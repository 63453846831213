import { Box, Card, CardContent, Divider, FormControlLabel, makeStyles, Radio } from "@material-ui/core";
import { ReactNode } from "react";

const useStyles = makeStyles(theme => ({
  card: {
    cursor: "pointer",
    backgroundColor: selected => selected ? "rgb(224, 241, 255)" : "rgb(255, 255, 255)",
    border: '1px solid transparent',
    borderRadius: 10,
    transition: '0.6s',
    '&:hover': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
    },
  },
}));

type SelectableCardProps = {
  title: string,
  icon?: ReactNode,
  onClick: () => void,
  selected: boolean,
  children: ReactNode
}

export function SelectableCard(props: SelectableCardProps) {
  const style = useStyles(props.selected)
  return (
    <Card className={style.card} onClick={props.onClick}>
      <CardContent>
        <Box mb={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
          <FormControlLabel
            label={props.title}
            control={<Radio color="primary" checked={props.selected}/>}
          />
          {props.icon}
          </Box>
          <Divider/>
        </Box>
        {props.children}
      </CardContent>
    </Card>
  )
}