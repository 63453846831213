import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useAPI } from "../api";
import { SelectableCard } from "./SelectableCard";
import {
  APIData,
  BeneficientsInput,
  CompanyInfo,
  DemographicsSpec,
  StepParams,
  StructureOptions,
  waiting,
} from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { handleChange } from "../lib";
import { Alert, Skeleton } from "@material-ui/lab";

// type BeneficientsFormProps = FormParams<BeneficientsInput> & {info: CompanyInfo }
type BeneficientsFormProps = StepParams<BeneficientsInput> & {
  info: CompanyInfo;
};

export function BeneficientsForm({ value, next, info }: BeneficientsFormProps) {
  const { getOptions } = useAPI();

  const [structureOpts, setStructureOpts] = React.useState<
    APIData<StructureOptions>
  >(waiting());
  const [beneficients, setBeneficients] = useState(value);

  const valid = useCallback(() => {
    if (beneficients.splitting === "ensemble") {
      return (
        beneficients.ensemble.personnes.length > 0 &&
        beneficients.ensemble.structure.length > 0
      );
    } else if (beneficients.splitting === "agirc-non-agirc") {
      return (
        beneficients.agirc.structure.length > 0 &&
        beneficients.nonAgirc.personnes.length > 0 &&
        beneficients.nonAgirc.structure.length > 0
      );
    } else if (beneficients.splitting === "ani-non-ani") {
      return (
        beneficients.ani.structure.length > 0 &&
        beneficients.nonAni.personnes.length > 0 &&
        beneficients.nonAni.structure.length > 0
      );
    }
  }, [beneficients]);

  const total = useMemo(() => {
    if (beneficients.splitting === "ensemble") {
      return parseInt(beneficients.ensemble.personnes);
    } else if (beneficients.splitting === "agirc-non-agirc") {
      return (
        parseInt(beneficients.agirc.personnes) +
        parseInt(beneficients.nonAgirc.personnes)
      );
    } else if (beneficients.splitting === "ani-non-ani") {
      return (
        parseInt(beneficients.ani.personnes) +
        parseInt(beneficients.nonAni.personnes)
      );
    } else {
      return 0;
    }
  }, [beneficients]);

  useEffect(() => {
    setStructureOpts(waiting);
    getOptions(info)
      .then((response) => setStructureOpts({ status: "ok", response }))
      .catch(() => setStructureOpts({ status: "error" }));
    // eslint-disable-next-line
  }, [info]);

  function structureOptions(structureOpts: StructureOptions) {
    return (
      <Grid className="demographic-selector" container spacing={3}>
        <Grid item xs={12} md={4}>
          <SelectableCard
            title="Ensemble du personnel"
            selected={beneficients.splitting === "ensemble"}
            onClick={() =>
              setBeneficients((i) => ({ ...i, splitting: "ensemble" }))
            }
          >
            <Typography style={{ display: "inline-block" }} variant="caption">
              Indiquez le nombre de salariés et la structure de cotisation
              souhaitée par l’entreprise.
            </Typography>
            <Demographics
              label="Nb personnes"
              options={structureOpts.ensemble}
              error={total > 49 && beneficients.splitting === "ensemble"}
              value={beneficients.ensemble}
              onChange={handleChange("ensemble", setBeneficients)}
            />
            {structureOpts.ensemble.length === 0 ? (
              <Alert severity="warning">No options</Alert>
            ) : null}
          </SelectableCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectableCard
            title="CADRE / NON-CADRE"
            selected={beneficients.splitting === "agirc-non-agirc"}
            onClick={() =>
              setBeneficients((i) => ({
                ...i,
                splitting: "agirc-non-agirc",
              }))
            }
          >
            <Typography style={{ display: "inline-block" }} variant="caption">
              CADRE: Salariés relevant des art 4 et 4 bis et 36 de la convention
              interprofessionnelle AGIRC du 14 mars 1947.
            </Typography>
            <Typography style={{ display: "inline-block" }} variant="caption">
              NON-CADRE: Salariés ne relevant pas des art 4, 4 bis et 36 de la
              convention interprofessionnelle AGIRC du 14 mars 1947.
            </Typography>
            <Typography style={{ display: "inline-block" }} variant="caption">
              Indiquez le nombre de salariés et la structure de cotisation
              souhaitée par l’entreprise.
            </Typography>
            <Demographics
              label="Cadres"
              options={structureOpts.agirc}
              value={beneficients.agirc}
              error={total > 49 && beneficients.splitting === "agirc-non-agirc"}
              onChange={handleChange("agirc", setBeneficients)}
            />
            <Demographics
              label="Non cadres"
              options={structureOpts.nonAgirc}
              value={beneficients.nonAgirc}
              error={total > 49 && beneficients.splitting === "agirc-non-agirc"}
              onChange={handleChange("nonAgirc", setBeneficients)}
            />

            {structureOpts.agirc.length === 0 ||
            structureOpts.nonAgirc.length === 0 ? (
              <Alert severity="warning">No options</Alert>
            ) : null}
          </SelectableCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectableCard
            title="ANI / NON-ANI"
            selected={beneficients.splitting === "ani-non-ani"}
            onClick={() =>
              setBeneficients((i) => ({ ...i, splitting: "ani-non-ani" }))
            }
          >
            <Typography style={{ display: "inline-block" }} variant="caption">
              ANI: Salariés relevant des art 2.1 et 2.2 de l'ANI du 17/11/2017
            </Typography>
            <Typography style={{ display: "inline-block" }} variant="caption">
              NON-ANI: Salariés ne relevant pas des art 2.1 et 2.2 de l'ANI du
              17/11/2017
            </Typography>
            <Typography style={{ display: "inline-block" }} variant="caption">
              Indiquez le nombre de salariés et la structure de cotisation
              souhaitée par l’entreprise.
            </Typography>
            <Demographics
              label="Nombre de salariés ANI"
              options={structureOpts.ani}
              value={beneficients.ani}
              error={total > 49 && beneficients.splitting === "ani-non-ani"}
              onChange={handleChange("ani", setBeneficients)}
            />
            <Demographics
              label="Nombre de salariés NON-ANI"
              options={structureOpts.nonAni}
              value={beneficients.nonAni}
              error={total > 49 && beneficients.splitting === "ani-non-ani"}
              onChange={handleChange("nonAni", setBeneficients)}
            />

            {structureOpts.ani.length === 0 ||
            structureOpts.nonAni.length === 0 ? (
              <Alert severity="warning">No options</Alert>
            ) : null}
          </SelectableCard>
        </Grid>
      </Grid>
    );
  }

  return (
    <Fragment>
      <Box mb={3}>
        <Typography variant="body1">
          L’ensemble du personnel, cadres et non-cadres, sera-t-il bénéficiaire
          des mêmes conditions d’assurance santé ?
        </Typography>
        <Typography variant="body2">
          L’employeur a le choix entre plusieurs structures de cotisation,
          impactant la tarification. Le choix doit être fait en fonction de la
          situation familiale de la majorité des salariés et du budget de
          l’entreprise.
        </Typography>
      </Box>
      {structureOpts.status === "waiting" ? (
        <Grid container>
          <Grid item xs={12} md={5}>
            <Skeleton variant="rect" width={210} height={118}></Skeleton>
          </Grid>
          <Grid item xs={12} md={5}>
            <Skeleton variant="rect" width={210} height={118}></Skeleton>
          </Grid>
        </Grid>
      ) : structureOpts.status === "error" ? (
        <Alert severity="error">Could not load data from the API.</Alert>
      ) : (
        structureOptions(structureOpts.response)
      )}
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          disabled={!valid() || total > 49}
          startIcon={<NavigateNextIcon />}
          onClick={() => next(beneficients)}
        >
          Continuer
        </Button>
      </Box>
    </Fragment>
  );
}

const useDemographicStyles = makeStyles({
  root: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    right: "22px", //do not overlap icon
    bottom: "0px", //maximize container height so label wont be cut horizontaly
  }, // a style rule
  shrinked: { right: "unset" }, // a nested style rule
});

type DemographicsProps = {
  label: string;
  options: string[];
  error: boolean;
  value: DemographicsSpec;
  onChange: (value: DemographicsSpec) => void;
};

function Demographics(params: DemographicsProps) {
  const styles = useDemographicStyles();
  return (
    <Box my={1} className="demographics">
      <Box display="inline-block" style={{ verticalAlign: "bottom" }}>
        <TextField
          label={params.label}
          InputLabelProps={{
            classes: { root: styles.root, shrink: styles.shrinked },
          }}
          type="number"
          value={params.value.personnes}
          error={params.error}
          // helperText={params.error ? "max 49":null}
          onChange={(event) => {
            let val = event.target.value;
            params.onChange({
              ...params.value,
              personnes: parseInt(val) > 0 || val === "" ? val : "0",
            });
          }}
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
      <Box display="inline-block" style={{ verticalAlign: "bottom" }}>
        <FormControl>
          <InputLabel id={"structure-label-" + params.label}>
            Structure de cotisation
          </InputLabel>
          <Select
            labelId={"structure-label-" + params.label}
            className="coverage"
            value={params.value.structure}
            onChange={(event) =>
              params.onChange({
                ...params.value,
                structure: event.target.value as string,
              })
            }
            onClick={(e) => e.stopPropagation()}
          >
            {params.options.map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

const emptyDemographicsSpec = {
  personnes: "",
  structure: "",
};

export const emptyBeneficientsInput: BeneficientsInput = {
  splitting: null,
  ensemble: emptyDemographicsSpec,
  agirc: emptyDemographicsSpec,
  nonAgirc: emptyDemographicsSpec,
  ani: emptyDemographicsSpec,
  nonAni: emptyDemographicsSpec,
};
