import {
  Card,
  makeStyles,
  createStyles,
  Theme,
  Container,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useAPI } from "../../api";
import { FileItem } from "../../components/types";
import { Downloadable } from "../../components/Files";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "30px",
      // paddingBottom: "30px !important"
    },
    document: {
      marginBottom: "10px",
      marginTop: "10px",
    },
    subdocument: {
      marginBottom: "6px",
      marginTop: "6px",
    },
  })
);

export function Kits() {
  const classes = useStyles();
  const { getKitsList } = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [fileList, setFileList] = useState([] as FileItem[]);

  useEffect(() => {
    setIsLoading(true);
    getKitsList().then((kits) => {
      setIsLoading(false);
      setFileList(kits);
      console.log(kits);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container hidden={isLoading}>
      <Card>
        <CardContent className={classes.main}>
          <Typography variant="h1">Kit « Santé Vous Assuré »</Typography>
          <ul>
            {fileList.map((e) =>
              e.items ? (
                <li className={classes.document}>
                  {e.name}:&nbsp;
                  <ul>
                    {e.items.map((f) =>
                      f.items ? (
                        <li>
                          {f.name}:&nbsp;
                          <ul>
                            <li>
                              {f.items.map((g) => (
                                <li
                                  className={classes.subdocument}
                                  key={g.name}
                                >
                                  <Downloadable item={g} />
                                </li>
                              ))}
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <li className={classes.subdocument} key={f.name}>
                          <Downloadable item={f} />
                        </li>
                      )
                    )}
                  </ul>
                </li>
              ) : (
                <li className={classes.document}>
                  <Downloadable item={e} />
                </li>
              )
            )}
          </ul>
        </CardContent>
      </Card>
    </Container>
  );
}
