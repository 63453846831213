import { Box, Typography } from "@material-ui/core"

export type OfferDetailsProps = {formula: "basic" | "standard" | "premium" | "ultimate" }

export function OfferDetails({formula}: OfferDetailsProps) {
  let details = options[formula]

  return (
    <Box>
      <Typography variant="subtitle2">Exemples de remboursement</Typography>
      {details.map(({label, value}, index) => (
        <Box key={index} my={1}>
          <Box><Typography variant="caption" noWrap>{label}</Typography></Box>
          <Box>
            <Typography variant="body2">
              {value}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export function RenfortDetails() {
  return (
    <Box>
      <Typography variant="body2" paragraph>
        En optique : un renfort sur une paire de lunettes
        de 80€ à 200 € en fonction du type de verre.
      </Typography>

      <Typography variant="body2" paragraph>
        En dépassement d’honoraires :
        2 fois la base de remboursement de la Sécurité Sociale,
        soit un complément de remboursement pouvant aller jusq’uà 46€
        pour une visite chez un spécialiste non-Optam
        (pédiatre, dermatologue, gynécologue…)
      </Typography>
    </Box>

  )
}

export const basicOptions = [
  { label: "Honoraires de médecins Optam",
    value: "100% de la base de remboursement" },
  { label: "Couronne dentaire",
    value: "150€" },
  { label: "Implant dentaire ",
    value: "150 € (jusqu’à 3 implants dans l’année)" },
  { label: "Hospitalisation en chambre particulière",
    value: "35€ la nuit" },
  { label: "Lunettes",
    value: "de 75€ à 200€ (monture et verres)" },
  { label: "Séances de médecine douce",
    value: "20€ par séance (4 séances)" }
]

export const standardOptions = [
  { label: "Honoraires de médecins Optam",
    value: "250% de la base de remboursement" },
  { label: "Couronne dentaire",
    value: "300€" },
  { label: "Implant dentaire ",
    value: "250€ (jusqu’à 3 implants dans l’année)" },
  { label: "Hospitalisation en chambre particulière",
    value: "50€ la nuit" },
  { label: "Lunettes",
    value: "de 200€ à 400€ (monture et verres)" },
  { label: "Séances de médecine douce",
    value: "30€ par séance (4 séances)" }
]

export const premiumOptions = [
  { label: "Honoraires de médecins Optam",
    value: "380% de la base de remboursement" },
  { label: "Couronne dentaire",
    value: "480€" },
  { label: "Implant dentaire ",
    value: "500€ (jusqu’à 3 implants dans l’année)" },
  { label: "Hospitalisation en chambre particulière",
    value: "85€ la nuit" },
  { label: "Lunettes",
    value: "de 300€ à 600€ (monture et verres)" },
  { label: "Séances de médecine douce",
    value: "45€ par séance (4 séances)" }
]

export const ultimateOptions = [
  { label: "Honoraires de médecins Optam",
    value: "500% de la base de remboursement" },
  { label: "Couronne dentaire",
    value: "660€" },
  { label: "Implant dentaire ",
    value: "750€ (jusqu’à 3 implants dans l’année)" },
  { label: "Hospitalisation en chambre particulière",
    value: "120€ la nuit" },
  { label: "Lunettes",
    value: "de 360€ à 800€ (monture et verres)" },
  { label: "Séances de médecine douce",
    value: "60€ par séance (4 séances)" }
]

const options = {
  "basic": basicOptions,
  "standard": standardOptions,
  "premium": premiumOptions,
  "ultimate": ultimateOptions
}
