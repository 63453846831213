export function handleChange<K extends keyof T,T>(spec: K, onChange: (a: (s: T) => T) => void) {
  return (value: T[K]) => {
      onChange((s: T) => {
        let n = Object.assign({}, s)
        //@ts-ignore
        n[spec] = value
        return n
      })
    }
}

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}