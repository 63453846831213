import { Chip, Container, FormControlLabel, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { useEffect, useState } from "react"
import { useUserAPI, User } from "../../api"
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Skeleton } from "@material-ui/lab";


function toggleActive(us: User[], id: string) {
  return us.map(u => u.user_id === id ? {...u, active: !u.active}:u )
}

export function UserManagement() {
  const { listUsers, activateBroker, deactivateBroker } = useUserAPI()
  const [ ulist, setUlist ] = useState([] as User[])

  useEffect(() => {
    listUsers(0)().then(r => {
      setUlist(r)
    })
  // eslint-disable-next-line
  }, [])

  const handleChange = (user: User, status: boolean) => {
    setUlist(toggleActive(ulist, user.user_id))
    let action = status ? activateBroker(user.user_id):deactivateBroker(user.user_id)
    action().catch(() => setUlist(toggleActive(ulist, user.user_id)))
  }

  return (
    <Container>
      <div className="transparent-card">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  E-mail
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              { ulist.length > 0 ? ulist.map(u => userRow(u, handleChange)):(
                <TableRow>
                  <TableCell><Skeleton/></TableCell>
                  <TableCell><Skeleton/></TableCell>
                  <TableCell><Skeleton/></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  )
}


function userRow(u: User, handleChange: (user: User, status: boolean) => void
) {
  return (
    <TableRow key={u.user_id}>
      <TableCell>{u.email}</TableCell>
      <TableCell>
        {u.name}
        {u.admin ? <Chip style={{marginLeft: "1ex"}} label="admin" size="small" color="primary"/>:null}
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={u.active}
              onChange={e => handleChange(u, e.target.checked)}
              color="primary"
            />
          }
          label="active"
        />
        <IconButton>
          <EditOutlinedIcon color="primary"/>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}