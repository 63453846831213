import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import './style.sass';
import { ThemedApp } from './App';
import reportWebVitals from './reportWebVitals';
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { audience, clientId, domain, scopes } from './config';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';


const Auth0ProviderWithHistory = ({ children }: { children: ReactNode }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.targetUrl || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={ domain }
      clientId={ clientId }
      audience={ audience }
      scope={ scopes.join(" ") }
      redirectUri={window.location.origin}
      useRefreshTokens={ true }
      // @ts-ignore
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithHistory>
        <ThemedApp />
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
