import { Card, CardContent, CardMedia, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useLocation, useParams } from "react-router";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug";
import remarkGfm from "remark-gfm";
import remarkToc from 'remark-toc'
import { useAPI } from "../../api";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 170,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  faqsection: {
    "& a": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      marginRight: "6px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    "& h1": theme.typography.h1,
    "& h2": theme.typography.h2,
    "& h3": theme.typography.h3,
    "& h4": theme.typography.h4,
    "& table": {
      maxWidth: "700px",
      borderCollapse: "collapse",
      "& tr": {
        borderBottom: "1px solid black"
      },
      "& td": {
        paddingTop: "1ex",
        paddingBottom: "1ex",
      }
    }
  }
}))

export function FAQ() {
  const classes = useStyles()
  const history = useHistory()
  const sections = [
    {
      title: 'FAQ Courtier',
      url: '/faq/courtier'
    },
    {
      title: 'FAQ Assuré',
      url: '/faq/assure'
    },
    {
      title: 'FAQ RH',
      url: '/faq/rh'
    },
    {
      title: 'FAQ Dirigeant',
      url: '/faq/dirigeant'
    }
  ]
  return (
    <Container>
      <Grid container spacing={5}>
        { sections.map(({title, url}) => (
          <Grid item md={3}>
            <Card className="button" onClick={() => history.push(url)}>
              <CardMedia className={classes.media}>
                <Skeleton animation={false} variant="rect" width={300} height={150}/>
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Santé Vous Assuré
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )) }
      </Grid>

    </Container>
  )
}


export function FAQSection() {
  const classes = useStyles()
  const { getFaqContent } = useAPI()
  const { section } = useParams() as { section: string }
  const [ faqContent, setFaqContent ] = useState("")
  const { hash } = useLocation()

  useEffect(() => {
    getFaqContent(section)().then(setFaqContent)
  // eslint-disable-next-line
  }, [section])

  useEffect(() => {
    setTimeout(() => {
      const id = decodeURI(hash).replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({behavior: "smooth", block: "center"});
    }, 100);
  }, [hash, section, faqContent])

  return (
    <Container>
      <div className="transparent-card">
        <ReactMarkdown
          className={classes.faqsection}
          rehypePlugins={[rehypeRaw, rehypeSlug]}
          remarkPlugins={[
            remarkGfm,
            [remarkToc, {"heading": "Sommaire"}]]}
        >
          { faqContent }
        </ReactMarkdown>
      </div>
    </Container>
  )
}