import {
  Box,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { AppBar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  finalStatus,
  lastUpdate,
  Proposal as ProposalType,
} from "../../components/types";
import { useAPI } from "../../api";
import { CompanyInfo, PriceCard } from "../../components/Proposal";
import { useHistory } from "react-router";
import { FinalProposalStatus } from "./ProposalView";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    tabsHeader: {
      padding: 0,
    },
  })
);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function Wallet() {
  const listRef = useRef<HTMLDivElement>(null);
  const { listProposals } = useAPI();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [proposalsList, setProposalsList] = useState<ProposalType[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    listProposals()
      .then((p): void => {
        setProposalsList(() =>
          p.sort((a, b) => (lastUpdate(a) < lastUpdate(b) ? 1 : -1))
        );
      })
      .catch(() => {
        setProposalsList(() => []);
        setIsLoading(() => false);
        setReload(() => false);
      })
      .finally(() => {
        setIsLoading(() => false);
        setReload(() => false);
      });
  }, [listRef, value, reload]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container hidden={isLoading}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Card>
          <CardActions className={classes.tabsHeader}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab key={0} label="Liste des propositions" {...a11yProps(0)} />
                <Tab
                  key={1}
                  disabled
                  label="Suivi de production"
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </CardActions>
          {!isLoading && proposalsList.length === 0 ? (
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Box
                    mt={3}
                    mb={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography color={"textSecondary"} variant="h6">
                      Rien à montrer
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          ) : (
            <CardContent>
              <TabPanel key={0} value={value} index={0}>
                <div ref={listRef}>
                  <ProposalsList proposals={proposalsList} />
                </div>
              </TabPanel>
              <TabPanel key={1} value={value} index={1}>
                <h1>Suivi de production</h1>
              </TabPanel>
            </CardContent>
          )}
        </Card>
      </Box>
    </Container>
  );
}

function ProposalsList({ proposals }: { proposals: ProposalType[] }) {
  return (
    <Grid container>
      {proposals.map((p) => ProposalItem(p))}
      <Grid item></Grid>
    </Grid>
  );
}

const useProposalItemStyle = makeStyles((theme) => ({
  card: {
    transition: "all 0.2s ease-out",
    padding: "20px",
    marginBottom: "20px",
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 15px 0px rgba(0, 0, 0, 0.4)",
      transform: "scale(1.01)",
    },
  },
}));

function ProposalItem(proposal: ProposalType) {
  const history = useHistory();
  const classes = useProposalItemStyle();
  return (
    <Paper
      className={classes.card}
      elevation={3}
      onClick={() => history.push(`/proposal/${proposal.proposalId}`)}
    >
      <Grid container spacing={2}>
        <Grid item md={3}>
          {finalStatus(proposal) ? (
            <Box mb={3}>
              <FinalProposalStatus proposal={proposal} />
            </Box>
          ) : null}
          <CompanyInfo info={proposal.company} />
        </Grid>
        {proposal.prices.rates.map((r) => (
          <Grid item md={3}>
            <PriceCard
              rates={r.value}
              college={r.college}
              assistance={proposal.prices.assistance}
              elevation={0}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}
