import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import "dayjs/locale/fr";

export default function GridDatePick(props: any) {
  const [value, setValue] = React.useState<Dayjs | null>();
  const handleVal = (newVal: Dayjs) => {
    setValue(newVal);

    if (newVal) {
      props.onChange(newVal);
    }
  };
  return (
    <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        //@ts-ignore
        onChange={handleVal}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: "JJ/MM/AAAA",
            }}
            variant="standard"
          />
        )}
      />
    </LocalizationProvider>
  );
}
