import { createStyles, makeStyles, Snackbar, Theme } from "@material-ui/core";
import { useAPI } from "../api";
import { DocumentItem, FileItem } from "./types";
import { saveAs } from "file-saver";
import { Fragment, useState } from "react";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      marginRight: "6px",
      "&:hover": {
        textDecoration: "underline",
      },
      // marginTop: "4pt"
    },
    notImpl: {
      marginTop: "4pt",
    },
  })
);

export function Downloadable({ item }: { item: FileItem | DocumentItem }) {
  const classes = useStyles();
  const { getFile } = useAPI();
  const [error, setError] = useState(false);

  function download(e: FileItem) {
    if (e.url) {
      setError(false);
      getFile(e.url, e.name)
        .then(({ blob, name }) => saveAs(blob, name))
        .catch((e) => {
          console.log(e);
          setError(true);
        });
    }
  }
  return (
    <Fragment>
      <div
        className={item.url ? classes.link : classes.notImpl}
        onClick={() => download(item)}
        key={item.name}
      >
        {item.name}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={error}
        onClose={() => setError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error" onClose={() => setError(false)}>
          Une erreur est survenue lors de la récupération du fichier
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export function DownloadableGfa({ item }: { item: FileItem | DocumentItem }) {
  const classes = useStyles();
  const { getFile } = useAPI();
  const [error, setError] = useState(false);

  function download(e: FileItem) {
    if (e.url) {
      setError(false);
      getFile(e.url, e.name)
        .then(({ blob, name }) => saveAs(blob, name))
        .catch((e) => {
          console.log(e);
          setError(true);
        });
    }
  }

  return (
    <Fragment>
      <div
        className={item.url ? classes.link : classes.notImpl}
        onClick={() => download(item)}
        key={item.name}
      >
        {item.name}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={error}
        onClose={() => setError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error" onClose={() => setError(false)}>
          Une erreur est survenue lors de la récupération du fichier
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
