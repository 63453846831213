import { Box, FormControlLabel } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import { IntervenantsInfo, StepParams } from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const emptyIntervenantsInfo: IntervenantsInfo = {
  typeEntrGrosOeuvre: "",
  marcheDejaPass: null as boolean | null,
  nomEntr: "",
  sirenEntr: "",
  nomMaitre: "",
  sirenMaitr: "",
  missionComplete: null as boolean | null,
  suiviTrav: null as boolean | null,
  constatAchev: null as boolean | null,
  validcompte: null as boolean | null,
  nomPersHabilite: "",
};

export function IntervenantsForm({
  value,
  next,
}: StepParams<IntervenantsInfo>) {
  const [typeEntrGrosOeuvre, setTypeEntrGrosOeuvre] = useState(
    value.typeEntrGrosOeuvre
  );
  const [marcheDejaPass, setMarcheDejaPass] = useState(value.marcheDejaPass);
  const [nomEntr, setNomEntr] = useState(value.nomEntr);
  const [sirenEntr, setSirenEntr] = useState(value.sirenEntr);
  const [nomMaitre, setNomMaitre] = useState(value.nomMaitre);
  const [sirenMaitr, setSirenMaitr] = useState(value.sirenMaitr);
  const [missionComplete, setMissionComplete] = useState(value.missionComplete);
  const [suiviTrav, setSuiviTrav] = useState(value.suiviTrav);
  const [nomPersHabilite, setnomPersHabilite] = useState(value.nomPersHabilite);
  const [constatAchev, setconstatAchev] = useState(value.constatAchev);
  const [validcompte, setvalidcompte] = useState(value.validcompte);
  let intervenantsInfos: IntervenantsInfo = {
    typeEntrGrosOeuvre,
    marcheDejaPass,
    nomEntr,
    sirenEntr,
    nomMaitre,
    sirenMaitr,
    missionComplete,
    suiviTrav,
    constatAchev,
    validcompte,
    nomPersHabilite,
  };

  return (
    <Box>
      <form className="basic-info" noValidate autoComplete="off">
        <Grid item xs={12} md={6} mt={2}>
          <Typography>Les marchés sont-ils passés avec :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={typeEntrGrosOeuvre === "une entreprise générale"}
                color="primary"
                onChange={() =>
                  setTypeEntrGrosOeuvre("une entreprise générale")
                }
              />
            }
            label="une entreprise générale"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={typeEntrGrosOeuvre === "par corps d’état"}
                color="primary"
                onChange={() => setTypeEntrGrosOeuvre("par corps d’état")}
              />
            }
            label="par corps d’état"
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Typography>
            Le marché avec l’entreprise de Gros Œuvre ou entreprise générale
            est-il déjà passé ?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={marcheDejaPass === true}
                color="primary"
                onChange={() => setMarcheDejaPass(true)}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={marcheDejaPass === false}
                color="primary"
                onChange={() => setMarcheDejaPass(false)}
              />
            }
            label="NON"
          />
        </Grid>
        {marcheDejaPass === true && (
          <>
            <Grid item xs={12} md={6}>
              <Box display="flex">
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  Nom de l’entreprise :
                </Typography>
                <TextField
                  type="text"
                  value={nomEntr}
                  onChange={(event) => setNomEntr(event.target.value)}
                  variant="standard"
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  SIREN :
                </Typography>
                <TextField
                  type="number"
                  value={sirenEntr}
                  onChange={(event) => setSirenEntr(event.target.value)}
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} mt={5}>
          <Box display="flex" flexDirection="row" alignContent={"center"}>
            <Typography
              pr="5px"
              sx={{
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
              }}
            >
              Nom du Maître d’œuvre :
            </Typography>
            <TextField
              type="text"
              value={nomMaitre}
              onChange={(event) => setNomMaitre(event.target.value)}
              InputProps={{
                sx: {
                  "& .MuiInput-input": {
                    paddingBottom: 0,
                  },
                },
              }}
              sx={{
                paddingLeft: 2,
              }}
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="row" alignContent={"center"}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
              }}
            >
              SIREN :
            </Typography>
            <TextField
              type="number"
              value={sirenMaitr}
              onChange={(event) => setSirenMaitr(event.target.value)}
              InputProps={{
                sx: {
                  "& .MuiInput-input": {
                    paddingBottom: 0,
                  },
                },
                inputProps: { min: 0 },
              }}
              sx={{
                paddingLeft: 2,
              }}
              variant="standard"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={missionComplete === true}
                color="primary"
                onChange={(e) => setMissionComplete(e.target.checked)}
              />
            }
            label="Mission complète"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={suiviTrav === true}
                color="primary"
                onChange={(e) => setSuiviTrav(e.target.checked)}
              />
            }
            label="Partielle"
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Typography>
            La mission du maître d'œuvre comprend-elle la constatation de
            l'achèvement de l'immeuble ?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={constatAchev === true}
                color="primary"
                onChange={() => setconstatAchev(true)}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={constatAchev === false}
                color="primary"
                onChange={() => setconstatAchev(false)}
              />
            }
            label="NON"
          />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <Typography>
            La mission du maître d'œuvre comprend-elle la validation des comptes
            rendus d'avancement des travaux ?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={validcompte === true}
                color="primary"
                onChange={() => setvalidcompte(true)}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={validcompte === false}
                color="primary"
                onChange={() => setvalidcompte(false)}
              />
            }
            label="NON"
          />
          {validcompte === false && (
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  pr="5px"
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  Nom de la personne habilitée à réaliser ses missions :
                </Typography>
                <TextField
                  type="text"
                  value={nomPersHabilite}
                  onChange={(event) => setnomPersHabilite(event.target.value)}
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </form>
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          //disabled={!valid()}
          startIcon={<NavigateNextIcon />}
          onClick={() => next(intervenantsInfos)}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}
