import * as React from "react";
import Box from "@mui/material/Box";

import "dayjs/locale/fr";
import {
  DataGrid,
  GridRowModel,
  frFR,
  useGridApiContext,
  GridValidRowModel,
  GridValueFormatterParams,
  GridColumnHeaderParams,
} from "@mui/x-data-grid";
import GridDatePick from "./GridDatePick";
import { Operation } from "./types";
import { Typography } from "@mui/material";
const emptyOperation = {
  id: 0,
  name: "",
  address: "",
  CATTC: null,
  costRev: null,
  costConstruct: null,
  logNum: null,
  finishDate: null,
  surface: null,
  isNew: false,
};
const initialRows = Array(5)
  .fill(0)
  .map((_, idx) => ({ ...emptyOperation, id: idx + 1 }));
//@ts-ignore
function GridEditDateCell({ id, field, value }) {
  const apiRef = useGridApiContext();
  //@ts-ignore

  function handleChange(newValue) {
    apiRef.current.setEditCellValue({
      id,
      field,
      value:
        newValue && newValue.format("DD/MM/YYYY") === "Invalid Date"
          ? ""
          : newValue.format("DD/MM/YYYY"),
    });
  }

  return <GridDatePick value={value} onChange={handleChange} />;
}

export default function OperationsGrid({
  onEdit,
  initRows,
}: {
  onEdit: React.Dispatch<React.SetStateAction<Operation[] | null>>;
  initRows: Operation[] | null;
}) {
  const [rows, setRows] = React.useState(initRows || initialRows);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => onEdit(rows), [rows]);

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    //@ts-ignore
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = [
    {
      field: "name",
      flex: 0.5,
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          Nom de l'opération
        </Typography>
      ),
    },
    {
      field: "address",
      flex: 0.48,
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          Ville + code postal
        </Typography>
      ),
    },
    {
      field: "CATTC",
      type: "number",
      flex: 0.25,
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          CA TTC
        </Typography>
      ),
    },
    {
      field: "costRev",
      flex: 0.5,
      type: "number",
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          Coût de revient TTC
        </Typography>
      ),
    },
    {
      field: "costConstruct",
      flex: 0.5,
      type: "number",
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          Coût de construction
        </Typography>
      ),
    },
    {
      field: "logNum",
      type: "number",
      flex: 0.5,
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          Nombre de logement
        </Typography>
      ),
    },
    {
      field: "finishDate",
      flex: 0.5,
      type: "date",
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      //@ts-ignore
      renderEditCell: (params) => <GridEditDateCell {...params} />,
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          Date d’achèvement
        </Typography>
      ),
      //@ts-ignore
      /*       valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"), */
    },
    {
      field: "surface",
      type: "number",
      flex: 0.5,
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Typography
          style={{ whiteSpace: "pre-wrap" }}
          sx={{ wordBreak: "break-word", fontWeight: "bold" }}
          variant="caption"
        >
          Surface plancher
        </Typography>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows as readonly GridValidRowModel[]}
        columns={columns}
        editMode="row"
        processRowUpdate={processRowUpdate}
        componentsProps={{
          //toolbar: { setRows, setRowModesModel },
          baseTextField: {
            inputProps: {
              autocomplete: "off",
              min: 0,
            },
          },
          baseFormControl: {
            autocomplete: "off",
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooter={true}
        autoHeight
        showCellRightBorder
        showColumnRightBorder
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      />
    </Box>
  );
}
