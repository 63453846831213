export const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;
export const api_url = process.env.REACT_APP_API_URL as string
export const scopes = [
  "create:own:proposal",
  "read:own:proposal",
  "list:own:proposals",
  "request:own:contract",
  "cancel:own:proposal",
  "read:any:proposal",
  "signed:any:contract",
  "manage:all:users"
]