import { Box, FormControlLabel } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import {
  CostsOperation,
  OperationInfos,
  StepParams,
  PaiementDiff,
} from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import InputAdornment from "@mui/material/InputAdornment";
import OpInfoGrid from "./opInfoGrid";
import DatePick from "./DatePick";
import TextField from "@mui/material/TextField";

const emptyPaiementDiff: PaiementDiff = {
  amount: "",
  date: "",
};

export const emptyOperationInfos: OperationInfos = {
  opName: "",
  addressContruction: "",
  typeContrat: "",
  numPC: "",
  numPCmodif: "",
  numPCTrans: "",
  dateNumPC: "",
  dateNumPCmodif: "",
  dateNumPCTrans: "",
  typeConstruct: [""],
  nbrBat: "",
  nbrBurCom: "",
  nbrTerr: "",
  nbrLog: "",
  nbrEtage: "",
  surfacePlancher: "",
  dateOuvChantier: "",
  dateFinTrav: "",
  dateDemChantier: "",
  dureeTrav: "",
  chantierDem: null as boolean | null,
  foncierPaye: null as boolean | null,
  creditAcFoncier: null as boolean | null,
  dation: null as boolean | null,
  montantDation: "",
  payDiff: null as boolean | null,
  paiementDiff: emptyPaiementDiff,
  costsHTandTTC: null as CostsOperation[] | null,
  montantCreditAcFoncier: null as string | null,
};

export function OperationInfosFrom({
  value,
  next,
}: StepParams<OperationInfos>) {
  const [opName, setOpName] = useState(value.opName);
  const [addressContruction, setAddressConstruct] = useState(
    value.addressContruction
  );
  const [typeContrat, setTypeContrat] = useState(value.typeContrat);
  const [numPC, setNumPC] = useState(value.numPC);
  const [numPCmodif, setNumPCmodif] = useState(value.numPCmodif);
  const [numPCTrans, setNumPCTrans] = useState(value.numPCTrans);
  const [dateNumPC, setDateNumPC] = useState(value.dateNumPC);
  const [dateNumPCmodif, setDateNumPCmodif] = useState(value.dateNumPCmodif);
  const [dateNumPCTrans, setDateNumPCTrans] = useState(value.dateNumPCTrans);
  const [typeConstruct, setTypeConstruct] = useState(value.typeConstruct);
  const [nbrBat, setNbrBat] = useState(value.nbrBat);
  const [nbrTerr, setNbrTerr] = useState(value.nbrTerr);
  const [nbrBurCom, setNbrBurCom] = useState(value.nbrBurCom);
  const [nbrLog, setNbrLog] = useState(value.nbrLog);
  const [nbrEtage, setNbrEtage] = useState(value.nbrEtage);
  const [surfacePlancher, setSurfacePlancher] = useState(value.surfacePlancher);

  const [dateOuvChantier, setDateOuvChantier] = useState(value.dateOuvChantier);
  const [dateFinTrav, setDateFinTrav] = useState(value.dateFinTrav);
  const [dateDemChantier, setDateDemChantier] = useState(value.dateDemChantier);
  const [dureeTrav, setDureeTrav] = useState(value.dureeTrav);
  const [chantierDem, setChantierDem] = useState(value.chantierDem);

  const [foncierPaye, setFoncierPaye] = useState(value.foncierPaye);
  const [creditAcFoncier, setCreditAcFoncier] = useState(value.creditAcFoncier);
  const [montantCreditAcFoncier, setMontantCreditAcFoncier] = useState(
    value.montantCreditAcFoncier
  );
  const [highlight, setHighLight] = useState(false);
  const [dation, setDation] = useState(value.dation);
  const [montantDation, setMontantDation] = useState(value.montantDation);

  const [payDiff, setPayDiff] = useState(value.payDiff);
  // eslint-disable-next-line
  const [paiementDiff, setPaiementDiff] = useState(value.paiementDiff);

  // eslint-disable-next-line

  const [costsHTandTTC, setCostsHTandTTC] = useState(value.costsHTandTTC);

  let opInfos = {
    opName,
    addressContruction,
    typeContrat,
    numPC,
    numPCmodif,
    numPCTrans,
    dateNumPC,
    dateNumPCmodif,
    dateNumPCTrans,
    typeConstruct,
    nbrTerr,
    nbrBat,
    nbrBurCom,
    nbrLog,
    nbrEtage,
    surfacePlancher,
    dateOuvChantier,
    dateFinTrav,
    dateDemChantier,
    dureeTrav,
    chantierDem,
    foncierPaye,
    creditAcFoncier,
    dation,
    montantDation,
    paiementDiff,
    costsHTandTTC,
    montantCreditAcFoncier,
    payDiff,
  };
  const handleUpdateTypeConstruct = (
    e: React.ChangeEvent<HTMLInputElement>,
    construct: string
  ) => {
    if (e.target.checked) setTypeConstruct([...typeConstruct, construct]);
    else setTypeConstruct(typeConstruct.filter((elem) => elem !== construct));
  };

  //useEffect(() => console.log(typeConstruct), [typeConstruct]);
  const valid = () =>
    opName &&
    addressContruction &&
    (typeContrat !== "VRD" ? nbrLog && nbrBat && nbrEtage : true) &&
    creditAcFoncier !== null &&
    foncierPaye !== null &&
    chantierDem !== null &&
    typeContrat !== "" &&
    (typeContrat === "VRD" ? nbrTerr !== null : true);

  return (
    <Box>
      <form className="basic-info" noValidate autoComplete="off">
        <Grid container xs={12} md={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nom de l'opération"
              value={opName}
              name="denomination-sociale"
              onChange={(event) => setOpName(event.target.value)}
              required
              fullWidth
              error={highlight && !opName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Adresse de construction"
              value={addressContruction}
              name="denomination-sociale"
              onChange={(event) => setAddressConstruct(event.target.value)}
              required
              fullWidth
              error={highlight && (addressContruction ? false : true)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={9} mt={5}>
          <Typography>Type de contrat * :</Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={typeContrat === "VEFA"}
                color="primary"
                onChange={() => setTypeContrat("VEFA")}
                sx={{
                  color:
                    typeContrat !== ""
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="VEFA"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={typeContrat === "VIR"}
                color="primary"
                onChange={() => setTypeContrat("VIR")}
                sx={{
                  color:
                    typeContrat !== ""
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="VIR"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={typeContrat === "VRD"}
                color="primary"
                onChange={() => setTypeContrat("VRD")}
                sx={{
                  color:
                    typeContrat !== ""
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="VRD"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={typeContrat === "CPI"}
                color="primary"
                onChange={() => setTypeContrat("CPI")}
                sx={{
                  color:
                    typeContrat !== ""
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="CPI"
          />
        </Grid>
        <Grid container xs={12} md={12} spacing={2} mt={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label={typeContrat === "VRD" ? "Numéro du PA" : "Numéro du PC"}
              value={numPC}
              //name="denomination-sociale"
              onChange={(event) => setNumPC(event.target.value)}
              fullWidth
              InputProps={{
                inputProps: { min: 0 },
              }}
              //error={highlight && (raisonSociale ? false : true)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePick
              label="Délivré le :"
              value={dateNumPC}
              onChange={setDateNumPC}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={
                typeContrat === "VRD"
                  ? "Numéro du PA Modificatif"
                  : "Numéro du PC Modificatif"
              }
              value={numPCmodif}
              //name="denomination-sociale"
              onChange={(event) => setNumPCmodif(event.target.value)}
              fullWidth
              //error={highlight && (raisonSociale ? false : true)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePick
              label="Délivré le :"
              value={dateNumPCmodif}
              onChange={setDateNumPCmodif}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label={
                typeContrat === "VRD"
                  ? "Numéro du PA Transféré"
                  : "Numéro du PC Transféré"
              }
              value={numPCTrans}
              //name="denomination-sociale"
              onChange={(event) => setNumPCTrans(event.target.value)}
              fullWidth
              //error={highlight && (raisonSociale ? false : true)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePick
              label="Délivré le :"
              value={dateNumPCTrans}
              onChange={setDateNumPCTrans}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box mt={5}>
            <Typography>Type de construction :</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={typeConstruct.includes("Traditionnel")}
                  color="primary"
                  onChange={(e) => handleUpdateTypeConstruct(e, "Traditionnel")}
                />
              }
              label="Traditionnel"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={typeConstruct.includes("Bois")}
                  color="primary"
                  onChange={(e) => handleUpdateTypeConstruct(e, "Bois")}
                />
              }
              label="Bois"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={typeConstruct.includes("Passive")}
                  color="primary"
                  onChange={(e) => handleUpdateTypeConstruct(e, "Passive")}
                />
              }
              label="Passive"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={typeConstruct.includes("Ossature métallique")}
                  color="primary"
                  onChange={(e) =>
                    handleUpdateTypeConstruct(e, "Ossature métallique")
                  }
                />
              }
              label="Ossature métallique"
            />
          </Box>
        </Grid>
        <Grid container xs={12} md={12} spacing={2}>
          {typeContrat === "VRD" ? (
            <Grid item xs={12} md={3}>
              <TextField
                label="Nombre de terrains à bâtir"
                value={nbrTerr}
                onChange={(event) => setNbrTerr(event.target.value)}
                required
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                error={highlight && (nbrTerr ? false : true)}
              />
            </Grid>
          ) : (
            <>
              {" "}
              <Grid item xs={12} md={3}>
                <TextField
                  label="Nombre de bâtiments"
                  value={nbrBat}
                  //name="denomination-sociale"
                  onChange={(event) => setNbrBat(event.target.value)}
                  required
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  error={highlight && (nbrBat ? false : true)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Nombre de logements"
                  value={nbrLog}
                  //name="denomination-sociale"
                  onChange={(event) => setNbrLog(event.target.value)}
                  required
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  error={highlight && (nbrLog ? false : true)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Nombre de bureaux / commerces"
                  value={nbrBurCom}
                  //name="denomination-sociale"
                  onChange={(event) => setNbrBurCom(event.target.value)}
                  required
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}

                  //error={highlight && (raisonSociale ? false : true)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Nombre d'étage"
                  value={nbrEtage}
                  //name="denomination-sociale"
                  onChange={(event) => setNbrEtage(event.target.value)}
                  required
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  error={highlight && (nbrEtage ? false : true)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={3} md={3}>
            <TextField
              type="number"
              label="Surface plancher"
              value={surfacePlancher}
              //name="denomination-sociale"
              onChange={(event) => setSurfacePlancher(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">m²</InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              //error={highlight && (raisonSociale ? false : true)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ mt: 5 }}>
          <Box width="100%">
            <OpInfoGrid onEdit={setCostsHTandTTC} initRows={costsHTandTTC} />
          </Box>
        </Grid>
        <Grid container xs={12} md={12} spacing={2} mt={5}>
          <Grid item xs={12} md={4}>
            <DatePick
              label="Date d'ouverture du chantier :"
              value={dateOuvChantier}
              onChange={setDateOuvChantier}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePick
              label="Date de fin des travaux :"
              value={dateFinTrav}
              onChange={setDateFinTrav}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePick
              label="Date démarrage des travaux :"
              value={dateDemChantier}
              //name="denomination-sociale"
              onChange={setDateDemChantier}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Durée des travaux"
              value={dureeTrav}
              onChange={(event) => setDureeTrav(event.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mt={5}>
            <Typography>Chantier démarré * ? </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chantierDem === true}
                  color="primary"
                  onChange={() => setChantierDem(true)}
                  sx={{
                    color:
                      chantierDem !== null
                        ? "primary"
                        : highlight
                        ? "red"
                        : "primary",
                  }}
                />
              }
              label="OUI"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={chantierDem === false}
                  color="primary"
                  onChange={() => setChantierDem(false)}
                  sx={{
                    color:
                      chantierDem !== null
                        ? "primary"
                        : highlight
                        ? "red"
                        : "primary",
                  }}
                />
              }
              label="NON"
            />
          </Box>
        </Grid>
        <Box mt={5}>
          <Typography variant={"h6"} sx={{ textDecoration: "underline" }}>
            Le Foncier
          </Typography>
        </Box>
        <Grid container xs={12} md={12}>
          <Box mt={2}>
            <Typography>Le foncier est-il payé * ? </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={foncierPaye === true}
                  color="primary"
                  onChange={() => setFoncierPaye(true)}
                  sx={{
                    color:
                      foncierPaye !== null
                        ? "primary"
                        : highlight
                        ? "red"
                        : "primary",
                  }}
                />
              }
              label="OUI"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={foncierPaye === false}
                  color="primary"
                  onChange={(e) => setFoncierPaye(false)}
                  sx={{
                    color:
                      foncierPaye !== null
                        ? "primary"
                        : highlight
                        ? "red"
                        : "primary",
                  }}
                />
              }
              label="NON"
            />
          </Box>
          <Grid container spacing={1} mt={2} xs={12} md={12}>
            <Grid item xs={12} md={6}>
              <Box mt={2}>
                <Typography>
                  Y a-t-il un crédit d’acquisition pour le foncier * ?
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creditAcFoncier === true}
                      onChange={() => setCreditAcFoncier(true)}
                      sx={{
                        color:
                          creditAcFoncier !== null
                            ? "primary"
                            : highlight
                            ? "red"
                            : "primary",
                      }}
                    />
                  }
                  label="OUI"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={creditAcFoncier === false}
                      onChange={(e) => setCreditAcFoncier(false)}
                      sx={{
                        color:
                          creditAcFoncier !== null
                            ? "primary"
                            : highlight
                            ? "red"
                            : "primary",
                      }}
                    />
                  }
                  label="NON"
                />
              </Box>
            </Grid>
            {creditAcFoncier ? (
              <Grid item xs={12} md={12}>
                <Box display="flex" flexDirection="row" alignContent={"center"}>
                  <Typography
                    pr="5px"
                    sx={{
                      whiteSpace: "nowrap",
                      alignSelf: "flex-end",
                    }}
                  >
                    Montant :
                  </Typography>
                  <TextField
                    type="number"
                    value={montantCreditAcFoncier}
                    onChange={(event) =>
                      setMontantCreditAcFoncier(event.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                      sx: {
                        "& .MuiInput-input": {
                          paddingBottom: 0,
                        },
                      },
                      inputProps: { min: 0 },
                    }}
                    sx={{
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    variant="standard"
                  />
                </Box>
              </Grid>
            ) : null}

            <Grid item mt={3} xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dation === true}
                    color="primary"
                    onChange={(e) => {
                      setPayDiff(false);
                      setDation(e.target.checked);
                    }}
                  />
                }
                label="Dation"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payDiff === true}
                    color="primary"
                    onChange={(e) => {
                      setDation(false);
                      setPayDiff(e.target.checked);
                    }}
                  />
                }
                label="Paiement différé"
              />
            </Grid>
            {dation ? (
              <Grid item xs={12} md={12}>
                <Box display="flex" flexDirection="row" alignContent={"center"}>
                  <Typography
                    pr="5px"
                    sx={{
                      whiteSpace: "nowrap",
                      alignSelf: "flex-end",
                    }}
                  >
                    Montant :
                  </Typography>
                  <TextField
                    type="number"
                    value={montantDation}
                    onChange={(event) => setMontantDation(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                      sx: {
                        "& .MuiInput-input": {
                          paddingBottom: 0,
                        },
                      },
                      inputProps: { min: 0 },
                    }}
                    sx={{
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    variant="standard"
                  />
                </Box>
              </Grid>
            ) : null}
            {payDiff ? (
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <TextField
                    label="Montant"
                    value={paiementDiff.amount}
                    onChange={(event) =>
                      setPaiementDiff({
                        ...paiementDiff,
                        amount: event.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                      sx: {
                        "& .MuiInput-input": {
                          paddingBottom: 0,
                        },
                      },
                      inputProps: { min: 0 },
                    }}
                    sx={{
                      paddingLeft: 2,
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <DatePick
                    label="Date:"
                    value={paiementDiff.date}
                    onChange={(v: string) =>
                      setPaiementDiff({
                        ...paiementDiff,
                        date: v,
                      })
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </form>
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<NavigateNextIcon />}
          onClick={() => {
            if (valid()) next(opInfos);
            else setHighLight(true);
          }}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}
