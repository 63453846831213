import {Container, StepButton} from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import React, {useMemo, useState} from "react";
import "./Sante.sass"
import {CompanyInfoForm, emptyCompanyInfo} from "../../components/CompanyInfoForm";
import {
    CompanyInfo,
    BeneficientsInput,
    OfferListParams,
    FormulasInput,
    ProposalParams,
    Formula,
    ByCollege,
    StructureOptions,
    SplittingType,
    PaymentDetails,
} from "../../components/types";
import {BeneficientsForm, emptyBeneficientsInput} from "../../components/BeneficientsForm";
import {emptyFormulasInput, SelectOfferForm} from "../../components/SelectOfferForm";
import {SelectPaymentMethod, emptyPaymentDetails} from "../../components/SelectPaymentMethod";
import {OptionSerenite} from "../../components/SereniteOptionForm";
import { NewProposal } from "../../components/Proposal";


export function Sante() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [info, setInfo] = React.useState(emptyCompanyInfo)
    const [beneficients, setBeneficients] = React.useState(emptyBeneficientsInput)
    const [formulas, setFormulas] = useState(emptyFormulasInput)
    const [paymentDetails, setPaymentDetails] = useState(emptyPaymentDetails)
    const [optionSerenite, setOptionSerenite] = useState(null as boolean | null)

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    let offerParams = useMemo(() => mkOfferParams(info, beneficients),
        [info, beneficients]
    )

    let companySize = useMemo(() => {
        if (beneficients.splitting === "agirc-non-agirc") {
            return parseInt(beneficients.agirc.personnes) + parseInt(beneficients.nonAgirc.personnes)
        } else if (beneficients.splitting === "ani-non-ani") {
            return parseInt(beneficients.ani.personnes) + parseInt(beneficients.nonAni.personnes)
        } else if (beneficients.splitting === "ensemble") {
            return parseInt(beneficients.ensemble.personnes)
        } else {
            return 0
        }
    }, [beneficients])

    let proposalParams = useMemo(() =>
            mkProposalParams(info, beneficients, formulas, optionSerenite as boolean, paymentDetails),
        [info, beneficients, formulas, optionSerenite, paymentDetails]
    )


    const steps = [
        {
            label: "Pour commencer, quelques précisions basiques sur l’entreprise",
            content:
                <CompanyInfoForm value={info} next={info => {
                    setInfo(info)
                    setActiveStep(1)
                }}/>
        },
        {
            label: "Un peu plus d’informations sur les bénéficiaires",
            content:
                <BeneficientsForm info={info} value={beneficients} next={beneficients => {
                    setBeneficients(beneficients)
                    setActiveStep(2)
                }}/>
        },
        {
            label: "Choisissez la couverture souhaitée",
            content:
                <SelectOfferForm params={offerParams}
                                 value={formulas}
                                 next={formulas => {
                                     setFormulas(formulas)
                                     setActiveStep(3)
                                 }}
                />
        },

        {
            label: "Offre Sérénité Dirigeant",
            content: <OptionSerenite size={companySize} value={optionSerenite}
                                     next={serenite => {
                                         setOptionSerenite(serenite)
                                         setActiveStep(4)
                                     }}/>
        },
        {
            label: "Choisissez la méthode de paiement",
            content:
                <SelectPaymentMethod 
                                 value={paymentDetails}
                                 next={paymentDetails => {
                                     setPaymentDetails(paymentDetails)
                                     setActiveStep(5)
                                 }}
                />
        },
        {
            label: "Proposition « Santé Vous Assuré »",
            content: <NewProposal params={proposalParams} company={info}/>
        }
    ]

    return (
        <Container>
            <div className="transparent-card">
                <Stepper activeStep={activeStep} orientation="vertical" >
                    {steps.map((s, index) => (
                        <Step key={s.label}>
                            <StepButton onClick={handleStep(index)}> {s.label} </StepButton>
                            <StepContent>
                                {s.content}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </Container>
    )
}

function mkOfferParams({postalCode, codeCCN}: CompanyInfo,
                       beneficients: BeneficientsInput): OfferListParams {
    let structure = (k: keyof StructureOptions) => beneficients[k].structure
    return {
        postalCode, codeCCN: codeCCN,
        structures: mkByCollege(beneficients.splitting, structure)
    }
}


function mkByCollege<T>(splitting: SplittingType, f: (s: keyof StructureOptions) => T):
    ByCollege<T> {
    if (splitting === "agirc-non-agirc") {
        return [{college: "agirc", value: f("agirc")},
            {college: "nonAgirc", value: f("nonAgirc")}]
    } else if (splitting === "ani-non-ani") {
        return [{college: "ani", value: f("ani")},
            {college: "nonAni", value: f("nonAni")}]
    } else if (splitting === "ensemble") {
        return [{college: "ensemble", value: f("ensemble")}]
    } else {
        return []
    }
}

function mkProposalParams({postalCode, codeCCN}: CompanyInfo,
                          beneficients: BeneficientsInput,
                          formulas: FormulasInput,
                          optionSerenite: boolean,
                          paymentDetails: PaymentDetails,
): ProposalParams {
    let mkFormula = (k: keyof StructureOptions) => ({
        formula: formulas[k] as Formula,
        structure: beneficients[k].structure
    })
    return {
        postalCode, codeCCN,
        companySize: mkByCollege(beneficients.splitting, k => parseInt(beneficients[k].personnes) ),
        optionSerenite,
        formula: mkByCollege(beneficients.splitting, mkFormula),
        paymentDetails: paymentDetails,
    }
}