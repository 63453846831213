import { Box, FormControlLabel } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button, InputAdornment } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import { FinancementOp, StepParams } from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DatePick from "./DatePick";

export const emptyFinancementOpInfos: FinancementOp = {
  appFdsPrpres: null as boolean | null,
  provFds: "",
  credTerr: null as boolean | null,
  montant: "",
  dateEch: "",
  nomBanqueCredTerr: "",
  credProm: null as boolean | null,
  montantCredProm: "",
  dateEchCredProm: "",
  nomBanqueCredProm: "",
  crowFund: null as boolean | null,
  montantCrowFund: "",
  dateEchCrowFund: "",
  nomPlateformeCrowFund: "",
  pretPriv: null as boolean | null,
  montantPretPriv: "",
  dateEchPretPriv: "",
};

export function FinancementOpForm({ value, next }: StepParams<FinancementOp>) {
  const [appFdsPrpres, setappFdsPrpres] = useState(value.appFdsPrpres);
  const [provFds, setprovFds] = useState(value.provFds);
  const [credTerr, setcredTerr] = useState(value.credTerr);
  const [montant, setmontant] = useState(value.montant);
  const [dateEch, setdateEch] = useState(value.dateEch);
  const [nomBanqueCredTerr, setnomBanqueCredTerr] = useState(
    value.nomBanqueCredTerr
  );
  const [credProm, setcredProm] = useState(value.credProm);
  const [montantCredProm, setmontantCredProm] = useState(value.montantCredProm);
  const [dateEchCredProm, setdateEchCredProm] = useState(value.dateEchCredProm);
  const [nomBanqueCredProm, setnomBanqueCredProm] = useState(
    value.nomBanqueCredProm
  );
  const [crowFund, setcrowFund] = useState(value.crowFund);
  const [montantCrowFund, setmontantCrowFund] = useState(value.montantCrowFund);
  const [dateEchCrowFund, setdateEchCrowFund] = useState(value.dateEchCrowFund);
  const [nomPlateformeCrowFund, setnomPlateformeCrowFund] = useState(
    value.nomPlateformeCrowFund
  );
  const [pretPriv, setpretPriv] = useState(value.pretPriv);
  const [montantPretPriv, setmontantPretPriv] = useState(value.montantPretPriv);
  const [dateEchPretPriv, setdateEchPretPriv] = useState(value.dateEchPretPriv);
  const [highlight, setHighLight] = useState(false);

  let financementOpInfos: FinancementOp = {
    appFdsPrpres,
    provFds,
    credTerr,
    montant,
    dateEch,
    nomBanqueCredTerr,
    credProm,
    montantCredProm,
    dateEchCredProm,
    nomBanqueCredProm,
    crowFund,
    montantCrowFund,
    dateEchCrowFund,
    nomPlateformeCrowFund,
    pretPriv,
    montantPretPriv,
    dateEchPretPriv,
  };

  const valid = () =>
    appFdsPrpres !== null &&
    credTerr !== null &&
    credProm !== null &&
    crowFund !== null &&
    pretPriv !== null;
  return (
    <Box>
      <form className="basic-info" noValidate autoComplete="off">
        <Grid item xs={12} md={6} mt={2}>
          <Typography>Fonds propres *</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={appFdsPrpres === true}
                color="primary"
                onChange={() => setappFdsPrpres(true)}
                sx={{
                  color:
                    appFdsPrpres !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={appFdsPrpres === false}
                color="primary"
                onChange={() => setappFdsPrpres(false)}
                sx={{
                  color:
                    appFdsPrpres !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="NON"
          />
        </Grid>
        {appFdsPrpres && (
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" alignContent={"center"}>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  alignSelf: "flex-end",
                }}
              >
                Apport en fonds propres :
              </Typography>
              <TextField
                type="number"
                value={provFds}
                onChange={(event) => setprovFds(event.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                  sx: {
                    "& .MuiInput-input": {
                      paddingBottom: 0,
                    },
                  },
                  inputProps: { min: 0 },
                }}
                sx={{
                  paddingLeft: 2,
                }}
                variant="standard"
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6} mt={5}>
          <Typography>Crédit terrain * :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={credTerr === true}
                color="primary"
                onChange={() => setcredTerr(true)}
                sx={{
                  color:
                    credTerr !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={credTerr === false}
                color="primary"
                onChange={() => setcredTerr(false)}
                sx={{
                  color:
                    credTerr !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="NON"
          />
        </Grid>
        {credTerr && (
          <>
            <Grid item xs={12} md={3}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  montant :
                </Typography>
                <TextField
                  type="number"
                  value={montant}
                  onChange={(event) => setmontant(event.target.value)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePick
                label="Date échéance :"
                value={dateEch}
                onChange={setdateEch}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  pr="5px"
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  Etablissement bancaire :
                </Typography>
                <TextField
                  type="text"
                  value={nomBanqueCredTerr}
                  required
                  onChange={(event) => setnomBanqueCredTerr(event.target.value)}
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} mt={5}>
          <Typography>Crédit promoteur * :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={credProm === true}
                color="primary"
                onChange={() => setcredProm(true)}
                sx={{
                  color:
                    credProm !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={credProm === false}
                color="primary"
                onChange={() => setcredProm(false)}
                sx={{
                  color:
                    credProm !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="NON"
          />
        </Grid>
        {credProm && (
          <>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  montant :
                </Typography>
                <TextField
                  type="number"
                  value={montantCredProm}
                  onChange={(event) => setmontantCredProm(event.target.value)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePick
                label="Date échéance :"
                value={dateEchCredProm}
                onChange={setdateEchCredProm}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  pr="5px"
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  Etablissement bancaire :
                </Typography>
                <TextField
                  type="text"
                  value={nomBanqueCredProm}
                  onChange={(event) => setnomBanqueCredProm(event.target.value)}
                  required
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} mt={5}>
          <Typography>Crowdfunding * :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={crowFund === true}
                color="primary"
                onChange={() => setcrowFund(true)}
                sx={{
                  color:
                    crowFund !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={crowFund === false}
                color="primary"
                onChange={() => setcrowFund(false)}
                sx={{
                  color:
                    crowFund !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="NON"
          />
        </Grid>
        {crowFund && (
          <>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  montant :
                </Typography>
                <TextField
                  type="number"
                  value={montantCrowFund}
                  required
                  onChange={(event) => setmontantCrowFund(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePick
                label="Date échéance :"
                value={dateEchCrowFund}
                onChange={setdateEchCrowFund}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  pr="5px"
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  Nom de la plateforme :
                </Typography>
                <TextField
                  type="text"
                  value={nomPlateformeCrowFund}
                  required
                  onChange={(event) =>
                    setnomPlateformeCrowFund(event.target.value)
                  }
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} mt={5}>
          <Typography>Prêt privé * :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={pretPriv === true}
                color="primary"
                onChange={() => setpretPriv(true)}
                sx={{
                  color:
                    pretPriv !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={pretPriv === false}
                color="primary"
                onChange={() => setpretPriv(false)}
                sx={{
                  color:
                    pretPriv !== null
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                }}
              />
            }
            label="NON"
          />
        </Grid>
        {pretPriv && (
          <>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  montant :
                </Typography>
                <TextField
                  type="number"
                  value={montantPretPriv}
                  required
                  onChange={(event) => setmontantPretPriv(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                    inputProps: { min: 0 },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePick
                label="Date échéance :"
                value={dateEchPretPriv}
                onChange={setdateEchPretPriv}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignContent={"center"}>
                <Typography
                  pr="5px"
                  sx={{
                    whiteSpace: "nowrap",
                    alignSelf: "flex-end",
                  }}
                >
                  Nom du prêteur :
                </Typography>
                <TextField
                  type="text"
                  value={nomPlateformeCrowFund}
                  required
                  onChange={(event) =>
                    setnomPlateformeCrowFund(event.target.value)
                  }
                  InputProps={{
                    sx: {
                      "& .MuiInput-input": {
                        paddingBottom: 0,
                      },
                    },
                  }}
                  sx={{
                    paddingLeft: 2,
                  }}
                  variant="standard"
                />
              </Box>
            </Grid>
          </>
        )}
      </form>
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          //disabled={!valid()}
          startIcon={<NavigateNextIcon />}
          onClick={() => {
            if (valid()) next(financementOpInfos);
            else setHighLight(true);
          }}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}
