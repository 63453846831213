import {
    Box,
    Card,
    CardContent,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    SvgIcon,
    Typography
} from "@material-ui/core";
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
// import PriceCheckRoundedIcon from '@material-ui/icons/PriceCheckRounded';
// import PendingActionsRoundedIcon from '@material-ui/icons/PendingActionsRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';

const useStyles = makeStyles(() =>
    createStyles({
        headerIcon: {
            marginRight: '10px',
            fontSize: '2rem !important'
        }
    })
)

export function AdminDashboard() {
    const classes = useStyles();

    const data = [{
        title: 'Nombre de propositions ouvertes',
        value: Math.round(Math.round(Math.random() * 10000)),
        icon: AssignmentRoundedIcon
    }, {
        title: 'Nombre de contrat signés',
        value: Math.round(Math.round(Math.random() * 10000)),
        icon: AssignmentTurnedInRoundedIcon
    }, {
        title: 'Nombre de propositions en attentes',
        value: Math.round(Math.round(Math.random() * 10000)),
        // icon: PendingActionsRoundedIcon
        icon: AssignmentRoundedIcon
    }, {
        title: 'Nombre de courtiers actifs',
        value: Math.round(Math.round(Math.random() * 10000)),
        icon: PersonRoundedIcon
    }, {
        title: 'Nombre de salariés assurés',
        value: Math.round(Math.round(Math.random() * 10000)),
        // icon: PriceCheckRoundedIcon
        icon: PersonRoundedIcon
    }]

    return (
        <Grid container spacing={2}>
            {
                data.map(item => (
                    <Grid item xs={6} md={4}>
                        <Card>
                            <CardContent>
                                <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <SvgIcon className={classes.headerIcon} component={item.icon}/>
                                    <h4>{item.title}</h4>
                                </Box>
                                <Divider/>
                                <Box mt={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <Typography variant="h4">{item.value}</Typography>
                                </Box>
                                <Box mt={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                                    <Typography color="textSecondary" variant={'caption'}>Mock data</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    )
}