import { Grid, Box, Typography } from "@material-ui/core";
import "./Price.sass";

type PriceProps = { price: string; ppmss: string; beneficient: string };

/** Displays price for a single beneficient together with %pmss */
export function Price(props: PriceProps) {
  return (
    <Box mb={0}>
      <Grid container spacing={2} alignItems="baseline">
        <Grid className="align-right" item xs={12}>
          <Box className="price">
            <Box
              className="subtext box--vertical"
              color="text.secondary"
              component="span"
            >
              <span>({(parseFloat(props.ppmss) * 100).toFixed(2)}%)</span>
              <span> du PMSS 2024</span>
            </Box>
            <Box className="box--vertical" component="span">
              <span>{Number(props.price).toFixed(2)} €</span>
              <span className="subtext">/mois</span>
            </Box>
            <Box ml={1} className="box--vertical" component="span">
              <Typography variant="caption">
                pour {props.beneficient}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

/** A hard-coded value for the assistance
 * TODO: this shouldn't be hardcoded.
 */
export function Assistance({ value }: { value: string }) {
  return (
    <Box mt={2}>
      <Typography variant="caption">
        + <b>{value}€</b> par mois pour votre assistance incluse
      </Typography>
    </Box>
  );
}
