import { Box, TextField, ListSubheader, Typography, FormHelperText } from "@material-ui/core"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import React, { useCallback, useState, useEffect } from "react"
import { ListChildComponentProps, VariableSizeList } from "react-window"
import { CodeType } from "./types"
import "./SelectCode.sass"

type SelectCodeProps = { label: string, options: CodeType[], value: CodeType, onChange: (v: CodeType) => void, highlight: boolean }

export function SelectCode( { label, options, value, onChange, highlight }: SelectCodeProps ) {
  const lookupOptions = useCallback((v: CodeType) => options.find(e => e.code === value.code) || null,
    [options, value])

  const [val, setVal] = useState(lookupOptions(value))

  // eslint-disable-next-line
  useEffect(() => setVal(lookupOptions(value)), [value])
  return (
    <Box>
      <Autocomplete
        options={options}
        value={val}
        includeInputInList={true}
        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        onChange={(_, v) => {
          if (v) {
            console.log(v)
            setVal(v)
            onChange(v)
          }
        }}
        getOptionLabel={(option) => option.code}
        renderOption={(option) => (
          <Box component="div" className="code-option" overflow="hidden" textOverflow="ellipsis">
            <Typography variant="body2">
            <b>{option.code}</b> {option.description}
            </Typography>
          </Box>
        )}
        filterOptions={filterOptions}
        renderInput={(params) => <TextField {...params} label={label} required error={highlight && (val ? false : true)} />}
        />
        <FormHelperText>{val?.description}</FormHelperText>
    </Box>
  )
}


/* Following example of virtualized autocomplete has been taken
from MaterialUI documentation:
https://material-ui.com/components/autocomplete/#virtualization

react-window, which is used for virtualization, need to know height
of the element before it is rendered. It is obvious if one takes
into account how it works. So we need to clip descriptions.

On the other hand, I doubt that having a full list is useful from the
users point of view. It is too long. If the list has more elements
than browser can render in reasonable time, then it is not much use
for the user.

I believe that the previous behavior with filter and limit was better.
Maybe we should have just increased limit.
*/

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  // const theme = useTheme();
  // const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = 100  //smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});


const filterOptions = createFilterOptions({
  // limit: 10,
  stringify: (option: {code: string, description: string}) => option.code + " " + option.description,
});