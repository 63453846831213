import { Box, FormControlLabel } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import { PromoteurInfo, StepParams, Partner } from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CancelIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InputAdornment from "@mui/material/InputAdornment";
import OperationsGrid from "./CrudGrid";

export const emptyPromoteurInfo: PromoteurInfo = {
  raisonSociale: "",
  dedicatedCompany: null,
  siret: "",
  partners: null,
  partnersOpHistory: null,
  otherExp: "",
};

const emptyPartner = { name: "", shares: "", siret: "" };

const isValidSiren = (siren: string) => (siren ? siren.length === 9 : true);

export function PromoteurForm({ value, next }: StepParams<PromoteurInfo>) {
  const [raisonSociale, setRaisonSociale] = useState(value.raisonSociale);
  const [dedicatedCompany, setDedicatedCompany] = useState(
    value.dedicatedCompany
  );
  const [siret, setSiret] = useState(value.siret);
  const [partners, setPartners] = useState(value.partners || ([] as Partner[]));
  const [partnersOpHistory, setPartnersOpHistory] = useState(
    value.partnersOpHistory
  );
  const [otherExp, setOtherExp] = useState(value.otherExp);
  // eslint-disable-next-line
  const [highlight, setHighLight] = useState(false);
  const [newPartner, setNewPartner] = useState(emptyPartner);

  let promInfo = {
    raisonSociale,
    dedicatedCompany,
    siret,
    partners,
    partnersOpHistory: partnersOpHistory ? [...partnersOpHistory] : [],
    otherExp,
  };

  const handlePartnersUpdate = (
    index: number,
    field: keyof Partner,
    value: string
  ) => {
    if (!partners || index === partners.length) {
      const part = {
        name: "",
        shares: "",
        siret: "",
      };
      part[field] = value;
      setPartners([...partners, part]);
    } else {
      const part = { ...partners[index] };
      part[field] = value;
      const tmpPartners = [...partners];
      tmpPartners[index] = part;
      setPartners(tmpPartners);
    }
  };

  const handleAddPartner = () => {
    setPartners([...partners, newPartner]);
    setNewPartner(emptyPartner);
  };

  const handleDeletePartner = (index: number) => {
    const tmp = partners.filter((elem, idx) => idx !== index);
    setPartners(tmp);
  };

  const validPartners = (
    promInfo: PromoteurInfo | null,
    isLastVal: Boolean = false
  ) => {
    let partnersToVerify = [];
    if (isLastVal && promInfo && promInfo.partners)
      partnersToVerify = promInfo.partners;
    else partnersToVerify = partners;
    if (partnersToVerify.length !== 0) {
      if (
        (partnersToVerify.length === 1 && partnersToVerify[0].shares === "") ||
        partnersToVerify[0].shares === "100"
      )
        return true;
      else {
        const totalParts = partnersToVerify.reduce(
          (acc, curr) => acc + Number(curr.shares || "200"),
          0
        );
        return totalParts === 100;
      }
    }
    return true;
  };

  const validPartnersSiren = (lastPartners: Partner[] | null) => {
    const PartnersToVerify = lastPartners || partners;
    if (PartnersToVerify.length !== 0) {
      return PartnersToVerify.every((curr) =>
        curr.siret ? isValidSiren(curr.siret || "") : true
      );
    }
    return true;
  };

  const validPartnersNames = (lastPartners: Partner[] | null) => {
    const PartnersToVerify = lastPartners || partners;
    if (PartnersToVerify.length !== 0) {
      return PartnersToVerify.every((curr) =>
        (curr.siret || curr.shares) && !curr.name ? false : true
      );
    }
  };

  const valid = (
    promInfo: PromoteurInfo | null,
    isLastVal: Boolean = false
  ) => {
    return (
      promInfo &&
      promInfo.raisonSociale &&
      promInfo.siret &&
      promInfo.dedicatedCompany !== null &&
      promInfo.siret &&
      (partners.length !== 0
        ? validPartners(promInfo, isLastVal) &&
          validPartnersSiren(promInfo.partners) &&
          validPartnersNames(promInfo.partners)
        : true) &&
      isValidSiren(siret || "")
    );
  };

  return (
    <Box>
      <form className="basic-info" noValidate autoComplete="off">
        <Grid item xs={12} md={6}>
          <TextField
            label="Raison sociale"
            value={raisonSociale}
            name="denomination-sociale"
            onChange={(event) => setRaisonSociale(event.target.value)}
            required
            fullWidth
            error={highlight && (raisonSociale ? false : true)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box mt={5}>
            <Typography>
              S'agit-il d'une société dédiée à l'opération ? *
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color:
                      dedicatedCompany !== null
                        ? "primary"
                        : highlight
                        ? "red"
                        : "primary",
                  }}
                  checked={dedicatedCompany === true}
                  color="primary"
                  onChange={() => setDedicatedCompany(true)}
                />
              }
              label="OUI"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: dedicatedCompany
                      ? "primary"
                      : highlight
                      ? "red"
                      : "primary",
                  }}
                  checked={dedicatedCompany === false}
                  color="primary"
                  onChange={() => setDedicatedCompany(false)}
                />
              }
              label="NON"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="SIREN"
            value={siret}
            name="numero-siren"
            onChange={(event) => setSiret(event.target.value)}
            error={highlight ? (siret || "").length !== 9 : false}
            required
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
          {!isValidSiren(siret || "") ? (
            <p style={{ color: "red" }}>
              Le numéro SIREN doit comporter 9 chiffres.
            </p>
          ) : null}
        </Grid>
        {partners.length !== 0 && (
          <Box mt={5}>
            <Typography>
              Noms des associés et répartition du capital :
            </Typography>
          </Box>
        )}

        <Grid item xs={12} md={9}>
          {partners
            ? partners.map((partner, index) => (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                      <TextField
                        label="Nom"
                        value={partner.name}
                        onChange={(event) =>
                          handlePartnersUpdate(
                            index,
                            "name",
                            event.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        label="Part"
                        value={partner.shares}
                        onChange={(event) =>
                          handlePartnersUpdate(
                            index,
                            "shares",
                            event.target.value
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputProps: { min: 0 },
                        }}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        label="SIREN"
                        value={partner.siret}
                        onChange={(event) =>
                          handlePartnersUpdate(
                            index,
                            "siret",
                            event.target.value
                          )
                        }
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                      />
                    </Grid>

                    <IconButton
                      color="primary"
                      onClick={() => handleDeletePartner(index)}
                      sx={{
                        alignSelf: "flex-end",
                      }}
                    >
                      <CancelIcon sx={{ height: "30px" }} />
                    </IconButton>
                  </Grid>
                  {(partner.shares || partner.siret) && !partner.name ? (
                    <p style={{ color: "red" }}>
                      Le nom de l'associé ci dessus est manquant.
                    </p>
                  ) : null}
                  {!isValidSiren(partner.siret) ? (
                    <p style={{ color: "red" }}>
                      Le numéro SIREN ci-dessus doit comporter 9 chiffres.
                    </p>
                  ) : null}
                </>
              ))
            : null}
          {!validPartners(promInfo, false) ? (
            <p style={{ color: "red" }}>
              Le nombre de parts total doit être égal à 100%. Merci d'ajouter,
              d'enlever un associé ou de revoir la répartition du capital.
            </p>
          ) : null}
          <Box mt={5}>
            <Typography>Ajoutez un associé :</Typography>
          </Box>
          <Box mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={3}>
                <TextField
                  label="Nom"
                  value={newPartner.name}
                  onChange={(event) =>
                    setNewPartner({ ...newPartner, name: event.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  label="Part"
                  type="number"
                  value={newPartner.shares}
                  onChange={(event) =>
                    setNewPartner({ ...newPartner, shares: event.target.value })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <TextField
                  label="SIREN"
                  value={newPartner.siret}
                  onChange={(event) =>
                    setNewPartner({ ...newPartner, siret: event.target.value })
                  }
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </Grid>
              <IconButton
                color="primary"
                onClick={handleAddPartner}
                sx={{
                  alignSelf: "flex-end",
                }}
              >
                <AddCircleOutlineIcon sx={{ height: "30px" }} />
              </IconButton>
            </Grid>
            {!isValidSiren(newPartner.siret || "") ? (
              <p style={{ color: "red" }}>
                Le numéro SIREN doit comporter 9 chiffres.
              </p>
            ) : null}
            {highlight &&
            (newPartner.shares || newPartner.siret) &&
            !newPartner.name ? (
              <p style={{ color: "red" }}>
                Le nom de l'associé ci-dessus est manquant.
              </p>
            ) : null}
          </Box>
        </Grid>
        <Box mt={8}>
          <Typography>
            Expérience des actionnaires, opérations déjà réalisées :
          </Typography>
        </Box>
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <Box width="100%">
            <OperationsGrid
              onEdit={setPartnersOpHistory}
              initRows={partnersOpHistory}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <Box display="flex" flexDirection="row" alignContent={"center"}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                alignSelf: "flex-end",
              }}
            >
              Autres expériences :
            </Typography>
            <TextField
              type="text"
              value={otherExp}
              onChange={(event) => setOtherExp(event.target.value)}
              sx={{
                paddingLeft: 2,
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
              variant="standard"
              fullWidth
            />
          </Box>
        </Grid>
      </form>
      <Box mt={5}>
        <legend>
          <Button
            variant="contained"
            disabled={false}
            color="primary"
            startIcon={<NavigateNextIcon />}
            onClick={() => {
              let lastPromInfo = promInfo;
              if (newPartner.name || newPartner.shares || newPartner.siret) {
                lastPromInfo = {
                  ...promInfo,
                  partners: [...partners, newPartner],
                };
              }
              if (valid(lastPromInfo, true)) next(lastPromInfo);
              else {
                setHighLight(true);
                if (newPartner.name || newPartner.shares || newPartner.siret)
                  setPartners([...partners, newPartner]);
                setNewPartner(emptyPartner);
              }
            }}
          >
            Continuer
          </Button>
        </legend>
      </Box>
    </Box>
  );
}
