import { useAuth0 } from "@auth0/auth0-react";
import {
  CompanyInfo,
  OfferListParams,
  StructureOptions,
  SereniteParams,
  SerenitePrice,
  ProposalParams,
  CompanyInfoINSEE,
  CreateProposalStatus,
  Proposal,
  JWTToken,
  Permission,
  FileItem,
  NewProposal,
  Prices,
  Offers,
  DownloadItem,
  DocumentItem,
  Questionnaire,
} from "./components/types";
import { api_url, audience } from "./config";
import jwt_decode from "jwt-decode";
import { parse as parseContentDisposition } from "content-disposition";

async function postRequest<T, K>(
  url: string,
  body: T,
  token: string
): Promise<K> {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  if (response.ok) {
    const opts = await response.json();
    return opts;
  } else {
    throw new Error("Error from API");
  }
}

/* async function postRequestFiles<T, K>(url: string, body: T, token: string): Promise<K> {
    console.log(body)
    /* const formData = new FormData()
        //@ts-ignore

    if (!(body === undefined || body.path === undefined)) {
                //@ts-ignore
        const base64Response = await fetch(body.path.split(',')[1])
        const blob = await base64Response.blob()
        console.log(blob)
        //@ts-ignore
        formData.append("file", blob)
            //@ts-ignore
        formData.append("filename", body.name)
    
    } */
//@ts-ignore
/*     const response = await fetch(url, {
        method: "POST",
        headers: {

            Authorization: `Bearer ${token}`,
        },
            //@ts-ignore
        
    });

    if (response.ok) {
        const opts = await response.json()
        return opts
    } else {
        throw new Error("Error from API")
    }
}
 */
async function getRequest<K>(url: string, token: string): Promise<K> {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.ok) {
    const opts = await response.json();
    return opts;
  } else {
    throw new Error("Could not load company information");
  }
}

async function simpleRequest(
  method: string,
  url: string,
  token: string
): Promise<void> {
  const response = await fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch(() => ({ ok: false }));

  if (response.ok) {
    return;
  } else {
    throw new Error("Could not load company information");
  }
}

export function useAPI() {
  const { getAccessTokenSilently } = useAuth0();

  function getPermissions() {
    return async function (): Promise<Permission[]> {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
      });

      if (accessToken) {
        const decodedAccessToken: JWTToken = jwt_decode(accessToken);
        return decodedAccessToken.permissions;
      } else {
        throw new Error("No access token");
      }
    };
  }

  function post<T, K>(url: string) {
    return async function (body: T): Promise<K> {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
      });
      return postRequest(url, body, accessToken);
    };
  }

  function postFiles<T, K>(url: string) {
    return async function (body: T): Promise<K> {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
      });
      return postRequest(url, body, accessToken);
    };
  }

  function get<K>(url: string) {
    return async function (): Promise<K> {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
      });
      return getRequest(`${url}`, accessToken);
    };
  }

  async function getFile(url: string, fname?: string): Promise<DownloadItem> {
    const accessToken = await getAccessTokenSilently({
      audience: audience,
    });

    const response = await fetch(api_url + url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      let contentDisposition = response.headers.get(
        "Content-Disposition"
      ) as string;
      let name = "";
      try {
        name = parseContentDisposition(contentDisposition).parameters.filename;
      } catch (error) {
        // Sometimes there is a bug in content-disposition parsing method with utf8 filemame extended field
        // so in this case we apply regex extraction to the content-disposition header
        console.log(error);
        contentDisposition = decodeURIComponent(contentDisposition);
        let r = new RegExp(/(?<=UTF-8'')(.*$)/g);
        name = r.exec(contentDisposition)![0] || fname || "Download";
      }
      const blob = await response.blob();
      return { name, blob };
    } else {
      throw new Error("Could not load content");
    }
  }

  return {
    getOptions: post<CompanyInfo, StructureOptions>(
      `${api_url}/v1/offer/structure-options`
    ),
    getOffers: post<OfferListParams, Offers>(`${api_url}/v1/offer/offers`),
    getSerenite: post<SereniteParams, SerenitePrice>(
      `${api_url}/v1/offer/serenite`
    ),
    getProposalPrices: post<ProposalParams, Prices>(
      `${api_url}/v1/offer/proposal`
    ),
    getCompanyInfo: (siret: string) =>
      get<CompanyInfoINSEE>(`${api_url}/v1/offer/company-info/${siret}`),
    uploadFiles: postFiles(`${api_url}/v1/upload/`),
    removeFiles: postFiles(`${api_url}/v1/remove/`),
    uploadFilesGfa: postFiles(`${api_url}/v1/gfa/upload/`),
    removeFilesGfa: postFiles(`${api_url}/v1/gfa/remove/`),
    saveProposal: post<NewProposal, { status: CreateProposalStatus }>(
      `${api_url}/v1/proposal/save`
    ),
    saveGfaForm: post<Questionnaire, { status: CreateProposalStatus }>(
      `${api_url}/v1/gfa/save`
    ),

    listProposals: get<Proposal[]>(`${api_url}/v1/proposal/list`),
    listGfaForms: get<Questionnaire[]>(`${api_url}/v1/gfa/list`),
    getProposal: (key: string) =>
      get<Proposal>(`${api_url}/v1/proposal/${key}`),
    getGfaForm: (key: string) => get<Questionnaire>(`${api_url}/v1/gfa/${key}`),
    requestContract: post<
      { proposalId: string; starts_at: string },
      { status: CreateProposalStatus }
    >(`${api_url}/v1/proposal/request-contract`),
    requestCheckDocs: post<string, { status: CreateProposalStatus }>(
      `${api_url}/v1/proposal/check-docs`
    ),
    requestCheckDocsGfa: post<string, { status: CreateProposalStatus }>(
      `${api_url}/v1/gfa/check-docs`
    ),
    signContract: post<string, { status: CreateProposalStatus }>(
      `${api_url}/v1/proposal/sign-contract`
    ),
    signContractGfa: post<string, { status: CreateProposalStatus }>(
      `${api_url}/v1/gfa/sign-contract`
    ),
    cancelProposal: post<string, { status: CreateProposalStatus }>(
      `${api_url}/v1/proposal/cancel`
    ),
    invalidateDocs: post<
      { proposalId: string; admin_message: string },
      { status: CreateProposalStatus }
    >(`${api_url}/v1/proposal/invalidate-docs`),
    invalidateDocsGfa: post<
      { gfaFormId: string; admin_message: string },
      { status: CreateProposalStatus }
    >(`${api_url}/v1/gfa/invalidate-docs`),
    getPermissions: getPermissions(),

    getDocumentList: (key: string) =>
      get<DocumentItem[]>(`${api_url}/v1/proposal/${key}/document/`),
    getDocumentListGfa: (key: string) =>
      get<DocumentItem[]>(`${api_url}/v1/gfa/${key}/document/`),
    getKitsList: get<FileItem[]>(`${api_url}/v1/kits-de-distribution/`),
    getFile,

    getFaqContent: (section: string) =>
      get<string>(`${api_url}/v1/faq/${section}`),
  };
}

export type UserList = {
  length: number;
  start: number;
  limit: number;
  users: User[];
};

export type User = {
  user_id: string;
  email: string;
  name: string;
  picture: string;
  admin?: boolean;
  broker?: boolean;
  active?: boolean;
};

export type Role = {
  id: string;
  name: string;
  description: string;
};

export function useUserAPI() {
  const { getAccessTokenSilently } = useAuth0();

  function get<K>(url: string) {
    return async function (): Promise<K> {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: "manage:all:users",
      });
      return getRequest(`${url}`, accessToken);
    };
  }

  function put(url: string) {
    return async function (): Promise<void> {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: "manage:all:users",
      });
      return simpleRequest("PUT", `${url}`, accessToken);
    };
  }

  function del(url: string) {
    return async function (): Promise<void> {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: "manage:all:users",
      });
      return simpleRequest("DELETE", `${url}`, accessToken);
    };
  }

  return {
    listUsers: (p: number) => get<User[]>(`${api_url}/v1/users/list/${p}`),
    activateBroker: (id: string) => put(`${api_url}/v1/users/${id}/activate`),
    deactivateBroker: (id: string) => del(`${api_url}/v1/users/${id}/activate`),
  };
}
