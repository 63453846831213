import {
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from "@material-ui/core";
import { useCallback, useState } from "react";
import { PaymentDetails, StepParams } from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const emptyPaymentDetails: PaymentDetails = {
  declareMode: false,
  paymentMethod: false,
};

export function SelectPaymentMethod({
  value,
  next,
}: StepParams<PaymentDetails>) {
  const [declareMode, setDeclareMode] = useState(value.declareMode);
  const [paymentMethod, setPaymentMethod] = useState(value.paymentMethod);

  const valid = useCallback(() => {
    return declareMode && paymentMethod;
    // eslint-disable-next-line
  }, [declareMode, paymentMethod]);

  const paymentDetails: PaymentDetails = {
    declareMode: declareMode,
    paymentMethod: paymentMethod,
  };

  return (
    <Box>
      <form className="basic-info" noValidate autoComplete="off">
        <Box mt={3}>
          La périodicité de la quittance est mensuelle à terme échu. Merci de
          choisir votre mode de déclaration et de paiement.
        </Box>
        <Box mt={5}>
          <Typography variant="h3">Mode de déclaration :</Typography>
          {/*         <Typography variant="subtitle1" >Mode de déclaration</Typography>
           */}{" "}
        </Box>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={declareMode === "DSN"}
                color="primary"
                onChange={() => setDeclareMode("DSN")}
              />
            }
            label="DSN"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={declareMode === "hors DSN"}
                color="primary"
                onChange={() => setDeclareMode("hors DSN")}
              />
            }
            label="Hors DSN"
          />
        </Box>
        <Box mt={5}>
          <Typography variant="h3">Mode de paiement :</Typography>
          {/*         <Typography variant="subtitle1" >Choisissez la périodicité du prélèvement.</Typography>
           */}{" "}
        </Box>
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={paymentMethod === "prélévement"}
                color="primary"
                onChange={() => setPaymentMethod("prélévement")}
              />
            }
            label="Prélévement"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={paymentMethod === "virement"}
                color="primary"
                onChange={() => setPaymentMethod("virement")}
              />
            }
            label="Virement"
          />
          {/* <FormControlLabel control={<Checkbox checked={paymentMethod === "chèque"} color="primary" onChange={() => setPaymentMethod("chèque")}/>} label="Chèque" />*/}{" "}
        </Box>
      </form>
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          disabled={!valid()}
          startIcon={<NavigateNextIcon />}
          onClick={() => next(paymentDetails)}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}
