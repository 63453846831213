import { CodeType } from "../components/types";

export const codeCCNList: CodeType[] = [
  {
    "code": "0003",
    "description": "Convention collective nationale des ouvriers de la navigation int\u00e9rieure de marchandises"
  },
  {
    "code": "0016",
    "description": "Convention collective nationale des transports routiers et activit\u00e9s auxiliaires du transport"
  },
  {
    "code": "0018",
    "description": "Convention collective nationale des industries textiles"
  },
  {
    "code": "0029",
    "description": "Convention collective nationale des \u00e9tablissements priv\u00e9s d'hospitalisation, de soins, de cure et de garde \u00e0 but non lucratif (FEHAP, convention de 1951)"
  },
  {
    "code": "0043",
    "description": "Convention collective nationale des entreprises de commission, de courtage et de commerce intracommunautaire et d'importation-exportation de France m\u00e9tropolitaine"
  },
  {
    "code": "0044",
    "description": "Convention collective nationale des industries chimiques et connexes"
  },
  {
    "code": "0045",
    "description": "Convention collective nationale du caoutchouc"
  },
  {
    "code": "0054",
    "description": "Convention collective r\u00e9gionale des ouvriers, employ\u00e9s, techniciens et agents de ma\u00eetrise des industries m\u00e9tallurgiques, m\u00e9caniques et connexes de la r\u00e9gion parisienne"
  },
  {
    "code": "0086",
    "description": "Convention collective nationale des entreprises de publicit\u00e9 et assimil\u00e9es"
  },
  {
    "code": "0087",
    "description": "Convention collective nationale des ouvriers des industries de carri\u00e8res et de mat\u00e9riaux"
  },
  {
    "code": "0112",
    "description": "Convention collective nationale de l'industrie laiti\u00e8re"
  },
  {
    "code": "0135",
    "description": "Convention collective nationale des employ\u00e9s techniciens et agents de ma\u00eetrise des industries de carri\u00e8res et de mat\u00e9riaux"
  },
  {
    "code": "0158",
    "description": "Convention collective nationale du travail m\u00e9canique du bois, des scieries, du n\u00e9goce et de l'importation des bois"
  },
  {
    "code": "0172",
    "description": "Convention collective interr\u00e9gionale de l'industrie du bois de pin maritime en for\u00eat de Gascogne (Charente, Aquitaine) (annex\u00e9e \u00e0 la convention collective nationale du travail m\u00e9canique du bois, des scieries, du n\u00e9goce et de l'importation des bois 0158)"
  },
  {
    "code": "0176",
    "description": "Convention collective nationale de l'industrie pharmaceutique"
  },
  {
    "code": "0184",
    "description": "Convention collective nationale de l'imprimerie de labeur et des industries graphiques"
  },
  {
    "code": "0200",
    "description": "Convention collective nationale des exploitations frigorifiques"
  },
  {
    "code": "0207",
    "description": "Convention collective nationale de l'industrie des cuirs et peaux (annex\u00e9e \u00e0 la convention collective nationale de travail des industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir 2528)"
  },
  {
    "code": "0211",
    "description": "Convention collective nationale des cadres des industries de carri\u00e8res et mat\u00e9riaux (UNICEM)"
  },
  {
    "code": "0214",
    "description": "Convention collective r\u00e9gionale des ouvriers des entreprises de presse de la r\u00e9gion parisienne"
  },
  {
    "code": "0218",
    "description": "Convention collective nationale des organismes de s\u00e9curit\u00e9 sociale"
  },
  {
    "code": "0233",
    "description": "Convention collective d\u00e9partementale des ma\u00eetres et artisans tailleurs de la Loire-Atlantique"
  },
  {
    "code": "0240",
    "description": "Convention collective nationale du personnel des greffes des tribunaux de commerce"
  },
  {
    "code": "0247",
    "description": "Convention collective nationale des industries de l'habillement"
  },
  {
    "code": "0270",
    "description": "Convention collective d\u00e9partementale de la couture des Bouches-du-Rh\u00f4ne"
  },
  {
    "code": "0275",
    "description": "Convention collective nationale du personnel au sol des entreprises de transport a\u00e9rien"
  },
  {
    "code": "0292",
    "description": "Convention collective nationale de la plasturgie (transformation des mati\u00e8res plastiques)"
  },
  {
    "code": "0303",
    "description": "Convention collective r\u00e9gionale de la couture parisienne"
  },
  {
    "code": "0306",
    "description": "Convention collective r\u00e9gionale des cadres techniques de la presse quotidienne parisienne"
  },
  {
    "code": "0311",
    "description": "Convention collective d\u00e9partementale de la couture de la Loire-Atlantique"
  },
  {
    "code": "0379",
    "description": "Convention collective d\u00e9partementale du travail des commerces de la Martinique"
  },
  {
    "code": "0394",
    "description": "Convention collective r\u00e9gionale des employ\u00e9s de la presse quotidienne parisienne"
  },
  {
    "code": "0405",
    "description": "Convention collective nationale des \u00e9tablissements m\u00e9dico-sociaux de l'union intersyndicale des secteurs sanitaires et sociaux (UNISSS, FFESCPE, convention de 1965, enfants, adolescents )"
  },
  {
    "code": "0412",
    "description": "Convention collective nationale de travail des guides accompagnateurs et accompagnateurs au service des agences de voyages et de tourisme (annex\u00e9e \u00e0 la convention collective nationale du personnel des agences de voyages et de tourisme 1710)"
  },
  {
    "code": "0413",
    "description": "Convention collective nationale de travail des \u00e9tablissements et services pour personnes inadapt\u00e9es et handicap\u00e9es (convention de 1966, SNAPEI)"
  },
  {
    "code": "0418",
    "description": "Convention collective nationale de la chemiserie sur mesure (annex\u00e9e \u00e0 la convention collective nationale de la couture parisienne 0303)"
  },
  {
    "code": "0438",
    "description": "Convention collective nationale de travail des \u00e9chelons interm\u00e9diaires des services ext\u00e9rieurs de production des soci\u00e9t\u00e9s d'assurances"
  },
  {
    "code": "0440",
    "description": "Convention collective d\u00e9partementale des sucreries et sucreries-distilleries de la R\u00e9union"
  },
  {
    "code": "0454",
    "description": "Convention collective nationale des remont\u00e9es m\u00e9caniques et domaines skiables"
  },
  {
    "code": "0468",
    "description": "Convention collective nationale du commerce succursaliste de la chaussure"
  },
  {
    "code": "0478",
    "description": "Convention collective nationale des soci\u00e9t\u00e9s financi\u00e8res"
  },
  {
    "code": "0489",
    "description": "Convention collective nationale du personnel des industries du cartonnage"
  },
  {
    "code": "0493",
    "description": "Convention collective nationale des vins, cidres, jus de fruits, sirops, spiritueux et liqueurs de France"
  },
  {
    "code": "0500",
    "description": "Convention collective nationale des entreprises de distribution en chaussure, jouet, textile et mercerie"
  },
  {
    "code": "0509",
    "description": "Convention collective r\u00e9gionale des cadres administratifs de la presse quotidienne parisienne"
  },
  {
    "code": "0538",
    "description": "Convention collective nationale du personnel des entreprises de manutention ferroviaire et travaux connexes"
  },
  {
    "code": "0567",
    "description": "Convention collective nationale de la bijouterie, joaillerie, orf\u00e8vrerie et des activit\u00e9s qui s'y rattachent"
  },
  {
    "code": "0572",
    "description": "Convention collective r\u00e9gionale de la couture et de la haute-couture de la r\u00e9gion Rh\u00f4ne-Alpes"
  },
  {
    "code": "0573",
    "description": "Convention collective nationale des commerces de gros"
  },
  {
    "code": "0598",
    "description": "Convention collective nationale de travail des ouvriers de la presse quotidienne r\u00e9gionale"
  },
  {
    "code": "0614",
    "description": "Convention collective nationale des industries de la s\u00e9rigraphie et des proc\u00e9d\u00e9s d'impression num\u00e9rique connexes (annex\u00e9e \u00e0 la convention collective nationale de l'imprimerie de labeur et des industries graphiques 0184)"
  },
  {
    "code": "0627",
    "description": "Convention collective d\u00e9partementale des employ\u00e9s, techniciens et agents de ma\u00eetrise du b\u00e2timent et des travaux publics de La R\u00e9union"
  },
  {
    "code": "0635",
    "description": "Convention collective nationale du n\u00e9goce en fournitures dentaires (annex\u00e9e \u00e0 la convention collective nationale des commerces de gros 0573)"
  },
  {
    "code": "0637",
    "description": "Convention collective nationale des industries et du commerce de la r\u00e9cup\u00e9ration (recyclage, r\u00e9gions Nord-Pas-de-Calais, Picardie)"
  },
  {
    "code": "0650",
    "description": "Convention collective nationale des ing\u00e9nieurs et cadres de la m\u00e9tallurgie"
  },
  {
    "code": "0653",
    "description": "Convention collective nationale de travail des producteurs salari\u00e9s de base des services ext\u00e9rieurs de production des soci\u00e9t\u00e9s d'assurances"
  },
  {
    "code": "0669",
    "description": "Convention collective nationale des industries de fabrication m\u00e9canique du verre"
  },
  {
    "code": "0673",
    "description": "Convention collective nationale de la fourrure (annex\u00e9e \u00e0 la convention collective nationale de la couture parisienne 0303)"
  },
  {
    "code": "0675",
    "description": "Convention collective nationale des maisons \u00e0 succursales de vente au d\u00e9tail d'habillement"
  },
  {
    "code": "0693",
    "description": "Convention collective nationale de travail des employ\u00e9s de la presse quotidienne d\u00e9partementale"
  },
  {
    "code": "0698",
    "description": "Convention collective nationale de travail des employ\u00e9s de la presse quotidienne r\u00e9gionale"
  },
  {
    "code": "0700",
    "description": "Convention collective nationale des ing\u00e9nieurs et cadres de la production des papiers, cartons et celluloses"
  },
  {
    "code": "0706",
    "description": "Convention collective nationale du personnel de la reprographie (annex\u00e9e \u00e0 la convention collective nationale des commerces de d\u00e9tail de papeterie, fournitures de bureau, de bureautique et informatique et de librairie 1539)"
  },
  {
    "code": "0707",
    "description": "Convention collective nationale des ing\u00e9nieurs et cadres de la transformation des papiers cartons et de la pellicule cellulosique"
  },
  {
    "code": "0714",
    "description": "Convention collective d\u00e9partementale des industries du travail des m\u00e9taux de la Moselle (m\u00e9tallurgie)"
  },
  {
    "code": "0715",
    "description": "Convention collective nationale des instruments \u00e0 \u00e9crire et des industries connexes (annex\u00e9e \u00e0 la convention collective du personnel des industries du cartonnage 0489)"
  },
  {
    "code": "0716",
    "description": "Convention collective nationale des employ\u00e9s et ouvriers de la distribution cin\u00e9matographique"
  },
  {
    "code": "0731",
    "description": "Convention collective nationale des commerces de quincaillerie, fournitures industrielles, fers, m\u00e9taux et \u00e9quipement de la maison, cadres"
  },
  {
    "code": "0733",
    "description": "Convention collective nationale des d\u00e9taillants en chaussure"
  },
  {
    "code": "0749",
    "description": "Convention collective d\u00e9partementale des ouvriers du b\u00e2timent et des travaux publics de la Martinique"
  },
  {
    "code": "0759",
    "description": "Convention collective nationale des pompes fun\u00e8bres"
  },
  {
    "code": "0771",
    "description": "Convention collective d\u00e9partementale des ing\u00e9nieurs assimil\u00e9s et cadres du b\u00e2timent et des travaux publics de La R\u00e9union"
  },
  {
    "code": "0779",
    "description": "Convention collective nationale  de travail du personnel des voies ferr\u00e9es d'int\u00e9r\u00eat local"
  },
  {
    "code": "0780",
    "description": "Convention collective r\u00e9gionale des tailleurs sur mesure de la r\u00e9gion parisienne (annex\u00e9e \u00e0 la convention collective nationale de la couture parisienne 0303)"
  },
  {
    "code": "0781",
    "description": "Convention collective nationale des cadres administratifs de la presse quotidienne d\u00e9partementale"
  },
  {
    "code": "0783",
    "description": "Convention collective nationale des centres d'h\u00e9bergement et de r\u00e9adaptation sociale et dans les services d'accueil, d'orientation et d'insertion pour adultes (CHRS, SOP )"
  },
  {
    "code": "0787",
    "description": "Convention collective nationale des cabinets d'experts-comptables et de commissaires aux comptes"
  },
  {
    "code": "0802",
    "description": "Convention collective nationale de la distribution de papiers-cartons commerces de gros pour les ouvriers, employ\u00e9s, techniciens et agents de ma\u00eetrise"
  },
  {
    "code": "0804",
    "description": "Accord national interprofessionnel des voyageurs, repr\u00e9sentants, placiers (VRP)"
  },
  {
    "code": "0822",
    "description": "Convention collective d\u00e9partementale applicable aux mensuels de la m\u00e9tallurgie de la Savoie"
  },
  {
    "code": "0827",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes des Ardennes"
  },
  {
    "code": "0828",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, connexes et similaires de la Manche"
  },
  {
    "code": "0829",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques et des industries connexes du Vaucluse"
  },
  {
    "code": "0836",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, connexes et similaires de la Haute-Savoie"
  },
  {
    "code": "0843",
    "description": "Convention collective nationale de la boulangerie-p\u00e2tisserie -entreprises artisanales-"
  },
  {
    "code": "0860",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie et des industries connexes du Finist\u00e8re"
  },
  {
    "code": "0863",
    "description": "Convention collective r\u00e9gionale des industries m\u00e9tallurgiques et connexes d'Ille-et-Vilaine et du Morbihan"
  },
  {
    "code": "0878",
    "description": "Convention collective d\u00e9partementale des mensuels des industries m\u00e9tallurgiques du Rh\u00f4ne"
  },
  {
    "code": "0887",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques et connexes de l'Eure"
  },
  {
    "code": "0892",
    "description": "Convention collective nationale des cadres et agents de ma\u00eetrise de la distribution de films de l'industrie cin\u00e9matographique"
  },
  {
    "code": "0897",
    "description": "Convention collective nationale des services de sant\u00e9 au travail interentreprises"
  },
  {
    "code": "0898",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, connexes et similaires de l'Allier"
  },
  {
    "code": "0899",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes de la Marne"
  },
  {
    "code": "0901",
    "description": "Convention collective d\u00e9partementale des ouvriers de la boulangerie de la Martinique"
  },
  {
    "code": "0911",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, connexes et similaires de Seine-et-Marne"
  },
  {
    "code": "0914",
    "description": "Convention collective d\u00e9partementale des mensuels des industries m\u00e9tallurgiques de l'Ain"
  },
  {
    "code": "0915",
    "description": "Convention collective nationale des soci\u00e9t\u00e9s d'expertises et d'\u00e9valuations"
  },
  {
    "code": "0919",
    "description": "Convention collective d\u00e9partementale du personnel des garages de la Martinique (Automobile, commerce, r\u00e9paration)"
  },
  {
    "code": "0920",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques \u00e9lectriques et connexes de la Vienne"
  },
  {
    "code": "0923",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie de la Charente-Maritime"
  },
  {
    "code": "0925",
    "description": "Convention collective nationale des ing\u00e9nieurs et cadres de la distribution des papiers et cartons, commerce de gros"
  },
  {
    "code": "0930",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie et des industries connexes de la Sarthe"
  },
  {
    "code": "0934",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques m\u00e9caniques connexes et similaires de l'Indre"
  },
  {
    "code": "0937",
    "description": "Convention collective r\u00e9gionale des industries m\u00e9tallurgiques m\u00e9caniques et connexes de la Haute-Vienne et de la Creuse"
  },
  {
    "code": "0943",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques m\u00e9caniques et connexes du Calvados"
  },
  {
    "code": "0948",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes de l'Orne"
  },
  {
    "code": "0953",
    "description": "Convention collective nationale de la charcuterie de d\u00e9tail"
  },
  {
    "code": "0959",
    "description": "Convention collective nationale des laboratoires de biologie m\u00e9dicale extra-hospitaliers"
  },
  {
    "code": "0965",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques et connexes du d\u00e9partement du Var"
  },
  {
    "code": "0979",
    "description": "Convention collective locale des industries m\u00e9tallurgiques de l'arrondissement du Havre (Seine-Maritime)"
  },
  {
    "code": "0984",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes d'Eure-et-Loir"
  },
  {
    "code": "0992",
    "description": "Convention collective nationale de la boucherie, boucherie-charcuterie et boucherie hippophagique, triperie, commerce de volailles et gibiers"
  },
  {
    "code": "0993",
    "description": "Convention collective nationale des proth\u00e9sistes dentaires et des personnels des laboratoires de proth\u00e8se dentaire"
  },
  {
    "code": "0998",
    "description": "Convention collective nationale des ouvriers, employ\u00e9s, techniciens et agents de ma\u00eetrise de l'exploitation d'\u00e9quipements thermiques et de g\u00e9nie climatique"
  },
  {
    "code": "1000",
    "description": "Convention collective nationale du personnel des cabinets d'avocats"
  },
  {
    "code": "1001",
    "description": "Convention collective nationale des m\u00e9decins sp\u00e9cialistes qualifi\u00e9s au regard du conseil de l'ordre travaillant dans des \u00e9tablissements et services pour personnes inadapt\u00e9es et handicap\u00e9es (annex\u00e9e \u00e0 la convention collective nationale de travail des \u00e9tablissements et services pour personnes inadapt\u00e9es et handicap\u00e9es 0413)"
  },
  {
    "code": "1007",
    "description": "Convention collective locale d'arrondissement des industries m\u00e9tallurgiques, m\u00e9caniques, connexes et similaires de la r\u00e9gion de Thiers (Puy-de-D\u00f4me)"
  },
  {
    "code": "1016",
    "description": "Convention collective nationale des cadres et agents de ma\u00eetrise de l'\u00e9dition de musique (annex\u00e9e \u00e0 la convention collective nationale de l'\u00e9dition 2121)"
  },
  {
    "code": "1018",
    "description": "Convention collective nationale des cadres techniques de la presse quotidienne d\u00e9partementale fran\u00e7aise"
  },
  {
    "code": "1031",
    "description": "Convention collective nationale de la f\u00e9d\u00e9ration nationale des associations familiales rurales (FNAFR)"
  },
  {
    "code": "1043",
    "description": "Convention collective nationale des gardiens, concierges et employ\u00e9s d'immeubles"
  },
  {
    "code": "1044",
    "description": "Convention collective nationale de l'horlogerie (annex\u00e9e \u00e0 la convention collective nationale de la bijouterie, joaillerie, orf\u00e8vrerie et des activit\u00e9s qui s'y rattachent 0567)"
  },
  {
    "code": "1049",
    "description": "Convention collective d\u00e9partementale du b\u00e2timent, des travaux publics et de toutes professions concourant \u00e0 l'acte de b\u00e2tir ou d'am\u00e9nager de Saint Pierre-et-Miquelon"
  },
  {
    "code": "1050",
    "description": "Convention collective d\u00e9partementale du commerce et des services commerciaux de Saint-Pierre-et-Miquelon"
  },
  {
    "code": "1057",
    "description": "Convention collective d\u00e9partementale des consignataires de navire et manutentionnaires de la Martinique"
  },
  {
    "code": "1059",
    "description": "Convention collective r\u00e9gionale des industries m\u00e9tallurgiques, \u00e9lectriques, \u00e9lectroniques et connexes de Midi-Pyr\u00e9n\u00e9es (Ari\u00e8ge, Aveyron, Gers, Haute-Garonne, Tarn, Tarn-et-Garonne, Lot, Aude convention du 1er avril 1980 )"
  },
  {
    "code": "1060",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie de la Martinique"
  },
  {
    "code": "1069",
    "description": "Convention collective d\u00e9partementale de la r\u00e9partition et des d\u00e9p\u00f4ts pharmaceutiques de la Martinique"
  },
  {
    "code": "1072",
    "description": "Convention collective locale du travail r\u00e8glementant le travail de manutention dans le port de Saint-Pierre"
  },
  {
    "code": "1077",
    "description": "Convention collective nationale entreprises du n\u00e9goce et de l'industrie des produits du sol, engrais et produits connexes"
  },
  {
    "code": "1083",
    "description": "Convention collective nationale de travail des ouvriers de la presse quotidienne d\u00e9partementale"
  },
  {
    "code": "1090",
    "description": "Convention collective nationale des services de l'automobile (commerce et r\u00e9paration de l'automobile, du cycle et du motocycle, activit\u00e9s connexes, contr\u00f4le technique automobile, formation des conducteurs auto-\u00e9coles CNPA )"
  },
  {
    "code": "1140",
    "description": "Convention collective d\u00e9partementale des h\u00f4tels caf\u00e9s restaurants de Saint-Pierre-et-Miquelon(HCR)"
  },
  {
    "code": "1147",
    "description": "Convention collective nationale du personnel des cabinets m\u00e9dicaux (m\u00e9decin)"
  },
  {
    "code": "1159",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie de la Ni\u00e8vre"
  },
  {
    "code": "1164",
    "description": "Convention collective locale des industries m\u00e9tallurgiques et annexes de la r\u00e9gion de Vimeu (Somme)"
  },
  {
    "code": "1170",
    "description": "Convention collective nationale de l'industrie des tuiles et briques (CCNTB)"
  },
  {
    "code": "1182",
    "description": "Convention collective nationale des personnels des ports de plaisance"
  },
  {
    "code": "1194",
    "description": "Convention collective nationale des employ\u00e9s de l'\u00e9dition de musique (annex\u00e9e \u00e0 la convention collective nationale de l'\u00e9dition 2121)"
  },
  {
    "code": "1203",
    "description": "Convention collective d\u00e9partementale du commerce et des services de la Guadeloupe"
  },
  {
    "code": "1225",
    "description": "Convention collective d\u00e9partementale du commerce de la R\u00e9union"
  },
  {
    "code": "1232",
    "description": "Convention collective d\u00e9partementale des h\u00f4tels de la Guadeloupe"
  },
  {
    "code": "1237",
    "description": "Convention collective nationale des centres de gestion agr\u00e9\u00e9s"
  },
  {
    "code": "1247",
    "description": "Convention collective d\u00e9partementale auto moto de la R\u00e9union"
  },
  {
    "code": "1256",
    "description": "Convention collective nationale des cadres, ing\u00e9nieurs et assimil\u00e9s des entreprises de gestion d'\u00e9quipements thermiques et de climatisation"
  },
  {
    "code": "1257",
    "description": "Convention collective d\u00e9partementale des employ\u00e9s, agents de ma\u00eetrise et cadres de la pharmacie d'officine de la R\u00e9union"
  },
  {
    "code": "1261",
    "description": "Convention collective nationale des acteurs du lien social et familial : centres sociaux et socioculturels, associations d'accueil de jeunes enfants, associations de d\u00e9veloppement social local (SNAECSO)"
  },
  {
    "code": "1266",
    "description": "Convention collective nationale du personnel des entreprises de restauration de collectivit\u00e9s"
  },
  {
    "code": "1267",
    "description": "Convention collective nationale de la p\u00e2tisserie"
  },
  {
    "code": "1274",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques de la Corr\u00e8ze"
  },
  {
    "code": "1278",
    "description": "Convention collective nationale des personnels PACT et ARIM -centres pour la protection l'am\u00e9lioration et la conservation de l'habitat et associations pour la restauration immobili\u00e8re- (annex\u00e9e \u00e0 la convention collective nationale de l'habitat et du logement accompagn\u00e9s 2336)"
  },
  {
    "code": "1281",
    "description": "Convention collective nationale des employ\u00e9s de la presse hebdomadaire r\u00e9gionale"
  },
  {
    "code": "1285",
    "description": "Convention collective nationale pour les entreprises artistiques et culturelles (SYNDEAC)"
  },
  {
    "code": "1286",
    "description": "Convention collective nationale des d\u00e9taillants, d\u00e9taillants-fabricants et artisans de la confiserie, chocolaterie, biscuiterie"
  },
  {
    "code": "1307",
    "description": "Convention collective nationale de l'exploitation cin\u00e9matographique"
  },
  {
    "code": "1311",
    "description": "Convention collective nationale de la restauration ferroviaire"
  },
  {
    "code": "1314",
    "description": "Convention collective nationale des maisons d'alimentation \u00e0 succursales, supermarch\u00e9s, hypermarch\u00e9s, \"g\u00e9rants mandataires\" (grande distribution)"
  },
  {
    "code": "1315",
    "description": "Convention collective r\u00e9gionale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes de la Haute-Marne et de la Meuse"
  },
  {
    "code": "1316",
    "description": "Convention collective nationale des organismes de tourisme social et familial"
  },
  {
    "code": "1341",
    "description": "Convention collective d\u00e9partementale des industries agroalimentaires de la R\u00e9union"
  },
  {
    "code": "1351",
    "description": "Convention collective nationale des entreprises de pr\u00e9vention et de s\u00e9curit\u00e9"
  },
  {
    "code": "1353",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques et connexes de la Dordogne"
  },
  {
    "code": "1365",
    "description": "Convention collective d\u00e9partementale de travail des industries de transformation des m\u00e9taux de Meurthe-et-Moselle (m\u00e9tallurgie)"
  },
  {
    "code": "1369",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, \u00e9lectriques, \u00e9lectroniques et connexes de Loire-Atlantique"
  },
  {
    "code": "1383",
    "description": "Convention collective nationale des commerces de quincaillerie, fournitures industrielles, fers, m\u00e9taux et \u00e9quipement de la maison, employ\u00e9s et personnels de ma\u00eetrise"
  },
  {
    "code": "1387",
    "description": "Convention collective locale des mensuels des industries m\u00e9tallurgiques des Flandres"
  },
  {
    "code": "1388",
    "description": "Convention collective nationale de l'industrie du p\u00e9trole"
  },
  {
    "code": "1391",
    "description": "Convention collective r\u00e9gionale concernant le personnel de l'industrie, de la manutention et du nettoyage sur les a\u00e9roports ouverts \u00e0 la circulation publique de la r\u00e9gion parisienne (annex\u00e9e \u00e0 la convention collective nationale du personnel au sol des entreprises de transport a\u00e9rien 0275)"
  },
  {
    "code": "1396",
    "description": "Convention collective nationale pour les industries de produits alimentaires \u00e9labor\u00e9s"
  },
  {
    "code": "1404",
    "description": "Convention collective nationale des entreprises de commerce, de location et de r\u00e9paration de tracteurs, machines et mat\u00e9riels agricoles, de mat\u00e9riels de travaux publics, de b\u00e2timent et de manutention, de mat\u00e9riels de motoculture de plaisance, de jardins et d'espaces verts (SEDIMA)"
  },
  {
    "code": "1405",
    "description": "Convention collective nationale des entreprises d'exp\u00e9dition et d'exportation de fruits et l\u00e9gumes"
  },
  {
    "code": "1408",
    "description": "Convention collective nationale des entreprises du n\u00e9goce et de distribution de combustibles solides, liquides, gazeux et produits p\u00e9troliers"
  },
  {
    "code": "1411",
    "description": "Convention collective nationale de la fabrication de l'ameublement"
  },
  {
    "code": "1412",
    "description": "Convention collective nationale des entreprises d'installation sans fabrication, y compris entretien, r\u00e9paration, d\u00e9pannage de mat\u00e9riel a\u00e9raulique, thermique, frigorifique et connexes"
  },
  {
    "code": "1413",
    "description": "Accord national professionnel relatif aux salari\u00e9s permanents des entreprises de travail temporaire"
  },
  {
    "code": "1420",
    "description": "Convention collective nationale de travail du personnel des organismes cr\u00e9\u00e9s pour l'application de la convention du 31 d\u00e9cembre 1958 UNEDIC ASSEDIC"
  },
  {
    "code": "1424",
    "description": "Convention collective nationale des r\u00e9seaux de transports publics urbains de voyageurs"
  },
  {
    "code": "1431",
    "description": "Convention collective nationale de l'optique-lunetterie de d\u00e9tail"
  },
  {
    "code": "1468",
    "description": "Convention collective nationale de branche du Cr\u00e9dit mutuel"
  },
  {
    "code": "1472",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques du Pas-de-Calais"
  },
  {
    "code": "1480",
    "description": "Convention collective nationale de travail des journalistes"
  },
  {
    "code": "1483",
    "description": "Convention collective nationale du commerce de d\u00e9tail de l'habillement et des articles textiles"
  },
  {
    "code": "1486",
    "description": "Convention collective nationale applicable au personnel des bureaux d'\u00e9tudes techniques, des cabinets d'ing\u00e9nieurs-conseils et des soci\u00e9t\u00e9s de conseils(BET, SYNTEC)"
  },
  {
    "code": "1487",
    "description": "Convention collective nationale du commerce de d\u00e9tail de l'horlogerie-bijouterie"
  },
  {
    "code": "1492",
    "description": "Convention collective nationale des ouvriers, employ\u00e9s, dessinateurs, techniciens et agents de ma\u00eetrise de la production des papiers, cartons et celluloses"
  },
  {
    "code": "1495",
    "description": "Convention collective nationale pour les ouvriers, employ\u00e9s, dessinateurs, techniciens et agents de ma\u00eetrise de la transformation des papiers, cartons et celluloses"
  },
  {
    "code": "1499",
    "description": "Convention collective nationale de la miroiterie, de la transformation et du n\u00e9goce du verre"
  },
  {
    "code": "1501",
    "description": "Convention collective nationale de la restauration rapide (restauration livr\u00e9e)"
  },
  {
    "code": "1504",
    "description": "Convention collective nationale de la poissonnerie (commerce de d\u00e9tail, de demi-gros et de gros de la poissonnerie)"
  },
  {
    "code": "1505",
    "description": "Convention collective nationale du commerce de d\u00e9tail de fruits et l\u00e9gumes, \u00e9picerie et produits laitiers"
  },
  {
    "code": "1512",
    "description": "Convention collective nationale de la promotion immobili\u00e8re"
  },
  {
    "code": "1513",
    "description": "Convention collective nationale des activit\u00e9s de production des eaux embouteill\u00e9es, des boissons rafra\u00eechissantes sans alcool et de bi\u00e8re"
  },
  {
    "code": "1516",
    "description": "Convention collective nationale des organismes de formation"
  },
  {
    "code": "1517",
    "description": "Convention collective nationale des commerces de d\u00e9tail non alimentaires : antiquit\u00e9s, brocante, galeries d'art, arts de la table, coutellerie, droguerie, \u00e9quipement du foyer, bazars, commerces m\u00e9nagers, mod\u00e9lisme, jeux, jouets, p\u00e9rinatalit\u00e9 et maroquinerie(\u0153uvres d'art)"
  },
  {
    "code": "1518",
    "description": "Convention collective nationale des m\u00e9tiers de l\u2019\u00c9ducation, de la Culture, des Loisirs, et de l\u2019Animation agissant pour l\u2019utilit\u00e9 sociale et environnementale, au service des Territoires dite ECLAT (ex Animation)"
  },
  {
    "code": "1525",
    "description": "Convention collective locale de la m\u00e9tallurgie de la r\u00e9gion dunkerquoise"
  },
  {
    "code": "1527",
    "description": "Convention collective nationale de l'immobilier"
  },
  {
    "code": "1534",
    "description": "Convention collective nationale des entreprises de l'industrie et des commerces en gros des viandes"
  },
  {
    "code": "1536",
    "description": "Convention collective nationale des distributeurs conseils hors domicile (entrepositaires-grossistes, bi\u00e8res, eaux min\u00e9rales et de table, boissons gazeuses, non gazeuses, sirops, jus de fruits, CHD)"
  },
  {
    "code": "1539",
    "description": "Convention collective nationale des commerces de d\u00e9tail de papeterie, fournitures de bureau, de bureautique et informatique [et de librairie]"
  },
  {
    "code": "1543",
    "description": "Convention collective nationale de la boyauderie (annex\u00e9e \u00e0 la convention collective nationale de l'industrie de la salaison, charcuterie en gros et conserves de viandes 1586)"
  },
  {
    "code": "1555",
    "description": "Convention collective nationale de la fabrication et du commerce des produits \u00e0 usage pharmaceutique, parapharmaceutique et v\u00e9t\u00e9rinaire"
  },
  {
    "code": "1557",
    "description": "Convention collective nationale du commerce des articles de sports et d'\u00e9quipements de loisirs (fusion entre la 1557 et la 1618)"
  },
  {
    "code": "1558",
    "description": "Convention collective nationale relative aux conditions de travail du personnel des industries c\u00e9ramiques de France"
  },
  {
    "code": "1560",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, \u00e9lectriques et connexes des Alpes-Maritimes"
  },
  {
    "code": "1561",
    "description": "Convention collective nationale de la cordonnerie multiservice (annex\u00e9e \u00e0 la convention collective nationale de travail des industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir 2528)"
  },
  {
    "code": "1563",
    "description": "Convention collective nationale des cadres de la presse hebdomadaire r\u00e9gionale d'information"
  },
  {
    "code": "1564",
    "description": "Convention collective d\u00e9partementale des industries de la m\u00e9tallurgie de Sa\u00f4ne-et-Loire"
  },
  {
    "code": "1565",
    "description": "Convention collective d\u00e9partementale des services de soins infirmiers \u00e0 domicile pour personnes \u00e2g\u00e9es de la Guadeloupe"
  },
  {
    "code": "1572",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie de la Charente"
  },
  {
    "code": "1576",
    "description": "Convention collective d\u00e9partementale de travail des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, \u00e9lectroniques, connexes et similaires du d\u00e9partement du Cher"
  },
  {
    "code": "1577",
    "description": "Convention collective r\u00e9gionale des industries m\u00e9tallurgiques, \u00e9lectroniques et connexes de l'H\u00e9rault, de l'Aude et des Pyr\u00e9n\u00e9es-Orientales"
  },
  {
    "code": "1578",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie de la Loire et de l'arrondissement d'Yssingeaux"
  },
  {
    "code": "1580",
    "description": "Convention collective nationale de l'industrie de la chaussure et des articles chaussants"
  },
  {
    "code": "1586",
    "description": "Convention collective nationale de l'industrie de la salaison, charcuterie en gros et conserves de viandes"
  },
  {
    "code": "1588",
    "description": "Convention collective nationale du personnel des soci\u00e9t\u00e9s coop\u00e9ratives d'HLM (annex\u00e9e \u00e0 la convention collective nationale du personnel des Offices Publics de l'Habitat 3220)"
  },
  {
    "code": "1589",
    "description": "Convention collective nationale des mareyeurs-exp\u00e9diteurs"
  },
  {
    "code": "1592",
    "description": "Convention collective locale de la m\u00e9tallurgie du Valenciennois et du Cambr\u00e9sis"
  },
  {
    "code": "1596",
    "description": "Convention collective nationale concernant les ouvriers employ\u00e9s par les entreprises du b\u00e2timent vis\u00e9es par le d\u00e9cret du 1er mars 1962 -c'est-\u00e0-dire occupant jusqu'\u00e0 10 salari\u00e9s-"
  },
  {
    "code": "1597",
    "description": "Convention collective nationale concernant les ouvriers employ\u00e9s par les entreprises du b\u00e2timent non vis\u00e9es par le d\u00e9cret 1er mars 1962 -c'est-\u00e0-dire occupant plus de 10 salari\u00e9s-"
  },
  {
    "code": "1604",
    "description": "Convention collective locale des industries m\u00e9tallurgiques des arrondissements de Rouen et de Dieppe"
  },
  {
    "code": "1605",
    "description": "Convention collective nationale des entreprises de d\u00e9sinfection, d\u00e9sinsectisation, d\u00e9ratisation"
  },
  {
    "code": "1606",
    "description": "Convention collective nationale du bricolage"
  },
  {
    "code": "1607",
    "description": "Convention collective nationale des industries des jeux, jouets, articles de f\u00eates et ornements de No\u00ebl, articles de pu\u00e9riculture et voitures d'enfants mod\u00e9lisme et industries connexes"
  },
  {
    "code": "1611",
    "description": "Convention collective nationale des entreprises de logistique de communication \u00e9crite directe"
  },
  {
    "code": "1612",
    "description": "Convention collective nationale du personnel navigant des essais et r\u00e9ceptions"
  },
  {
    "code": "1618",
    "description": "Convention collective nationale du camping (fusion entre la 1557 et la 1618)"
  },
  {
    "code": "1619",
    "description": "Convention collective nationale des cabinets dentaires"
  },
  {
    "code": "1621",
    "description": "Convention collective nationale de la r\u00e9partition pharmaceutique"
  },
  {
    "code": "1624",
    "description": "Convention collective nationale des commerces de gros de la confiserie, chocolaterie, biscuiterie et alimentation fine et des n\u00e9gociants distributeurs de levure"
  },
  {
    "code": "1626",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, \u00e9lectro-c\u00e9ramiques et connexes des Hautes-Pyr\u00e9n\u00e9es"
  },
  {
    "code": "1627",
    "description": "Convention collective d\u00e9partementale du travail des industries de la m\u00e9tallurgie et des constructions m\u00e9caniques de Clermont-Ferrand et du Puy-de-D\u00f4me"
  },
  {
    "code": "1628",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie des Deux-S\u00e8vres"
  },
  {
    "code": "1631",
    "description": "Convention collective nationale de l'h\u00f4tellerie de plein air"
  },
  {
    "code": "1634",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie des C\u00f4tes-d'Armor"
  },
  {
    "code": "1635",
    "description": "Convention collective r\u00e9gionale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes de la Gironde et des Landes"
  },
  {
    "code": "1659",
    "description": "Convention collective nationale du rouissage teillage du lin"
  },
  {
    "code": "1671",
    "description": "Convention collective nationale des maisons d'\u00e9tudiants"
  },
  {
    "code": "1672",
    "description": "Convention collective nationale des soci\u00e9t\u00e9s d'assurances"
  },
  {
    "code": "1679",
    "description": "Convention collective nationale de l'inspection d'assurance"
  },
  {
    "code": "1686",
    "description": "Convention collective nationale des commerces et services de l'audiovisuel, de l'\u00e9lectronique et de l'\u00e9quipement m\u00e9nager"
  },
  {
    "code": "1700",
    "description": "Convention collective d\u00e9partementale des sucreries, sucreries-distilleries et distilleries de la Guadeloupe"
  },
  {
    "code": "1702",
    "description": "Convention collective nationale des ouvriers de travaux publics"
  },
  {
    "code": "1710",
    "description": "Convention collective nationale du personnel des agences de voyages et de tourisme"
  },
  {
    "code": "1732",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques et connexes de l'Yonne"
  },
  {
    "code": "1734",
    "description": "Convention collective des artistes-interpr\u00e8tes engag\u00e9s pour des \u00e9missions de t\u00e9l\u00e9vision (annex\u00e9e \u00e0 la convention collective de la production audiovisuelle 2642)"
  },
  {
    "code": "1747",
    "description": "Convention collective nationale des activit\u00e9s industrielles de boulangerie et de p\u00e2tisserie (fusion entre la 1747 et la 2075)"
  },
  {
    "code": "1760",
    "description": "Convention collective nationale des jardineries et graineteries"
  },
  {
    "code": "1790",
    "description": "Convention collective nationale des espaces de loisirs, d'attractions et culturels"
  },
  {
    "code": "1794",
    "description": "Convention collective nationale du personnel des institutions de retraites compl\u00e9mentaires"
  },
  {
    "code": "1800",
    "description": "Convention collective nationale du personnel de la c\u00e9ramique d'art (annex\u00e9e \u00e0 la convention collective nationale relative aux conditions de travail du personnel des industries c\u00e9ramiques de France 1558)"
  },
  {
    "code": "1801",
    "description": "Convention collective nationale des soci\u00e9t\u00e9s d'assistance"
  },
  {
    "code": "1813",
    "description": "Convention collective locale de travail des industries de la transformation des m\u00e9taux de la r\u00e9gion de Maubeuge"
  },
  {
    "code": "1821",
    "description": "Convention collective nationale des professions regroup\u00e9es du cristal, du verre et du vitrail "
  },
  {
    "code": "1843",
    "description": "Convention collective r\u00e9gionale des ing\u00e9nieurs, assimil\u00e9s et cadres du b\u00e2timent de la r\u00e9gion parisienne"
  },
  {
    "code": "1850",
    "description": "Convention collective nationale de l'avocat salari\u00e9"
  },
  {
    "code": "1867",
    "description": "Convention collective r\u00e9gionale de la m\u00e9tallurgie de la Dr\u00f4me-Ard\u00e8che"
  },
  {
    "code": "1875",
    "description": "Convention collective nationale des cabinets et cliniques v\u00e9t\u00e9rinaires : personnel salari\u00e9"
  },
  {
    "code": "1880",
    "description": "Convention collective nationale du n\u00e9goce de l'ameublement"
  },
  {
    "code": "1885",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques et connexes du d\u00e9partement de la C\u00f4te-d'Or"
  },
  {
    "code": "1895",
    "description": "Convention collective nationale de l'encadrement de la presse quotidienne r\u00e9gionale"
  },
  {
    "code": "1902",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, \u00e9lectroniques connexes et similaires du Maine-et-Loire"
  },
  {
    "code": "1909",
    "description": "Convention collective nationale des organismes de tourisme"
  },
  {
    "code": "1912",
    "description": "Convention collective d\u00e9partementale des industries de la m\u00e9tallurgie du Haut-Rhin"
  },
  {
    "code": "1921",
    "description": "Convention collective nationale des huissiers de justice"
  },
  {
    "code": "1922",
    "description": "Convention collective nationale de la radiodiffusion"
  },
  {
    "code": "1923",
    "description": "Convention collective d\u00e9partementale de la manutention portuaire de la Guadeloupe"
  },
  {
    "code": "1930",
    "description": "Convention collective nationale des m\u00e9tiers de la transformation des grains (ex meunerie)"
  },
  {
    "code": "1938",
    "description": "Convention collective nationale des industries de la transformation des volailles (abattoirs, ateliers de d\u00e9coupe et centres de conditionnement de volailles, commerce de gros de volailles)"
  },
  {
    "code": "1942",
    "description": "Convention collective nationale de l'industrie de production des textiles artificiels et synth\u00e9tiques et produits assimil\u00e9s (annex\u00e9e \u00e0 la convention collective nationale de l\u2019industrie textile 0018)"
  },
  {
    "code": "1944",
    "description": "Convention collective nationale du personnel navigant technique des exploitants d'h\u00e9licopt\u00e8res"
  },
  {
    "code": "1947",
    "description": "Convention collective nationale du n\u00e9goce de bois d'\u0153uvre et produits d\u00e9riv\u00e9s"
  },
  {
    "code": "1951",
    "description": "Convention collective nationale des cabinets ou entreprises d'expertises en automobile"
  },
  {
    "code": "1960",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes du Lot-et-Garonne"
  },
  {
    "code": "1961",
    "description": "Convention collective d\u00e9partementale pour les stations-service en Guadeloupe"
  },
  {
    "code": "1966",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques du Loiret"
  },
  {
    "code": "1967",
    "description": "Convention collective d\u00e9partementale de l'industrie des m\u00e9taux du Bas-Rhin"
  },
  {
    "code": "1970",
    "description": "Convention collective r\u00e9gionale des commerces de d\u00e9tail non alimentaires des d\u00e9partements du Bas-Rhin et du Haut-Rhin"
  },
  {
    "code": "1974",
    "description": "Convention collective nationale du personnel des entreprises de transport de passagers en navigation int\u00e9rieure"
  },
  {
    "code": "1978",
    "description": "Convention collective nationale des fleuristes, de la vente et des services des animaux familiers"
  },
  {
    "code": "1979",
    "description": "Convention collective nationale des h\u00f4tels, caf\u00e9s, restaurants (HCR)"
  },
  {
    "code": "1980",
    "description": "Convention collective d\u00e9partementale des commissionnaires en douane et agents auxiliaires de la Martinique"
  },
  {
    "code": "1982",
    "description": "Convention collective nationale du n\u00e9goce et prestations de services dans les domaines m\u00e9dico-techniques"
  },
  {
    "code": "1987",
    "description": "Convention collective nationale des p\u00e2tes alimentaires s\u00e8ches et du couscous non pr\u00e9par\u00e9"
  },
  {
    "code": "1996",
    "description": "Convention collective nationale de la pharmacie d'officine"
  },
  {
    "code": "2002",
    "description": "Convention collective interr\u00e9gionale de la blanchisserie, laverie, location de linge, nettoyage \u00e0 sec, pressing et teinturerie"
  },
  {
    "code": "2003",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, \u00e9lectriques, \u00e9lectroniques et connexes du d\u00e9partement des Vosges"
  },
  {
    "code": "2021",
    "description": "Convention collective nationale du golf"
  },
  {
    "code": "2025",
    "description": "Convention collective r\u00e9gionale du travail des activit\u00e9s mini\u00e8res de Guyane"
  },
  {
    "code": "2046",
    "description": "Convention collective nationale du personnel non m\u00e9dical des centres de lutte contre le cancer"
  },
  {
    "code": "2060",
    "description": "Convention collective nationale des cha\u00eenes de caf\u00e9t\u00e9rias et assimil\u00e9s"
  },
  {
    "code": "2075",
    "description": "Convention collective nationale des centres immatricul\u00e9s de conditionnement, de commercialisation et de transformation des \u0153ufs et des industries en produits d'\u0153ufs (fusion entre la 1747 et la 2075)"
  },
  {
    "code": "2089",
    "description": "Convention collective nationale de l'industrie des panneaux \u00e0 base de bois"
  },
  {
    "code": "2098",
    "description": "Convention collective nationale du personnel des prestataires de services dans le domaine du secteur tertiaire"
  },
  {
    "code": "2104",
    "description": "Convention collective nationale du thermalisme (fusion entre la 2264 et la 2104)"
  },
  {
    "code": "2111",
    "description": "Convention collective nationale des salari\u00e9s du particulier employeur"
  },
  {
    "code": "2120",
    "description": "Convention collective nationale de la banque"
  },
  {
    "code": "2121",
    "description": "Convention collective nationale de l'\u00e9dition"
  },
  {
    "code": "2126",
    "description": "Convention collective r\u00e9gionale de la m\u00e9tallurgie du Gard et de la Loz\u00e8re"
  },
  {
    "code": "2128",
    "description": "Convention collective nationale de la mutualit\u00e9"
  },
  {
    "code": "2147",
    "description": "Convention collective nationale des entreprises des services d'eau et d'assainissement (entreprises en g\u00e9rance, en concession ou en affermage assurent l'exploitation, le service, le pompage, le traitement et la distribution d'eau \u00e0 usage public, particulier, domestique, agricole)"
  },
  {
    "code": "2148",
    "description": "Convention collective nationale des t\u00e9l\u00e9communications"
  },
  {
    "code": "2149",
    "description": "Convention collective nationale des activit\u00e9s du d\u00e9chet"
  },
  {
    "code": "2150",
    "description": "Convention collective nationale des personnels des soci\u00e9t\u00e9s anonymes et fondations d'HLM"
  },
  {
    "code": "2156",
    "description": "Convention collective nationale des grands magasins et des magasins populaires"
  },
  {
    "code": "2174",
    "description": "Convention collective nationale du personnel s\u00e9dentaire des entreprises de transports de marchandises de la navigation int\u00e9rieure"
  },
  {
    "code": "2190",
    "description": "Convention collective nationale des missions locales et PAIO des maisons de l'emploi et PLIE"
  },
  {
    "code": "2198",
    "description": "Convention collective nationale des entreprises de vente \u00e0 distance"
  },
  {
    "code": "2205",
    "description": "Convention collective nationale du notariat "
  },
  {
    "code": "2216",
    "description": "Convention collective nationale du commerce de d\u00e9tail et de gros \u00e0 pr\u00e9dominance alimentaire (entrep\u00f4ts d'alimentation, sup\u00e9rettes, supermarch\u00e9s, hypermarch\u00e9s, grande distribution)"
  },
  {
    "code": "2219",
    "description": "Convention collective nationale des taxis"
  },
  {
    "code": "2221",
    "description": "Convention collective r\u00e9gionale des mensuels des industries des m\u00e9taux de l'Is\u00e8re et des Hautes-Alpes"
  },
  {
    "code": "2230",
    "description": "Convention collective nationale des associations agr\u00e9\u00e9es de surveillance de la qualit\u00e9 de l'air (annex\u00e9e \u00e0 la convention collective nationale des bureaux d'\u00e9tudes techniques, des cabinets d'ing\u00e9nieurs-conseils et des soci\u00e9t\u00e9s de conseils 1486)"
  },
  {
    "code": "2247",
    "description": "Convention collective nationale des entreprises de courtage d'assurances et/ou de r\u00e9assurances"
  },
  {
    "code": "2250",
    "description": "Convention collective r\u00e9gionale de la boulangerie-p\u00e2tisserie de la Guyane"
  },
  {
    "code": "2257",
    "description": "Convention collective nationale des casinos"
  },
  {
    "code": "2264",
    "description": "Convention collective nationalede l'hospitalisation priv\u00e9e (CCU, FHP, \u00e9tablissements pour personnes \u00e2g\u00e9es, maison de retraite, \u00e9tablissements de suite et r\u00e9adaptation, m\u00e9dicaux pour enfants et adolescents, UHP, sanitaires sociaux et m\u00e9dico-sociaux CRRR, hospitalisation priv\u00e9 \u00e0 but lucratif FIEHP ) (fusion entre la 2264 et la 2104)"
  },
  {
    "code": "2266",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, connexes et similaires de la Mayenne"
  },
  {
    "code": "2272",
    "description": "Convention collective nationale de l'assainissement et de la maintenance industrielle"
  },
  {
    "code": "2294",
    "description": "Convention collective d\u00e9partementale des industries et m\u00e9tiers de la m\u00e9tallurgie de l'Aube"
  },
  {
    "code": "2328",
    "description": "Convention collective d\u00e9partementale des ouvriers du b\u00e2timent et des travaux publics de la Guadeloupe et d\u00e9pendances"
  },
  {
    "code": "2329",
    "description": "Accord professionnel national de travail entre les avocats au Conseil d'Etat et \u00e0 la Cour de Cassation et leur personnel salari\u00e9 -non avocat-"
  },
  {
    "code": "2332",
    "description": "Convention collective nationale des entreprises d'architecture"
  },
  {
    "code": "2335",
    "description": "Convention collective nationale du personnel des agences g\u00e9n\u00e9rales d'assurances"
  },
  {
    "code": "2336",
    "description": "Convention collective nationale de l'habitat et du logement accompagn\u00e9s"
  },
  {
    "code": "2344",
    "description": "Convention collective nationale de la sid\u00e9rurgie"
  },
  {
    "code": "2345",
    "description": "Convention collective r\u00e9gionale du transport sanitaire en Martinique"
  },
  {
    "code": "2357",
    "description": "Accord professionnel national du 3 mars 1993 relatif aux cadres de direction des soci\u00e9t\u00e9s d'assurances"
  },
  {
    "code": "2360",
    "description": "Convention collective r\u00e9gionale des services de l'automobile de la Guyane"
  },
  {
    "code": "2372",
    "description": "Convention collective nationale des entreprises de la distribution directe"
  },
  {
    "code": "2378",
    "description": "Accords professionnels nationaux concernant le personnel int\u00e9rimaire des entreprises de travail temporaire"
  },
  {
    "code": "2389",
    "description": "Convention collective r\u00e9gionale des ouvriers du b\u00e2timent et des travaux publics r\u00e9gion de La R\u00e9union"
  },
  {
    "code": "2395",
    "description": "Convention collective nationale de travail des assistants maternels du particulier employeur"
  },
  {
    "code": "2397",
    "description": "Convention collective nationale des mannequins adultes et des mannequins enfants de moins de seize ans employ\u00e9s par les agences de mannequins (fusion entre la 2717 et la 2397)"
  },
  {
    "code": "2405",
    "description": "Convention collective d\u00e9partementale des \u00e9tablissements d'hospitalisation priv\u00e9e de la Guadeloupe du 01/04/2003"
  },
  {
    "code": "2411",
    "description": "Convention collective nationale des cha\u00eenes th\u00e9matiques"
  },
  {
    "code": "2412",
    "description": "Convention collective nationale de la production de films d'animation"
  },
  {
    "code": "2420",
    "description": "Convention collective nationale des cadres du b\u00e2timent du 1er juin 2004"
  },
  {
    "code": "2480",
    "description": "Convention collective locale de la manutention portuaire du port de Fort-de-France du 4 juillet 2003"
  },
  {
    "code": "2489",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques et assimil\u00e9es de la Vend\u00e9e"
  },
  {
    "code": "2494",
    "description": "Convention collective nationale de la coop\u00e9ration maritime"
  },
  {
    "code": "2511",
    "description": "Convention collective nationale du sport"
  },
  {
    "code": "2526",
    "description": "Convention collective nationale des organisations professionnelles de l'habitat social"
  },
  {
    "code": "2528",
    "description": "Convention collective nationale de travail des industries de la maroquinerie, articles de voyage, chasse sellerie, gainerie, bracelets en cuir"
  },
  {
    "code": "2534",
    "description": "Convention collective d\u00e9partementale de l'industrie sucri\u00e8re et rhumi\u00e8re de la Martinique"
  },
  {
    "code": "2535",
    "description": "Convention collective d\u00e9partementale dans la culture de la canne \u00e0 sucre en Martinique"
  },
  {
    "code": "2542",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques et connexes de l'Aisne"
  },
  {
    "code": "2543",
    "description": "Convention collective nationale des cabinets ou entreprises de g\u00e9om\u00e8tres experts, g\u00e9om\u00e8tres topographes photogramm\u00e8tres, experts-fonciers"
  },
  {
    "code": "2564",
    "description": "Convention collective nationale des v\u00e9t\u00e9rinaires praticiens salari\u00e9s"
  },
  {
    "code": "2579",
    "description": "Conventions collectives d\u00e9partementales applicables aux entreprises des industries et m\u00e9tiers de la m\u00e9tallurgie du Loir-et-Cher"
  },
  {
    "code": "2583",
    "description": "Convention collective nationale de branche des soci\u00e9t\u00e9s concessionnaires ou exploitantes d'autoroutes ou d'ouvrages routiers"
  },
  {
    "code": "2596",
    "description": "Convention collective nationale de la coiffure et des professions connexes"
  },
  {
    "code": "2603",
    "description": "Convention collective nationale de travail des praticiens conseils du r\u00e9gime g\u00e9n\u00e9ral de s\u00e9curit\u00e9 sociale"
  },
  {
    "code": "2609",
    "description": "Convention collective nationale des employ\u00e9s, techniciens et agents de ma\u00eetrise du b\u00e2timent"
  },
  {
    "code": "2614",
    "description": "Convention collective nationale des employ\u00e9s, techniciens et agents de ma\u00eetrise des travaux publics"
  },
  {
    "code": "2615",
    "description": "Convention collective r\u00e9gionale de la m\u00e9tallurgie des Pyr\u00e9n\u00e9es-Atlantiques et du Seignanx"
  },
  {
    "code": "2622",
    "description": "Convention collective nationale du cr\u00e9dit maritime mutuel"
  },
  {
    "code": "2630",
    "description": "Convention collective r\u00e9gionale des industries m\u00e9tallurgiques des Bouches-du-Rh\u00f4ne et Alpes-de-Haute-Provence"
  },
  {
    "code": "2631",
    "description": "Accord collectif national Branche de la t\u00e9l\u00e9diffusion. Salari\u00e9s employ\u00e9s sous contrat \u00e0 dur\u00e9e d\u00e9termin\u00e9e d'usage"
  },
  {
    "code": "2642",
    "description": "Convention collective nationale de la production audiovisuelle"
  },
  {
    "code": "2658",
    "description": "Convention collective r\u00e9gionale du travail des guides d'exp\u00e9dition, guides accompagnateurs et guides animateurs en milieu amazonien"
  },
  {
    "code": "2666",
    "description": "Convention collective nationale des conseils d'architecture, d'urbanisme et de l'environnement"
  },
  {
    "code": "2668",
    "description": "Convention collective nationale de travail des cadres sup\u00e9rieurs des soci\u00e9t\u00e9s de secours mini\u00e8res et de leurs \u00e9tablissements, des unions r\u00e9gionales et des assistants sociaux r\u00e9gionaux"
  },
  {
    "code": "2683",
    "description": "Convention collective nationale du portage de presse"
  },
  {
    "code": "2691",
    "description": "Convention collective nationale de l'enseignement priv\u00e9 ind\u00e9pendant (hors contrat)"
  },
  {
    "code": "2697",
    "description": "Convention collective nationale des personnels des structures associatives cyn\u00e9g\u00e9tiques"
  },
  {
    "code": "2700",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie de l'Oise"
  },
  {
    "code": "2701",
    "description": "Convention collective d\u00e9partementale du travail du personnel des banques de la Guyane"
  },
  {
    "code": "2702",
    "description": "Convention collective d\u00e9partementale du travail du personnel des banques de la Martinique"
  },
  {
    "code": "2704",
    "description": "Convention collective d\u00e9partementale du travail du personnel des banques de la Guadeloupe, de Saint-Martin et de Saint-Barth\u00e9l\u00e9my"
  },
  {
    "code": "2706",
    "description": "Convention collective nationale du personnel des administrateurs et des mandataires judiciaires"
  },
  {
    "code": "2707",
    "description": "Convention collective r\u00e9gionale des employ\u00e9s, techniciens et agents de ma\u00eetrise du b\u00e2timent \u00cele-de-France"
  },
  {
    "code": "2717",
    "description": "Convention collective nationale des entreprises techniques au service de la cr\u00e9ation et de l'\u00e9v\u00e9nement (fusion entre la 2717 et la 2397)"
  },
  {
    "code": "2727",
    "description": "Convention collective nationale des omnipraticiens exer\u00e7ant dans les centres de sant\u00e9 miniers"
  },
  {
    "code": "2728",
    "description": "Convention collective nationale des sucreries, sucreries-distilleries et raffineries de sucre"
  },
  {
    "code": "2754",
    "description": "Convention collective nationale des magasins prestataires de services de cuisine \u00e0 usage domestique"
  },
  {
    "code": "2755",
    "description": "Convention collective d\u00e9partementale des industries de la m\u00e9tallurgie de Belfort/Montb\u00e9liard"
  },
  {
    "code": "2768",
    "description": "Convention collective nationale de travail des pharmaciens du r\u00e9gime minier"
  },
  {
    "code": "2770",
    "description": "Convention collective nationale de l'\u00e9dition phonographique (annex\u00e9e \u00e0 la convention collective nationale de l'\u00e9dition 2121)"
  },
  {
    "code": "2785",
    "description": "Convention collective nationale des soci\u00e9t\u00e9s de ventes volontaires de meubles aux ench\u00e8res publiques et des offices de commissaires-priseurs judiciaires"
  },
  {
    "code": "2796",
    "description": "Convention collective nationale du personnel de direction du r\u00e9gime social des ind\u00e9pendants, ses annexes et son accord d'application"
  },
  {
    "code": "2797",
    "description": "Convention collective nationale sp\u00e9ciale de travail des praticiens conseils du r\u00e9gime social des ind\u00e9pendants et son annexe 1 relative \u00e0 l'ARTT"
  },
  {
    "code": "2798",
    "description": "Convention collective nationale des employ\u00e9s et cadres du r\u00e9gime social des ind\u00e9pendants, ses annexes 1 \u00e0 6 et son accord d'application"
  },
  {
    "code": "2847",
    "description": "Convention collective nationale de P\u00f4le Emploi"
  },
  {
    "code": "2870",
    "description": "Convention collective r\u00e9gionale des ouvriers du b\u00e2timent, des travaux publics et des industries et activit\u00e9s connexes de la Guyane"
  },
  {
    "code": "2931",
    "description": "Convention collective nationale des activit\u00e9s de march\u00e9s financiers"
  },
  {
    "code": "2941",
    "description": "Convention collective nationale de la branche de l'aide, de l'accompagnement, des soins et des services \u00e0 domicile"
  },
  {
    "code": "2964",
    "description": "Accord collectif d\u00e9partemental relatif au transport de proximit\u00e9 public et priv\u00e9 de produits p\u00e9troliers et de liquides inflammables sur le territoire de la Martinique"
  },
  {
    "code": "2972",
    "description": "Convention collective nationale du personnel s\u00e9dentaire des entreprises de navigation"
  },
  {
    "code": "2978",
    "description": "Convention collective nationale du personnel salari\u00e9 des agences de recherches priv\u00e9es"
  },
  {
    "code": "2980",
    "description": "Convention collective d\u00e9partementale de la m\u00e9tallurgie de la Somme"
  },
  {
    "code": "2992",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, \u00e9lectriques, connexes et similaires d'Indre-et Loire"
  },
  {
    "code": "3013",
    "description": "Convention collective nationale de la librairie"
  },
  {
    "code": "3016",
    "description": "Convention collective nationale des ateliers chantiers d'insertion"
  },
  {
    "code": "3017",
    "description": "Convention collective nationale unifi\u00e9e ports et manutention"
  },
  {
    "code": "3028",
    "description": "Convention collective r\u00e9gionale des transports routiers et activit\u00e9s auxiliaires du transport de la Guadeloupe"
  },
  {
    "code": "3032",
    "description": "Convention collective nationale de l'esth\u00e9tique - cosm\u00e9tique et de l'enseignement technique et professionnel li\u00e9s aux m\u00e9tiers de l'esth\u00e9tique et de la parfumerie"
  },
  {
    "code": "3035",
    "description": "Convention collective de la parfumerie s\u00e9lective\u00a0"
  },
  {
    "code": "3043",
    "description": "Convention collective nationale des entreprises de propret\u00e9 et services associ\u00e9s du 26 juillet 2011."
  },
  {
    "code": "3053",
    "description": "Convention collective d\u00e9partementale des industries de la m\u00e9tallurgie de Haute-Sa\u00f4ne"
  },
  {
    "code": "3090",
    "description": "Convention collective nationale des entreprises du secteur priv\u00e9 du spectacle vivant"
  },
  {
    "code": "3097",
    "description": "Convention collective nationale de la production cin\u00e9matographique"
  },
  {
    "code": "3105",
    "description": "Convention collective nationale des r\u00e9gies de quartier"
  },
  {
    "code": "3107",
    "description": "Convention collective r\u00e9gionale des employ\u00e9s, techniciens et agents de ma\u00eetrise du b\u00e2timent et des travaux publics et annexes de la Martinique"
  },
  {
    "code": "3109",
    "description": "Convention collective nationale des 5 branches industries alimentaires diverses"
  },
  {
    "code": "3123",
    "description": "Convention collective r\u00e9gionale des ambulances Guyane"
  },
  {
    "code": "3127",
    "description": "Convention collective nationale des services \u00e0 la personne du 20 septembre 2012"
  },
  {
    "code": "3128",
    "description": "Convention collective r\u00e9gionale des employ\u00e9s, techniciens et agents de ma\u00eetrise du b\u00e2timent, des travaux publics et des industries et activit\u00e9s connexes de la Guyane"
  },
  {
    "code": "3144",
    "description": "Convention collective r\u00e9gionale des ETAM du b\u00e2timent et des travaux publics de la Guadeloupe"
  },
  {
    "code": "3160",
    "description": "Convention collective nationale des associations de gestion et de comptabilit\u00e9 (annex\u00e9e \u00e0 la convention collective nationale du personnel des cabinets d'experts-comptables et de commissaires aux comptes 0787)"
  },
  {
    "code": "3168",
    "description": "Convention collective nationale des professions de la photographie"
  },
  {
    "code": "3203",
    "description": "Convention collective nationale des structures associatives de p\u00eache de loisir et de protection du milieu aquatique"
  },
  {
    "code": "3204",
    "description": "Convention collective r\u00e9gionale des ing\u00e9nieurs et cadres du b\u00e2timent, des travaux publics et des industries et activit\u00e9s connexes de la Guyane"
  },
  {
    "code": "3205",
    "description": "Convention collective nationale du personnel des coop\u00e9ratives de consommation"
  },
  {
    "code": "3206",
    "description": "Convention collective d\u00e9partementale du personnel des cabinets m\u00e9dicaux de Martinique"
  },
  {
    "code": "3207",
    "description": "Convention collective r\u00e9gionale des transports sanitaires de Guadeloupe"
  },
  {
    "code": "3209",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9caniques, microtechniques et connexes du d\u00e9partement du Doubs "
  },
  {
    "code": "3210",
    "description": "Convention collective nationale de la banque populaire"
  },
  {
    "code": "3212",
    "description": "Convention collective nationale des cadres des travaux publics"
  },
  {
    "code": "3213",
    "description": "Convention collective nationale des collaborateurs salari\u00e9s des entreprises d'\u00e9conomistes de la construction et des m\u00e9treurs-v\u00e9rificateurs"
  },
  {
    "code": "3216",
    "description": "Convention collective nationale des salari\u00e9s du n\u00e9goce des mat\u00e9riaux de construction"
  },
  {
    "code": "3217",
    "description": "Convention collective nationale ferroviaire "
  },
  {
    "code": "3218",
    "description": "Convention collective nationale de l'enseignement priv\u00e9 non lucratif (EPNL)"
  },
  {
    "code": "3219",
    "description": "Convention collective nationale de branche des salari\u00e9s en portage salarial"
  },
  {
    "code": "3220",
    "description": "Convention collective nationale des offices publics de l'habitat"
  },
  {
    "code": "3221",
    "description": "Convention collective nationale des agences de presse "
  },
  {
    "code": "3222",
    "description": "Convention collective nationale des menuiseries charpentes et constructions industrialis\u00e9es et des portes planes"
  },
  {
    "code": "3223",
    "description": "Convention collective nationale des officiers des entreprises de transport et services maritimes"
  },
  {
    "code": "3224",
    "description": "Convention collective nationale de la distribution des papiers-cartons ; commerce de gros"
  },
  {
    "code": "3225",
    "description": "Convention collective nationale des employ\u00e9s et des cadres des \u00e9diteurs de la presse magazine"
  },
  {
    "code": "3227",
    "description": "Convention collective nationale des industries de la fabrication de la chaux"
  },
  {
    "code": "3228",
    "description": "Convention collective nationale du groupement des armateurs de service de passages d'eau - personnel navigant"
  },
  {
    "code": "3230",
    "description": "Convention collective nationale de la presse d'information sp\u00e9cialis\u00e9e "
  },
  {
    "code": "3231",
    "description": "Convention collective d\u00e9partementale des industries m\u00e9tallurgiques, m\u00e9caniques, similaires et connexes du Jura"
  },
  {
    "code": "3232",
    "description": "Convention collective nationale de travail des agents de direction des organismes du r\u00e9gime g\u00e9n\u00e9ral de s\u00e9curit\u00e9 sociale"
  },
  {
    "code": "3233",
    "description": "Convention collective nationale de la branche de l'industrie de la fabrication des ciments"
  },
  {
    "code": "3236",
    "description": "Convention collective nationale de l\u2019industrie et des services nautiques\u00a0"
  },
  {
    "code": "3235",
    "description": "Convention collective de la parfumerie s\u00e9lective\u00a0"
  },
  {
    "code": "5001",
    "description": "Statut des industries \u00e9lectriques et gazi\u00e8res"
  },
  {
    "code": "5002",
    "description": "Statut du Mineur"
  },
  {
    "code": "5003",
    "description": "Statut de la Fonction publique d'\u00c9tat"
  },
  {
    "code": "5005",
    "description": "Statut des Caisses d'\u00e9pargne"
  },
  {
    "code": "5008",
    "description": "Statut de la Banque de France"
  },
  {
    "code": "5010",
    "description": "Statut des Chambres des m\u00e9tiers & de l'artisanat"
  },
  {
    "code": "5011",
    "description": "Statut de l'A\u00e9roport de Paris"
  },
  {
    "code": "5012",
    "description": "Statut des chemins de fer"
  },
  {
    "code": "5014",
    "description": "Statut de la RATP"
  },
  {
    "code": "5015",
    "description": "Statut du CNRS"
  },
  {
    "code": "5017",
    "description": "Statut de l'\u00c9glise ou convention dioc\u00e9saine"
  },
  {
    "code": "5018",
    "description": "Statut des Chambres de commerce et d'industrie "
  },
  {
    "code": "5019",
    "description": "Statut des Chambres d'agriculture"
  },
  {
    "code": "5021",
    "description": "Statut de la Fonction publique territoriale"
  },
  {
    "code": "5022",
    "description": "Statut de la Fonction publique hospitali\u00e8re"
  },
  {
    "code": "5023",
    "description": "Statut Vivea"
  },
  {
    "code": "5024",
    "description": "Statut des chefs d'\u00e9tablissement de l'enseignement catholique"
  },
  {
    "code": "5025",
    "description": "Statut des personnels des organismes de d\u00e9veloppement \u00e9conomique"
  },
  {
    "code": "5100",
    "description": "Statut divers ou non pr\u00e9cis\u00e9"
  },
  {
    "code": "5200",
    "description": "Grille d'usage Mars PF (Non conventionnelle)"
  },
  {
    "code": "5201",
    "description": "Recommandations ANIL ADIL (Non conventionnelles)"
  },
  {
    "code": "5203",
    "description": "Ancienne convention collective nationale des peintres en lettres et publicit\u00e9 peinte (d\u00e9nonc\u00e9e, mais pouvant servir de grille d'usage d'\u00e9tablissements r\u00e9put\u00e9s sans convention)"
  },
  {
    "code": "5204",
    "description": "Grille d'usage MSF logistique (Non conventionnelle)"
  },
  {
    "code": "5205",
    "description": "Convention d'entreprise CSE Air France exploitation hub"
  },
  {
    "code": "5501",
    "description": "Convention d'entreprise ind\u00e9pendante ou texte assimil\u00e9 non pr\u00e9cis\u00e9"
  },
  {
    "code": "5502",
    "description": "Convention d'entreprise Croix Rouge"
  },
  {
    "code": "5503",
    "description": "Convention d'entreprise SEITA (LOGISTA France)"
  },
  {
    "code": "5505",
    "description": "Convention d'entreprise CEA"
  },
  {
    "code": "5506",
    "description": "Convention d'entreprise Cr\u00e9dit agricole SA"
  },
  {
    "code": "5507",
    "description": "Convention collective nationale des administratifs du football (n'est pas consid\u00e9r\u00e9e \u00e0 l'heure actuelle comme une convention de branche)"
  },
  {
    "code": "5509",
    "description": "Convention d'entreprise PMU"
  },
  {
    "code": "5513",
    "description": "Convention d'entreprise IFREMER"
  },
  {
    "code": "5514",
    "description": "Convention d'entreprise Cr\u00e9dit Foncier"
  },
  {
    "code": "5516",
    "description": "Convention d'entreprise La Poste - France T\u00e9l\u00e9com"
  },
  {
    "code": "5519",
    "description": "Convention collective nationale des salari\u00e9s administratifs des soci\u00e9t\u00e9s de secours mini\u00e8res  (n'est pas consid\u00e9r\u00e9e \u00e0 l'heure actuelle comme une convention de branche)"
  },
  {
    "code": "5521",
    "description": "Convention collective nationale du personnel navigant d'ex\u00e9cution du transport maritime"
  },
  {
    "code": "5522",
    "description": "Convention d'entreprise \u00c9tablissement fran\u00e7ais du sang"
  },
  {
    "code": "5524",
    "description": "Convention d'entreprise France terre d'asile"
  },
  {
    "code": "5526",
    "description": "Charte du football professionnel (n'est pas consid\u00e9r\u00e9e \u00e0 l'heure actuelle comme une convention de branche)"
  },
  {
    "code": "5530",
    "description": "Convention d'entreprise Adoma (ex Sonacotra)"
  },
  {
    "code": "5531",
    "description": "Convention d'entreprise Syngenta "
  },
  {
    "code": "5532",
    "description": "Convention d'entreprise INRS"
  },
  {
    "code": "5533",
    "description": "Convention du comit\u00e9 d'entreprise SNCF"
  },
  {
    "code": "5537",
    "description": "Convention collective nationale des dentistes des soci\u00e9t\u00e9s de secours mini\u00e8res (n'est pas consid\u00e9r\u00e9e \u00e0 l'heure actuelle comme une convention de branche)"
  },
  {
    "code": "5538",
    "description": "Convention collective nationale des m\u00e9decins sp\u00e9cialiste des soci\u00e9t\u00e9s de secours mini\u00e8res (n'est pas consid\u00e9r\u00e9e \u00e0 l'heure actuelle comme une convention de branche)"
  },
  {
    "code": "5539",
    "description": "Convention d'entreprise AFPA"
  },
  {
    "code": "5541",
    "description": "Convention collective nationale du rugby professionnel (n'est pas consid\u00e9r\u00e9e \u00e0 l'heure actuelle comme une convention de branche)"
  },
  {
    "code": "5542",
    "description": "Convention collective nationale du  basket ball professionnel (n'est pas consid\u00e9r\u00e9e \u00e0 l'heure actuelle comme une convention de branche)"
  },
  {
    "code": "5545",
    "description": "Convention d'entreprise des restaurants PTT"
  },
  {
    "code": "5546",
    "description": "Convention d'entreprise CNES"
  },
  {
    "code": "5547",
    "description": "Convention d'entreprise Club M\u00e9diterran\u00e9e"
  },
  {
    "code": "5548",
    "description": "Convention du comit\u00e9 d'entreprise RATP"
  },
  {
    "code": "5549",
    "description": "Convention d'entreprise IRSN"
  },
  {
    "code": "5551",
    "description": "Convention d'entreprise Institut Pasteur"
  },
  {
    "code": "5552",
    "description": "Convention d'entreprise Soci\u00e9t\u00e9 d'agences et de diffusion"
  },
  {
    "code": "5553",
    "description": "Convention d'entreprise CCAS"
  },
  {
    "code": "5554",
    "description": "Convention collective nationale des officiers du Remorquage maritime "
  },
  {
    "code": "5555",
    "description": "Convention collective nationale des navigants d'ex\u00e9cution du Remorquage maritime "
  },
  {
    "code": "5558",
    "description": "Accords-convention d'entreprise bas\u00e9s sur l'Accord national Multiservice immobilier et facilties management"
  },
  {
    "code": "5559",
    "description": "Convention d'entreprise Alliance emploi"
  },
  {
    "code": "5562",
    "description": "Convention d'entreprise Talc de Luzenac"
  },
  {
    "code": "5563",
    "description": "Convention d'entreprise R\u00e9union des mus\u00e9es nationaux"
  },
  {
    "code": "5564",
    "description": "Convention d'entreprise APEC"
  },
  {
    "code": "5565",
    "description": "Convention du groupe TF1"
  },
  {
    "code": "5566",
    "description": "Convention d'entreprise Soci\u00e9t\u00e9 Protectrice des Animaux"
  },
  {
    "code": "5567",
    "description": "Convention d'entreprise ARTE"
  },
  {
    "code": "5568",
    "description": "Convention d'entreprise CIRAD"
  },
  {
    "code": "5569",
    "description": "Convention d'entreprise Com\u00e9die fran\u00e7aise"
  },
  {
    "code": "5570",
    "description": "Convention d'entreprise Op\u00e9ra de Paris"
  },
  {
    "code": "5571",
    "description": "Convention d'entreprise Fondation d'Auteuil"
  },
  {
    "code": "5572",
    "description": "Convention d'entreprise Kiloutou"
  },
  {
    "code": "5573",
    "description": "Convention d'entreprise UGAP"
  },
  {
    "code": "5574",
    "description": "Convention du groupe Canal +"
  },
  {
    "code": "5575",
    "description": "Convention d'entreprise du groupe M\u00e9tropole T\u00e9l\u00e9vision"
  },
  {
    "code": "5576",
    "description": "Convention du groupe AGEFOS PME"
  },
  {
    "code": "5577",
    "description": "Convention d'entreprise Agence Fran\u00e7aise de D\u00e9veloppement"
  },
  {
    "code": "5578",
    "description": "Convention d'entreprise Agence Nationale pour les Ch\u00e8ques Vacances"
  },
  {
    "code": "5579",
    "description": "Accords-convention d'entreprise France t\u00e9l\u00e9vision"
  },
  {
    "code": "5580",
    "description": "Convention d'entreprise Radio France "
  },
  {
    "code": "5581",
    "description": "Convention d'entreprise France M\u00e9dias Monde"
  },
  {
    "code": "5582",
    "description": "Accords-convention d'entreprise Institut national de l'audio visuel"
  },
  {
    "code": "5583",
    "description": "Convention d'entreprise Voies navigables de France"
  },
  {
    "code": "5584",
    "description": "Accords-convention d'entreprise Laboratoire national de m\u00e9trologie et d\u2019essais"
  },
  {
    "code": "5585",
    "description": "Accords-convention d'entreprise Pioneer"
  },
  {
    "code": "5586",
    "description": "Accords-convention d'entreprise SACEM"
  },
  {
    "code": "5587",
    "description": "Accords-convention d'entreprise Cit\u00e9 de la musique"
  },
  {
    "code": "5588",
    "description": "Accords-convention d'entreprise SACD"
  },
  {
    "code": "5589",
    "description": "Convention d'entreprise ARPEJ"
  },
  {
    "code": "5590",
    "description": "Convention d'entreprise OPCALIM"
  },
  {
    "code": "5591",
    "description": "Convention d'entreprise Louis Vuitton services"
  },
  {
    "code": "5592",
    "description": "Convention d'entreprise UES CAMIF"
  },
  {
    "code": "5593",
    "description": "Accord de r\u00e9f\u00e9rence FNAB"
  },
  {
    "code": "5594",
    "description": "Convention d'entreprise Ortec Services"
  },
  {
    "code": "5595",
    "description": "Convention d'entreprise Sede environnement"
  },
  {
    "code": "5596",
    "description": "Convention d'entreprise Compagnie des Alpes"
  },
  {
    "code": "5598",
    "description": "Convention d'entreprise Eurotunnel"
  },
  {
    "code": "5599",
    "description": "Convention d'entreprise Havas"
  },
  {
    "code": "5600",
    "description": "Accords-convention d'entreprise CGG Services"
  },
  {
    "code": "5601",
    "description": "Accords-convention d'entreprise COSEM"
  },
  {
    "code": "5602",
    "description": "Accords-convention d'entreprise Bureau de recherche g\u00e9ologiques et mini\u00e8res\u00a0"
  },
  {
    "code": "5603",
    "description": "Accords-convention d'entreprise Synchrotron Soleil"
  },
  {
    "code": "5604",
    "description": "Accords-convention d'entreprise Accor"
  },
  {
    "code": "5605",
    "description": "Convention d'entreprise Blondel a\u00e9rologistique"
  },
  {
    "code": "5606",
    "description": "Convention d'entreprise ANFH"
  },
  {
    "code": "5607",
    "description": "Convention d'entreprise Danone"
  },
  {
    "code": "5608",
    "description": "Convention d'entreprise Arvalis institut du v\u00e9g\u00e9tal"
  },
  {
    "code": "5610",
    "description": "Convention d'entreprise Cin\u00e9math\u00e8que fran\u00e7aise"
  },
  {
    "code": "5611",
    "description": "Accords-convention d'entreprise ADIE"
  },
  {
    "code": "5612",
    "description": "Accords-convention d'entreprise Eurosport"
  },
  {
    "code": "5614",
    "description": "Convention d'entreprise Institut de l'\u00e9levage"
  },
  {
    "code": "5615",
    "description": "Convention d'entreprise Forum des images"
  },
  {
    "code": "5616",
    "description": "Accords-convention d'entreprise CTC"
  },
  {
    "code": "5617",
    "description": "Accords-convention d'entreprise Sodexo Justice Services"
  },
  {
    "code": "5618",
    "description": "Accords-convention d'entreprise ADEF"
  },
  {
    "code": "5619",
    "description": "Convention collective nationale provisoire de la p\u00eache professionnelle maritime"
  },
  {
    "code": "5620",
    "description": "Convention d'entreprise Messageries lyonnaises de presse"
  },
  {
    "code": "5621",
    "description": "Convention d'entreprise Fondation Jean Moulin"
  },
  {
    "code": "5622",
    "description": "Accords-convention d'entreprise CCFD Terre Solidaire "
  },
  {
    "code": "5623",
    "description": "Convention d'entreprise du groupement national interprofessionnel des semences graines et plants"
  },
  {
    "code": "5624",
    "description": "Accords-convention d'entreprise Secours catholique"
  },
  {
    "code": "5625",
    "description": "Convention d'entreprise M\u00e9decins du monde"
  },
  {
    "code": "5627",
    "description": "Convention d'entreprise du Groupe Technique des Hippodromes Parisiens"
  },
  {
    "code": "5628",
    "description": "Statut-convention d'entreprise d'ACPPA"
  },
  {
    "code": "5629",
    "description": "Convention d'entreprise d'Unifomation"
  },
  {
    "code": "5630",
    "description": "Accords-convention d'entreprise France active"
  },
  {
    "code": "5631",
    "description": "Convention d'entreprise UNAF"
  },
  {
    "code": "5632",
    "description": "Accords-convention d'entreprise Sodiparc"
  },
  {
    "code": "5633",
    "description": "Accords CFA-BTP (num\u00e9ro provisoire)"
  },
  {
    "code": "5634",
    "description": "Accords collaborateurs parlementaires de d\u00e9put\u00e9s (num\u00e9ro provisoire)"
  },
  {
    "code": "5635",
    "description": "Accords-convention d'entreprise TV5 Monde"
  },
  {
    "code": "7001",
    "description": "Convention collective nationale des coop\u00e9ratives et SICA de production, transformation et vente du b\u00e9tail et des viandes"
  },
  {
    "code": "7002",
    "description": "Convention collective nationale des coop\u00e9ratives et SICA de c\u00e9r\u00e9ales, de meunerie, d'approvisionnement et d'alimentation du b\u00e9tail et d'ol\u00e9agineux"
  },
  {
    "code": "7003",
    "description": "Convention collective nationale des coop\u00e9ratives agricoles, union de coop\u00e9ratives agricoles et SICA fabriquant des conserves de fruits et de l\u00e9gumes, des plats cuisin\u00e9s et des sp\u00e9cialit\u00e9s"
  },
  {
    "code": "7004",
    "description": "Convention collective nationale des coop\u00e9ratives laiti\u00e8res, unions de coop\u00e9ratives laiti\u00e8res et SICA laiti\u00e8res"
  },
  {
    "code": "7005",
    "description": "Convention collective nationale des caves coop\u00e9ratives et de leurs unions \u00e9largie aux SICA vinicoles"
  },
  {
    "code": "7006",
    "description": "Convention collective nationale des coop\u00e9ratives, unions de coop\u00e9ratives agricoles et SICA de fleurs, de fruits et l\u00e9gumes et de pommes de terre"
  },
  {
    "code": "7007",
    "description": "Convention collective nationale des coop\u00e9ratives, unions de coop\u00e9ratives agricoles et SICA de teillage de lin"
  },
  {
    "code": "7008",
    "description": "Convention collective nationale du personnel des organismes de contr\u00f4le laitier"
  },
  {
    "code": "7009",
    "description": "Convention collective nationale des entreprises d'accouvage et de s\u00e9lection de produits avicoles"
  },
  {
    "code": "7010",
    "description": "Convention collective nationale du personnel des \u00e9l\u00e9vages aquacoles "
  },
  {
    "code": "7012",
    "description": "Convention collective nationale des centres \u00e9questres"
  },
  {
    "code": "7013",
    "description": "Convention collective nationale des \u00e9tablissements d'entra\u00eenement des chevaux de courses au trot"
  },
  {
    "code": "7014",
    "description": "Convention collective nationale des \u00e9tablissements d'entra\u00eenement des chevaux de courses au galop"
  },
  {
    "code": "7017",
    "description": "Convention collective nationale des parcs et jardins zoologiques ouverts au public"
  },
  {
    "code": "7018",
    "description": "Convention collective nationale des entreprises du paysage"
  },
  {
    "code": "7019",
    "description": "Convention collective nationale de la conchyliculture"
  },
  {
    "code": "7020",
    "description": "Convention collective nationale du r\u00e9seau des centres d'\u00e9conomie rurale"
  },
  {
    "code": "7021",
    "description": "Convention collective nationale des entreprises relevant de la s\u00e9lection et de la reproduction animale"
  },
  {
    "code": "7023",
    "description": "Convention collective nationale des entreprises agricoles de d\u00e9shydratation"
  },
  {
    "code": "7501",
    "description": "Convention collective nationale des caisses r\u00e9gionales du cr\u00e9dit agricole"
  },
  {
    "code": "7502",
    "description": "Convention collective nationale de la Mutualit\u00e9 sociale agricole"
  },
  {
    "code": "7503",
    "description": "Convention collective nationale des distilleries coop\u00e9ratives viticoles et SICA de distillation"
  },
  {
    "code": "7508",
    "description": "Convention collective nationale des Maisons familiales rurales, instituts ruraux et centres"
  },
  {
    "code": "7509",
    "description": "Convention collective nationale des organismes de formation et de promotion agricoles"
  },
  {
    "code": "7511",
    "description": "Convention collective nationale du personnel des ASAVPA"
  },
  {
    "code": "7513",
    "description": "Convention collective nationale des centres d'initiatives pour valoriser l'agriculture et le milieu rural"
  },
  {
    "code": "7514",
    "description": "Convention collective nationale des organismes de la Conf\u00e9d\u00e9ration paysanne"
  },
  {
    "code": "7515",
    "description": "Convention collective nationale des soci\u00e9t\u00e9s d'am\u00e9nagement foncier et d'\u00e9tablissement rural (SAFER)"
  },
  {
    "code": "7520",
    "description": "Convention collective nationale de l'enseignement agricole priv\u00e9 (CNEAP)"
  },
  {
    "code": "8112",
    "description": "Convention collective r\u00e9gionale de la polyculture \u00cele-de-France non cadres"
  },
  {
    "code": "8113",
    "description": "Convention collective r\u00e9gionale de l'arboriculture mara\u00eechage \u00cele-de-France"
  },
  {
    "code": "8114",
    "description": "Convention collective r\u00e9gionale des champignonni\u00e8res \u00cele-de-France"
  },
  {
    "code": "8115",
    "description": "Convention collective r\u00e9gionale des hippodromes \u00cele-de-France Cabourg Caen Chantilly Deauville"
  },
  {
    "code": "8116",
    "description": "Convention collective r\u00e9gionale de la polyculture \u00cele-de-France cadres"
  },
  {
    "code": "8117",
    "description": "Convention collective r\u00e9gionale des exploitations polyculture \u00e9lev CUMA Seine et Marne ETAR Ile de France"
  },
  {
    "code": "8211",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res Champagne Ardenne"
  },
  {
    "code": "8212",
    "description": "Convention collective r\u00e9gionale des scieries Champagne Ardenne"
  },
  {
    "code": "8214",
    "description": "Convention collective r\u00e9gionale des ETAR Aube Marne polyculture Marne"
  },
  {
    "code": "8216",
    "description": "Convention collective r\u00e9gionale de la viticulture Champagne"
  },
  {
    "code": "8221",
    "description": "Convention collective r\u00e9gionale des champignonnistes Oise Aisne"
  },
  {
    "code": "8231",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res Haute Normandie"
  },
  {
    "code": "8233",
    "description": "Convention collective r\u00e9gionale des ETAR Haute Normandie"
  },
  {
    "code": "8234",
    "description": "Convention collective r\u00e9gionale de l'horticulture et p\u00e9pini\u00e9ristes Haute Normandie"
  },
  {
    "code": "8241",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Centre"
  },
  {
    "code": "8243",
    "description": "Convention collective r\u00e9gionale des champignonni\u00e8res Centre"
  },
  {
    "code": "8244",
    "description": "Convention collective r\u00e9gionale mara\u00eech\u00e8re Indre Cher"
  },
  {
    "code": "8251",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Calvados Manche Orne"
  },
  {
    "code": "8252",
    "description": "Convention collective r\u00e9gionale des travaux agricoles Basse Normandie"
  },
  {
    "code": "8262",
    "description": "Convention collective r\u00e9gionale des exploitations agricoles C\u00f4te d'or Ni\u00e8vre Yonne ETAR CUMA C\u00f4te d'or"
  },
  {
    "code": "8311",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Nord Pas de Calais"
  },
  {
    "code": "8313",
    "description": "Convention collective r\u00e9gionale des ETAR Nord Pas de Calais"
  },
  {
    "code": "8412",
    "description": "Convention collective r\u00e9gionale des scieries agricoles Alsace Lorraine"
  },
  {
    "code": "8414",
    "description": "Convention collective r\u00e9gionale mara\u00eech\u00e8re Meurthe et Moselle Meuse Moselle et Vosges "
  },
  {
    "code": "8415",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res Lorraine"
  },
  {
    "code": "8416",
    "description": "Convention collective r\u00e9gionale de polyculture Lorraine"
  },
  {
    "code": "8421",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res Alsace"
  },
  {
    "code": "8422",
    "description": "Convention collective r\u00e9gionale de polyculture Alsace"
  },
  {
    "code": "8431",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res Doubs Jura Haute-Sa\u00f4ne Territoire de Belfort "
  },
  {
    "code": "8432",
    "description": "Convention collective r\u00e9gionale des scieries agricoles Franche Comt\u00e9"
  },
  {
    "code": "8433",
    "description": "Convention collective r\u00e9gionale de l'horticulture Franche Comt\u00e9"
  },
  {
    "code": "8434",
    "description": "Convention collective r\u00e9gionale des cultures CUMA ETAR Franche Comt\u00e9"
  },
  {
    "code": "8435",
    "description": "Convention collective r\u00e9gionale des coop\u00e9ratives fruiti\u00e8res Ain Doubs Jura"
  },
  {
    "code": "8522",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Pays de la Loire"
  },
  {
    "code": "8523",
    "description": "Convention collective r\u00e9gionale des exploitations sylvicoles Pays de la Loire"
  },
  {
    "code": "8525",
    "description": "Convention collective r\u00e9gionale des ETAR Pays de la Loire"
  },
  {
    "code": "8526",
    "description": "Convention collective r\u00e9gionale de l'arboriculture fruiti\u00e8re Ouest de la France"
  },
  {
    "code": "8531",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Bretagne"
  },
  {
    "code": "8532",
    "description": "Convention collective r\u00e9gionale des ETAR Bretagne"
  },
  {
    "code": "8534",
    "description": "Convention collective r\u00e9gionale mara\u00eech\u00e8re Ille et Vilaine Morbihan"
  },
  {
    "code": "8535",
    "description": "Convention collective r\u00e9gionale des CUMA Bretagne Pays de la Loire"
  },
  {
    "code": "8541",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Poitou Charentes"
  },
  {
    "code": "8542",
    "description": "Convention collective r\u00e9gionale des ETAR Vienne Deux S\u00e8vres production agricole Vienne"
  },
  {
    "code": "8721",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Massif Gascogne"
  },
  {
    "code": "8722",
    "description": "Convention collective r\u00e9gionale de gemmage for\u00eat Gascogne"
  },
  {
    "code": "8723",
    "description": "Convention collective r\u00e9gionale de l'entretien forestier Gascogne"
  },
  {
    "code": "8731",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Midi Pyr\u00e9n\u00e9es"
  },
  {
    "code": "8733",
    "description": "Convention collective r\u00e9gionale des CUMA Tarn Haute Garonne"
  },
  {
    "code": "8734",
    "description": "Convention collective r\u00e9gionale de l'horticulture Midi Pyr\u00e9n\u00e9es"
  },
  {
    "code": "8741",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res Limousin"
  },
  {
    "code": "8821",
    "description": "Convention collective r\u00e9gionale des ouvriers forestiers communes ONF Rh\u00f4ne Alpes"
  },
  {
    "code": "8822",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Rh\u00f4ne Alpes"
  },
  {
    "code": "8825",
    "description": "Convention collective r\u00e9gionale de polyculture CUMA Rh\u00f4ne Alpes cadres"
  },
  {
    "code": "8826",
    "description": "Convention collective r\u00e9gionale des exploitations trav agricoles CUMA Savoie Hte Savoie"
  },
  {
    "code": "8831",
    "description": "Convention collective r\u00e9gionale des exploitations foresti\u00e8res scieries Auvergne"
  },
  {
    "code": "8832",
    "description": "Convention collective r\u00e9gionale de polyculture CUMA Haute Loire Loz\u00e8re"
  },
  {
    "code": "8912",
    "description": "Convention collective r\u00e9gionale des ETAR Languedoc Roussillon"
  },
  {
    "code": "9011",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Ain"
  },
  {
    "code": "9021",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Aisne"
  },
  {
    "code": "9022",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res Aisne"
  },
  {
    "code": "9032",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Allier"
  },
  {
    "code": "9041",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Alpes de Haute Provence"
  },
  {
    "code": "9051",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Hautes Alpes"
  },
  {
    "code": "9061",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Alpes Maritimes"
  },
  {
    "code": "9062",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res scieries Alpes Maritimes"
  },
  {
    "code": "9071",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Ard\u00e8che"
  },
  {
    "code": "9081",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Ardennes"
  },
  {
    "code": "9091",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Ari\u00e8ge"
  },
  {
    "code": "9101",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Aube"
  },
  {
    "code": "9103",
    "description": "Convention collective d\u00e9partementale de sylviculture Aube"
  },
  {
    "code": "9111",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles zone c\u00e9r\u00e9ali\u00e8re Aude"
  },
  {
    "code": "9112",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles zone viticole Aude"
  },
  {
    "code": "9121",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Aveyron"
  },
  {
    "code": "9131",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Bouches du Rh\u00f4ne "
  },
  {
    "code": "9141",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture et CUMA Calvados"
  },
  {
    "code": "9142",
    "description": "Convention collective d\u00e9partementale des exploitations horticoles fruits Calvados"
  },
  {
    "code": "9151",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Cantal"
  },
  {
    "code": "9161",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Charente"
  },
  {
    "code": "9171",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Charente Maritime"
  },
  {
    "code": "9181",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Cher"
  },
  {
    "code": "9182",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Cher"
  },
  {
    "code": "9191",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Corr\u00e8ze"
  },
  {
    "code": "9201",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Corse du Sud"
  },
  {
    "code": "9202",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Haute Corse"
  },
  {
    "code": "9211",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res scieries C\u00f4te d'or"
  },
  {
    "code": "9221",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture C\u00f4tes d'Armor"
  },
  {
    "code": "9222",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture C\u00f4tes d'Armor"
  },
  {
    "code": "9231",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Creuse"
  },
  {
    "code": "9232",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res sylvicoles Creuse"
  },
  {
    "code": "9241",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Dordogne"
  },
  {
    "code": "9261",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Dr\u00f4me"
  },
  {
    "code": "9272",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Eure non cadres"
  },
  {
    "code": "9273",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture Eure cadres"
  },
  {
    "code": "9281",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture, \u00e9levage et CUMA Eure et Loir"
  },
  {
    "code": "9283",
    "description": "Convention collective d\u00e9partementale des exploitations horticoles fruiti\u00e8res jardinerie Eure et Loire"
  },
  {
    "code": "9291",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture, \u00e9levage et mara\u00eechage Finist\u00e8re"
  },
  {
    "code": "9292",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Finist\u00e8re"
  },
  {
    "code": "9301",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Gard"
  },
  {
    "code": "9302",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Gard cadres"
  },
  {
    "code": "9311",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Haute Garonne"
  },
  {
    "code": "9321",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Gers"
  },
  {
    "code": "9331",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Gironde"
  },
  {
    "code": "9341",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles H\u00e9rault"
  },
  {
    "code": "9351",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture, \u00e9levage et CUMA Ille et Vilaine"
  },
  {
    "code": "9352",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Ille et Vilaine"
  },
  {
    "code": "9361",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture, \u00e9levage et CUMA Indre"
  },
  {
    "code": "9362",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Indre"
  },
  {
    "code": "9371",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture, \u00e9levage viticulture Indre et Loire"
  },
  {
    "code": "9372",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Indre et Loire"
  },
  {
    "code": "9374",
    "description": "Convention collective d\u00e9partementale des exploitations arboriculture fruiti\u00e8re Indre et Loire"
  },
  {
    "code": "9383",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles CUMA Is\u00e8re"
  },
  {
    "code": "9401",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Landes"
  },
  {
    "code": "9411",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Loir et Cher"
  },
  {
    "code": "9412",
    "description": "Convention collective d\u00e9partementale des ETAR CUMA Loir et Cher"
  },
  {
    "code": "9413",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Loir et Cher"
  },
  {
    "code": "9421",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Loire"
  },
  {
    "code": "9422",
    "description": "Convention collective d\u00e9partementale des CUMA Loire"
  },
  {
    "code": "9441",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Loire Atlantique"
  },
  {
    "code": "9442",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Loire Atlantique"
  },
  {
    "code": "9444",
    "description": "Convention collective d\u00e9partementale des exploitations mara\u00eech\u00e8res Loire Atlantique"
  },
  {
    "code": "9451",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Loiret"
  },
  {
    "code": "9452",
    "description": "Convention collective d\u00e9partementale des CUMA Loiret"
  },
  {
    "code": "9456",
    "description": "Convention collective d\u00e9partementale des exploitations cultures sp\u00e9cialis\u00e9es Loiret"
  },
  {
    "code": "9461",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Lot"
  },
  {
    "code": "9471",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Lot et Garonne"
  },
  {
    "code": "9472",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Lot et Garonne"
  },
  {
    "code": "9491",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture, \u00e9levage Maine et Loire"
  },
  {
    "code": "9492",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Maine et Loire"
  },
  {
    "code": "9493",
    "description": "Convention collective d\u00e9partementale des champignonni\u00e8res Maine et Loire"
  },
  {
    "code": "9494",
    "description": "Convention collective d\u00e9partementale des exploitations mara\u00eech\u00e8res Maine et Loire"
  },
  {
    "code": "9497",
    "description": "Convention collective d\u00e9partementale des producteurs graines Maine et Loire"
  },
  {
    "code": "9501",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Manche"
  },
  {
    "code": "9502",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Manche"
  },
  {
    "code": "9521",
    "description": "Convention collective d\u00e9partementale des polyculture p\u00e9pini\u00e8res horticulture CUMA Haute Marne"
  },
  {
    "code": "9531",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Mayenne"
  },
  {
    "code": "9532",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Mayenne"
  },
  {
    "code": "9561",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Morbihan"
  },
  {
    "code": "9562",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Morbihan"
  },
  {
    "code": "9581",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res Ni\u00e8vre"
  },
  {
    "code": "9591",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Nord"
  },
  {
    "code": "9592",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Nord"
  },
  {
    "code": "9601",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Oise"
  },
  {
    "code": "9602",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res Oise"
  },
  {
    "code": "9603",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Oise"
  },
  {
    "code": "9612",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Orne"
  },
  {
    "code": "9613",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Orne"
  },
  {
    "code": "9621",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Pas de Calais"
  },
  {
    "code": "9622",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Pas de Calais "
  },
  {
    "code": "9631",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Puy de D\u00f4me"
  },
  {
    "code": "9641",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Pyr\u00e9n\u00e9es Atlantiques"
  },
  {
    "code": "9651",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Hautes Pyr\u00e9n\u00e9es"
  },
  {
    "code": "9661",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Pyr\u00e9n\u00e9es Orientales"
  },
  {
    "code": "9691",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Rh\u00f4ne"
  },
  {
    "code": "9711",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res Sa\u00f4ne et Loire"
  },
  {
    "code": "9712",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Sa\u00f4ne et Loire"
  },
  {
    "code": "9721",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Sarthe"
  },
  {
    "code": "9722",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Sarthe"
  },
  {
    "code": "9723",
    "description": "Convention collective d\u00e9partementale des exploitations mara\u00eech\u00e8res Sarthe"
  },
  {
    "code": "9725",
    "description": "Convention collective d\u00e9partementale des champignonni\u00e8res Sarthe"
  },
  {
    "code": "9761",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Seine Maritime"
  },
  {
    "code": "9762",
    "description": "Convention collective d\u00e9partementale des exploitations mara\u00eech\u00e8res Seine Maritime"
  },
  {
    "code": "9791",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Deux S\u00e8vres"
  },
  {
    "code": "9801",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res Somme"
  },
  {
    "code": "9802",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage et CUMA, ETAR Somme"
  },
  {
    "code": "9811",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Tarn"
  },
  {
    "code": "9821",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Tarn et Garonne"
  },
  {
    "code": "9831",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Var"
  },
  {
    "code": "9841",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Vaucluse"
  },
  {
    "code": "9851",
    "description": "Convention collective d\u00e9partementale des exploitations polyculture \u00e9levage Vend\u00e9e"
  },
  {
    "code": "9852",
    "description": "Convention collective d\u00e9partementale des exploitations p\u00e9pini\u00e8res horticulture Vend\u00e9e"
  },
  {
    "code": "9853",
    "description": "Convention collective d\u00e9partementale des exploitations mara\u00eech\u00e8res Vend\u00e9e"
  },
  {
    "code": "9862",
    "description": "Convention collective d\u00e9partementale des  champignonni\u00e8res Vienne"
  },
  {
    "code": "9871",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Haute Vienne"
  },
  {
    "code": "9891",
    "description": "Convention collective d\u00e9partementale des exploitations foresti\u00e8res Yonne"
  },
  {
    "code": "9971",
    "description": "Convention collective d\u00e9partementale des exploitations banani\u00e8res Martinique"
  },
  {
    "code": "9972",
    "description": "Convention collective d\u00e9partementale des exploitations agricoles Guyane"
  },
  {
    "code": "9998",
    "description": "___Convention non encore en vigueur___"
  },
  {
    "code": "9999",
    "description": "___Sans convention collective___"
  }
]