import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { isAllowed } from "../../App";
import Container from "@material-ui/core/Container";
import { useCallback } from "react";
import LogoAstimm from "../../assets/logo.png";
import SanteSrc from "../../assets/logo-sante/LOGO-SVA-RVB.svg";
import PrevoyanceSrc from "../../assets/Prevoyance@2x.png";
//import ConstructionIcon from "@mui/icons-material/Construction";

import { useHistory } from "react-router-dom";
import { HomeProps } from "../../components/types";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 170,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
});

export function Home({ roles }: HomeProps) {
  const history = useHistory();
  const handleSante = useCallback(() => history.push("/sante"), [history]);
  const handleGFA = useCallback(() => history.push("/gfa"), [history]);
  const classes = useStyles();

  return (
    <Container>
      <Grid container spacing={5}>
        {isAllowed(["Broker", "Admin"], roles) ? (
          <Grid xs={12} md={6} item>
            <Card onClick={handleSante} className="button">
              <CardMedia className={classes.media}>
                <img src={SanteSrc} height="150px" alt="" />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Santé Vous Assuré
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  la complémentaire santé performante, simple et engagée pour
                  les entreprises !
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {isAllowed(["Broker", "Admin"], roles) ? (
          <Grid xs={12} md={6} item>
            <Card className="button">
              <CardMedia className={classes.media}>
                <img src={PrevoyanceSrc} height="150px" alt="" />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Prévoyance
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Bientôt disponible
                  {/* la complémentaire santé performante, simple et engagée pour les entreprises ! */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {isAllowed(["Admin", "GFA broker", "GFA admin"], roles) ? (
          <Grid xs={12} md={6} item>
            <Card onClick={handleGFA} className="button">
              <CardMedia className={classes.media}>
                <img src={LogoAstimm} height="150px" alt="" />
                {/*                 <ConstructionIcon sx={{ height: "150px", width: "150px" }} />
                 */}{" "}
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Garantie financière
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Garantie financière d'achèvement
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}
