import { Box, FormControlLabel, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Checkbox } from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { CompanyInfo, LegalForm, showLegalForm, StepParams, CodeType } from "./types";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useAPI } from "../api";
import { codeCCNList } from "../assets/codeCCN";
import { codeAPEList } from "../assets/codeAPE";
import { SelectCode } from "./SelectCode";
import { validateEmail } from "../lib";
import { Alert } from "@material-ui/lab";


export const emptyCompanyInfo: CompanyInfo = {
  siret: "",
  legalForm: "",
  name: "",
  representant: { genderTitle: "", firstname: "", lastname: "", email: "", role: "", telephone: "" },
  address: "",
  postalCode: "",
  city: "",
  codeAPE: {code: "", description: ""},
  codeCCN: {code: "", description: ""},
}

type Form<T> = {value: T, setValue: (x: T) => void, highlight: boolean}

function siretFromInput(input: string): string {
  return input.replace(/ /g, "")
}

function inputToSiret(siret: string): string {
  let splitted = [ siret.substr(0, 3),
                   siret.substr(3, 3),
                   siret.substr(6, 3),
                   siret.substr(9, 14) ]
  if (splitted) {
    return splitted.filter(a => a.length > 0)
      .reduce((accum, el) => accum + " " + el, "")
      .trimStart()
  } else {
    return ""
  }
}

function Siret({ value, setValue, highlight }: Form<string>) {
  const [siret, setSiret] = useState(value)
  const [valid, setValid] = useState(siret.length === 0)

  const handleChange = (val: string) => (old: string) => {
    return inputToSiret(siretFromInput(val))
  }

  const validateSiret = useCallback(() => {
    const siretRegex = /^([0-9]{14}|.{0})$/
    if (siretFromInput(siret).match(siretRegex)) {
      setValid(true)
      return true
    } else {
      setValid(false)
      return false
    }
  }, [siret])

  useEffect(() => {
    if (validateSiret() && siret.length > 0) {
      setValue(siretFromInput(siret))
    }
  // eslint-disable-next-line
  }, [siret, validateSiret])

  return (
    <TextField
      label="N° SIRET de l'entreprise"
      name="SIRET"
      value={siret}
      onBlur={validateSiret}
      onChange={(event) => setSiret(handleChange(event.target.value))}
      error={highlight && (siret === "" || !valid)}
      helperText={!valid ? "SIRET doit avoir 14 chiffres":""}
      required
      fullWidth
    />
  );
}

export function CompanyInfoForm({ value, next }: StepParams<CompanyInfo>) {
  const { getCompanyInfo } = useAPI()
  const [siret, setSiret] = useState(value.siret)
  const [siretLookup, setSiretLookup] = useState(false)
  const [siretLookupError, setSiretLookupError] = useState("")
  
  const [highlight, setHighLight] = useState(false)
  
  const [legalForm, setLegalForm] = useState(value.legalForm)
  const [name, setName] = useState(value.name)

  const [genderTitle, setGenderTitle] = useState(value.representant.genderTitle)
  const [ maleTitleCheckBox, setMaleTitleCheckBox ] = useState(value.representant.genderTitle === 'Monsieur' ? true : false)
  const [ femaleTitleCheckBox, setFemaleTitleCheckBox ] = useState(value.representant.genderTitle === 'Madame' ? true : false);

  const [firstname, setFirstname] = useState(value.representant.firstname)
  const [lastname, setLastname] = useState(value.representant.lastname)
  const [role, setRole] = useState(value.representant.role)
  const [telephone, setTelephone] = useState(value.representant.telephone)
  const [email, setEmail] = useState(value.representant.email)

  const [address, setAddress] = useState(value.address)
  const [postalCode, setPostalCode] = useState(value.postalCode)
  const [city, setCity] = useState(value.city)
  const [codeAPE, setCodeAPE] = useState(value.codeAPE)
  const [codeCCN, setCodeCCN] = useState(value.codeCCN)

  const lookupOptionsForCodeAPE = useCallback((v: CodeType) => codeAPEList.find(e => e.code === v.code) || v,
  [])
  // eslint-disable-next-line
  useEffect(() => setCodeAPE(lookupOptionsForCodeAPE(codeAPE)), [codeAPE])

  const lookupInfo = useCallback(() => {
    if (siret.length > 0) {
      setSiretLookup(true)
      getCompanyInfo(siret)()
        .then(info => {
          setName(info.name || "")
          setAddress(info.address || "")
          setPostalCode(info.postalCode || "")
          setCity(info.city || "")
          setCodeAPE({code: info.codeAPE || "", description: ""})
          setSiretLookupError("")
        })
        .catch(e => {
          console.log(e)
          setSiretLookupError("Établissement introuvable")
        })
        .finally(() => setSiretLookup(false))
    }
  // eslint-disable-next-line
  }, [siret, name])

  let info: CompanyInfo = { 
    siret, legalForm, 
    name, address, postalCode, city, 
    codeAPE, codeCCN,
    representant: { genderTitle, firstname, lastname, role, telephone, email }
  }

  const isPostalCodeValid = (): boolean =>
    postalCode.length === 5 &&
    postalCodes.some(pc => postalCode.startsWith(pc)) &&
    !excludedPostalCodes.some(pc => postalCode.startsWith(pc))
  const isEmailValid = (): boolean => email.length > 0 && validateEmail(email)

  const valid = useCallback(() => {
    if(siret.length > 0 && legalForm.length > 0 && name.length > 0 && 
      firstname.length > 0 && lastname.length > 0 && role.length > 0 &&
      telephone.length > 0 &&
      address.length > 0 && city.length > 0 &&
      codeAPE.code.length > 0 && codeCCN.code.length > 0 &&
        isEmailValid() &&
        isPostalCodeValid()) {
          return true
        }
    else {
      return false
    }
  // eslint-disable-next-line
  }, [info])

  const interproCCN = useMemo(() =>
    codeCCN.code.length > 0 && codeCCN.code !== "1486" && codeCCN.code !== "1512",
  [codeCCN.code])

  const HandleTitleCheckBoxes = (title: string) => {
    if(title === "Monsieur") {
      setMaleTitleCheckBox(true)
      setFemaleTitleCheckBox(false)
      setGenderTitle("Monsieur")     
    } else if (title === "Madame") {
      setMaleTitleCheckBox(false)
      setFemaleTitleCheckBox(true)
      setGenderTitle("Madame")   
    }
  }

/*    const handleSubmit = (cb: CallableFunction, valid: boolean) => {

   }
 */
  return (
    <Box>
      <form className="basic-info" noValidate autoComplete="on">
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={10} md={4}>
            <Siret value={siret} setValue={setSiret} highlight={highlight}/>
          </Grid>
          <Grid item>
            <Box style={{marginTop: "20px"}} display="flex" alignItems="baseline">
              <Button color="primary" variant="outlined"
                onClick={lookupInfo}
              >
                Rechercher
              </Button>
              <Box ml={2}>
                { siretLookup ?
                  <CircularProgress size={20}/>:
                  siretLookupError ?
                    <Box color="warning.main">
                      <Typography variant="body2">{ siretLookupError } </Typography>
                    </Box>
                  :<Fragment/>
                }
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl style={ {width: 220} }>
              <InputLabel id="legalForm" required error={highlight && (legalForm ? false : true)}>Forme juridique</InputLabel>
              <Select
                labelId="legalForm"
                value={legalForm}
                onChange={event => setLegalForm(event.target.value as LegalForm)}
              >
                {(["SARL", "SA", "SAS/SASU", "SNC", "Scop", "SCA+SCS"] as LegalForm[])
                  .map(f => <MenuItem value={f}>{showLegalForm(f)}</MenuItem> )
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField 
              label="Dénomination sociale"
              value={name}
              name="denomination-sociale"
              onChange={event => setName(event.target.value)}
              required 
              fullWidth
              error={highlight && (name ? false : true)}
              />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField 
              label="Adresse"
              name="address"
              value={address}
              onChange={event => setAddress(event.target.value)}
              required 
              fullWidth
              error={highlight && (address ? false : true)}
              />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} md={2}>
            <TextField 
              label="Code postal"
              name="code-postal"
              value={postalCode}
              error={highlight && (postalCode === "" || (postalCode ? !isPostalCodeValid() : false))}
              helperText={postalCode.length > 1 && !isPostalCodeValid() ? "Code postal invalide":""}
              onChange={event => setPostalCode(event.target.value)}
              required 
              fullWidth />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextField 
              label="Ville"
              name="ville"
              value={city}
              onChange={event => setCity(event.target.value)}
              required
              error={highlight && (city ? false : true)}
              fullWidth
               />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4} md={3}>
            <SelectCode
              label="Code APE"
              options={codeAPEList}
              value={codeAPE}
              onChange={value => setCodeAPE(value)}
              highlight={highlight}
            />
          </Grid>
          <Grid item xs={8} md={3}>
            <SelectCode
              label="Code CCN"
              options={codeCCNList}
              value={codeCCN}
              onChange={value => { 
                console.log(value)
                setCodeCCN(value)}}
              highlight={highlight}
            />
          </Grid>
        </Grid>

        { interproCCN ?
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Alert severity="warning">
                Pensez à vérifier si la convention collective à laquelle est rattachée
                l’entreprise définit des obligations spécifiques en frais de santé
                afin de choisir une formule adaptée.
              </Alert>
            </Grid>
          </Grid>:null
        }

        { excludedPostalCodes.some(pc => postalCode.startsWith(pc)) ?
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Alert severity="error">
                Les formules Santé Vous Assuré ne peuvent être proposées pour
                les entreprises domiciliées : en Polynésie Française,
                en Nouvelle Calédonie, à la Réunion, ou à Monaco.
              </Alert>
            </Grid>
          </Grid>:null
        }

        <Box mt={5}>
        <Typography variant="h3" >Représentant légal</Typography>
        </Box>
        <Box mt={3}>
          <FormControlLabel control={<Checkbox sx={{color: genderTitle ? 'primary' : highlight ? 'red' : 'primary'}} checked={maleTitleCheckBox} color="primary" onChange={() => HandleTitleCheckBoxes("Monsieur")} />} label="Monsieur" />
          <FormControlLabel control={<Checkbox sx={{color: genderTitle ? 'primary' : highlight ? 'red' : 'primary'}} checked={femaleTitleCheckBox} color="primary" onChange={() => HandleTitleCheckBoxes("Madame")}/>} label="Madame" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <TextField label="Prénom"
              autoComplete="given-name"
              name="given-name"
              value={firstname}
              onChange={event => setFirstname(event.target.value)}
              error={highlight && (firstname ? false : true)}
              required 
              fullWidth />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField label="Nom"
              autoComplete="family-name"
              name="last-name"
              value={lastname}
              onChange={event => setLastname(event.target.value)}
              error={highlight && (lastname ? false : true)}
              required 
              fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <TextField label="Fonction"
              name="role"
              autoComplete="organization-title"
              value={role}
              onChange={event => setRole(event.target.value)}
              error={highlight && (role ? false : true)}
              required
              fullWidth />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <TextField label="Adresse e-mail"
              autoComplete="email"
              name="email"
              error={highlight && (email ? (email !== "" && !isEmailValid()): true)}
              helperText={(email.length > 1 && !isEmailValid() ? "Adresse email invalide":"")}
              value={email}
              onChange={event => setEmail(event.target.value)}
              required 
              fullWidth />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              autoComplete="tel-national"
              name="phone-num"
              label="Téléphone"
              value={telephone}
              onChange={event => setTelephone(event.target.value)}
              error={highlight && (telephone ? false : true)}
              required
              fullWidth />
          </Grid>
        </Grid>

      </form>
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          //disabled={!valid()}
          startIcon={<NavigateNextIcon/>}
          onClick={() => {
            if(valid()) {
              console.log(info)
              next(info)
            }
            else {
              setHighLight(true)
            }
          }}// handleSubmit(() => next(info), valid())}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}

const postalCodes = [
  "01", "38", "60", "97", "61", "09", "12", "26", "45", "51", "63", "30", "89",
  "73", "23", "53", "76", "47", "59", "58", "46", "72", "35", "14", "78", "77",
  "88", "68", "29", "65", "57", "15", "56", "81", "04", "18", "70", "84", "37",
  "90", "06", "48", "13", "16", "34", "20", "10", "42", "07", "86", "79", "66",
  "02", "64", "69", "22", "49", "11", "19", "93", "98", "91", "03", "87", "92",
  "28", "44", "32", "85", "83", "41", "39", "31", "36", "05", "71", "67", "40",
  "24", "27", "52", "08", "17", "94", "80", "33", "54", "43", "82", "55", "50",
  "75", "95", "62", "74", "25", "21"]

const excludedPostalCodes = [
  "974",  // Réunion
  "987", // Polynésie Française
  "988", // Nouvelle Calédonie
  "98000" // Monaco
]
