import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/fr";

type DatePickProps = {
  label?: string;
  value: string;
  onChange: any;
};
export default function DatePick(props: DatePickProps) {
  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs(props.value, "DD/MM/YYYY")
  );
  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (value) props.onChange(value.format("DD/MM/YYYY"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
      <DatePicker
        label={props.label}
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={false}
            inputProps={{
              ...params.inputProps,
              placeholder: "JJ/MM/AAAA",
            }}
            variant="standard"
          />
        )}
      />
    </LocalizationProvider>
  );
}
