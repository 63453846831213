import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAPI } from "../../api";
import { CompanyInfo, PricesList } from "../../components/Proposal";
import {
  currentStatus,
  DocumentItem,
  finalStatus,
  Proposal as ProposalType,
  ProposalStatus,
  Questionnaire,
  Role,
  statusUpdate,
} from "../../components/types";
import SanteSrc from "../../assets/logo-sante/LOGO-SVA-RVB.svg";
import {
  Alert,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SendIcon from "@material-ui/icons/Send";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import AddIcon from "@material-ui/icons/Add";
import { Downloadable } from "../../components/Files";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import FileUpload from "../../components/FileUpload";
import Popover from "@mui/material/Popover";
import Skeleton from "@mui/material/Skeleton";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "50%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const emptyNotif = { txt: "", show: false, severity: "" };

export function ProposalView(props: { userRoles: Role[] }) {
  const { id } = useParams() as { id: string };
  const [proposal, setProposal] = useState(
    undefined as ProposalType | undefined
  );

  const [docsCom, setDocsCom] = useState([] as DocumentItem[]);
  const [docsProj, setDocsProj] = useState([] as DocumentItem[]);
  const [docsConform, setDocsConform] = useState([] as DocumentItem[]);
  const [docsContract, setDocsContract] = useState([] as DocumentItem[]);

  const [filesToUpload, setFilesToUpload] = useState([] as any[]);
  const [companyDocs, setCompanyDocs] = useState([] as any[]);
  const [reqRetries, setReqRetries] = useState(0 as number);

  //const [propDocsLen, setPropDocsLen] = useState(0 as number)
  //const [contractDocsLen, setContractDocsLen] = useState(0 as number)
  const [notif, setNotif] = useState(emptyNotif);
  //  const [refreshCompanyDocs, setRefreshCompanyDocs] = useState(false)

  const { getProposal, getDocumentList, uploadFiles, removeFiles } = useAPI();

  const refreshProposal = () => {
    getProposal(id)()
      .then(setProposal)
      .catch(() => setProposal(undefined));
    setReqRetries(0);
  };
  /*   useEffect(()=> {
      if(proposal) {
        setContractDocsLen(proposal.contractDocsLength)
        setPropDocsLen(proposal.propDocsLength)
      }
    }, [proposal]) */

  useEffect(refreshProposal, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDocs = (docsList: any) => {
    setReqRetries(reqRetries + 1);
    setDocsCom(
      docsList
        .filter(function (el: any) {
          return el.doc_category === "docs_commerciaux";
        }) //@ts-ignore
        .sort((a, b) => a.key < b.key)
    );
    setDocsProj(
      docsList
        .filter(function (el: any) {
          return el.doc_category === "docs_projet";
        }) //@ts-ignore
        .sort((a, b) => a.key < b.key)
    );
    setDocsConform(
      docsList
        .filter(function (el: any) {
          return el.doc_category === "docs_conform";
        }) //@ts-ignore
        .sort((a, b) => a.key < b.key)
    );
    setCompanyDocs(
      docsList
        .filter(function (el: any) {
          return el.doc_category === "uploaded_docs";
        }) //@ts-ignore
        .sort((a, b) => a.key < b.key)
    );
    setDocsContract(
      docsList
        .filter(function (el: any) {
          return el.doc_category === "docs_contract";
        }) //@ts-ignore
        .sort((a, b) => a.key < b.key)
    );

    //setContractDocs(docsList)
    //setRefreshCompanyDocs(false)
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (reqRetries <= 10) {
        getDocumentList(id)().then(handleDocs);
      }
    }, 7000);
    /*@ts-ignore*/
    if (reqRetries > 10) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [reqRetries]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileUploadError = () => {
    // Do something...
  };

  useEffect(() => {
    if (!(filesToUpload === undefined || filesToUpload.length === 0)) {
      uploadFiles({ files: filesToUpload, proposalId: proposal?.proposalId });
      setNotif({ severity: "success", txt: "Succès de l'envoi", show: true });
      setTimeout(() => getDocumentList(id)().then(handleDocs), 3000);
      setFilesToUpload([]);
    }
  }, [filesToUpload]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilesChange = (files: any) => {
    setFilesToUpload(files);
  };

  const handleNotif = () => {
    setTimeout(() => setNotif(emptyNotif), 3000);
    //@ts-ignore
    return (
      <Popover
        open={notif.show}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: window.innerHeight / 2,
          left: window.innerWidth / 2,
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        {/*
        //@ts-ignore*/}
        <Alert severity={notif.severity}>{notif.txt}</Alert>
      </Popover>
    );
  };

  const handleRemoveFile = (index: number) => {
    removeFiles({
      files: [companyDocs[index]],
      proposalId: proposal?.proposalId,
    });
    const tmp = [...companyDocs];
    tmp.splice(index, 1);
    setCompanyDocs(tmp);
  };
  //@ts-ignore
  const RemoveIcon = ({ readOnly, index }) => {
    if (readOnly) return null;
    return (
      <Fragment>
        <IconButton
          sx={{ padding: "0px" }}
          onClick={() => handleRemoveFile(index)}
        >
          <CloseIcon />
        </IconButton>
      </Fragment>
    );
  };
  if (proposal) {
    return (
      <Container>
        <div className="transparent-card">
          <Box>
            <Grid container>
              <Grid item md={3}>
                <CompanyInfo info={proposal.company} />
              </Grid>
              <Grid item md={6}>
                <ProposalTimeline
                  proposal={proposal}
                  onChange={refreshProposal}
                  userRoles={props.userRoles}
                  companyDocs={companyDocs}
                />
              </Grid>
              <Grid item md={3}>
                <Box sx={{ textAlign: "right" }}>
                  <img
                    style={{ marginLeft: "auto" }}
                    src={SanteSrc}
                    height="80px"
                    alt="Santé"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: "inline-block" }}>
              <Paper elevation={3}>
                <p style={{ padding: "10px" }}>
                  Liste des documents à retourner à ASTIMM pour l’émission du
                  contrat : <br />
                  - Bulletin de souscription <br />
                  - KBIS de moins de 3 mois <br />
                  - CNI du dirigeant signataire <br />- Les Conditions
                  Particulières avec date d'effet signées par votre client.
                  Celles-ci seront dans la section "Documents Contractuels"
                  générées après le clic sur "demander le contrat".
                </p>
              </Paper>
            </Box>
            <Box paddingBottom={3}>
              <FileUpload
                multiFile={true}
                disabled={false}
                title="Envoyez les documents de votre client"
                header="Déposez"
                leftLabel="ou"
                rightLabel="pour rechercher un fichier (pdf, jpeg, jpg max 10Mo) sur votre ordinateur "
                buttonLabel="cliquez ici"
                // buttonRemoveLabel="Effacer tous"
                maxFileSize={10}
                maxUploadFiles={5}
                maxFilesContainerHeight={357}
                errorSizeMessage={"erreur lors de l'envoi"}
                allowedExtensions={["jpg", "pdf", "jpeg"]}
                onFilesChange={handleFilesChange}
                onError={handleFileUploadError}
                // bannerProps={{ elevation: 0, variant: "outlined" }}
                //containerProps={{ elevation: 0, variant: "outlined" }}
              />
              {notif.show ? handleNotif() : null}
            </Box>
            <Box mt={2} mb={2}>
              {reqRetries <= 10 ? (
                <span>Création ou récupération des documents en cours:</span>
              ) : null}
              {reqRetries <= 10 ? (
                <LinearProgressWithLabel value={(reqRetries / 10) * 100} />
              ) : null}
            </Box>

            <Box>
              <h3>Documents échangés avec Astimm</h3>
              <ul>
                {companyDocs.length !== 0 ? (
                  companyDocs.map((e, index) => (
                    <li>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Downloadable item={e} />
                        {/*
                    //@ts-ignore*/}
                        <RemoveIcon
                          readOnly={proposal.docsReadOnly}
                          index={index}
                        />
                      </Box>
                    </li>
                  ))
                ) : (
                  <span>
                    Vous n'avez pas encore envoyé ni reçu de document.
                  </span>
                )}
              </ul>
            </Box>
            <Box>
              <h3>Documents commerciaux</h3>
              <ul>
                {docsCom.length ? (
                  docsCom.map((e) => (
                    <li key={e.name} style={{ width: "fit-content" }}>
                      <Downloadable item={e} />
                    </li>
                  ))
                ) : (
                  <Skeleton animation="wave" height={10} width="40%" />
                )}
              </ul>
            </Box>
            <Box>
              <h3>Documents projet</h3>
              {/*               {proposal.status[0].tag === 'open' ? <span>Les documents seront disponibles lorsque vous demanderez le contrat.</span> : null}
              {proposal.status[0].tag === 'pending' && contractDocs.length < contractDocsLen ? <span>Création ou récupération en cours:</span> : null}
              {proposal.status[0].tag === 'pending' && contractDocs.length < contractDocsLen ? <LinearProgressWithLabel value={contractDocs.length/contractDocsLen * 100} /> : null} */}
              <ul>
                {docsProj.length ? (
                  docsProj.map((e) => (
                    <li key={e.name} style={{ width: "fit-content" }}>
                      <Downloadable item={e} />
                    </li>
                  ))
                ) : (
                  <Skeleton animation="wave" height={10} width="40%" />
                )}
              </ul>
            </Box>
            <Box>
              <h3>Documents conformité</h3>
              {/*               {proposal.status[0].tag === 'open' ? <span>Les documents seront disponibles lorsque vous demanderez le contrat.</span> : null}
              {proposal.status[0].tag === 'pending' && contractDocs.length < contractDocsLen ? <span>Création ou récupération en cours:</span> : null}
              {proposal.status[0].tag === 'pending' && contractDocs.length < contractDocsLen ? <LinearProgressWithLabel value={contractDocs.length/contractDocsLen * 100} /> : null} */}
              <ul>
                {docsConform.length ? (
                  docsConform.map((e) => (
                    <li key={e.name} style={{ width: "fit-content" }}>
                      <Downloadable item={e} />
                    </li>
                  ))
                ) : (
                  <Skeleton animation="wave" height={10} width="50%" />
                )}
              </ul>
            </Box>
            <Box>
              <h3>Documents contractuels</h3>
              {/*               {proposal.status[0].tag === 'open' ? <span>Les documents seront disponibles lorsque vous demanderez le contrat.</span> : null}
              {proposal.status[0].tag === 'pending' && contractDocs.length < contractDocsLen ? <span>Création ou récupération en cours:</span> : null}
              {proposal.status[0].tag === 'pending' && contractDocs.length < contractDocsLen ? <LinearProgressWithLabel value={contractDocs.length/contractDocsLen * 100} /> : null} */}
              <ul>
                {docsContract.length ? (
                  docsContract.map((e) => (
                    <li key={e.name} style={{ width: "fit-content" }}>
                      <Downloadable item={e} />
                    </li>
                  ))
                ) : (
                  <Skeleton animation="wave" height={10} width="40%" />
                )}
              </ul>
            </Box>

            <Box mt={5}>
              <PricesList prices={proposal.prices} />
            </Box>

            {!finalStatus(proposal) ? (
              <Box mt={5}>
                <CancelContract
                  proposal={proposal}
                  onChange={refreshProposal}
                />
              </Box>
            ) : null}
          </Box>
        </div>
      </Container>
    );
  } else {
    return null;
  }
}

export function NextStepAction({
  userRoles,
  proposal,
  onChange,
  companyDocs,
}: StatusChangeProps) {
  let s = currentStatus(proposal).tag;
  if (s === "open") {
    return <DemandContract proposal={proposal} onChange={onChange} />;
  } else if (s === "pending") {
    return (
      <SubmitDocs
        proposal={proposal}
        onChange={onChange}
        companyDocs={companyDocs}
      />
    );
  } else if (s === "checking_docs") {
    return (
      <AdminCheckDocsResult
        proposal={proposal}
        onChange={onChange}
        userRoles={userRoles}
      />
    );
  } else if (s === "invalid_docs") {
    return (
      <SubmitDocs
        proposal={proposal}
        onChange={onChange}
        companyDocs={companyDocs}
      />
    );
  } else {
    return <FinalProposalStatus proposal={proposal} />;
  }
}

type StatusChangeProps = {
  companyDocs?: DocumentItem[];
  userRoles?: Role[];
  proposal: ProposalType;
  onChange: () => void;
};

export function DemandContract({ proposal, onChange }: StatusChangeProps) {
  const { requestContract } = useAPI();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  //@ts-ignore
  const [dateValue, setDateValue] = useState<MaterialUiPickersDate>(minDay());

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequest = () => {
    if (dateValue) {
      setError(false);
      //@ts-ignore
      requestContract({
        proposalId: proposal.proposalId,
        starts_at: dateValue.format("YYYY-MM-DD"),
      })
        .then(() => {
          handleClose();
          onChange();
        })
        // TODO handle error reposonse
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    }
  };
  return (
    <Fragment>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Demander le contrat
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Quelle est la date d'effet souhaitée ?</DialogTitle>
        <DialogContent>
          <DatePicker
            views={["year", "month"]}
            autoOk
            // disableToolbar
            minDate={minDay()}
            maxDate={maxDay()}
            /*             {proposal.expirationDate ? moment(proposal.expirationDate, 'DD-MM-YYYY') : maxDay()}
             */
            variant="static"
            openTo="date"
            value={dateValue}
            //@ts-ignore
            onChange={(d) => setDateValue(d ? d.startOf("month") : null)}
          />
          {/*           {proposal.expirationDate ? 
          <Alert severity="warning">
            La date d'effet ne peut pas être ultérieure à la date limite de validité de la proposition: {proposal.expirationDate}.
          </Alert> : null} */}
          {error ? (
            <Alert severity="error">
              Erreur API Veuillez réessayer plus tard.
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => handleClose()}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            //@ts-ignore
            disabled={!(dateValue && dateValue >= minDay())}
            onClick={() => handleRequest()}
          >
            Confirmer la demande
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

function minDay() {
  const now = moment();
  return now.date() < 15
    ? now.startOf("month").startOf("month")
    : now.add(1, "month").startOf("month");
}

function maxDay() {
  const next = moment().add(1, "year");
  return next.endOf("year").startOf("month");
}

function SubmitDocs({ proposal, onChange, companyDocs }: StatusChangeProps) {
  const { requestCheckDocs } = useAPI();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleConfirm = () => {
    setError(false);
    requestCheckDocs(proposal.proposalId)
      .then(() => {
        setOpen(false);
        onChange();
      })
      // TODO handle error reposonse
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };
  const handleDisabled = () => {
    return companyDocs === undefined || companyDocs.length === 0 ? true : false;
  };
  //if(companyDocs === undefined || proposal === undefined) return null
  return (
    <Fragment>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Demander la validation des pièces et la signature du contrat.
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <b>
            Ceci déclenchera la vérification des pièces de votre client par
            notre gestionnaire et la signature du contrat par l' assureur.
          </b>
          <p>
            Veuillez vérifier que vous avez bien joint les documents nécessaires
            ainsi que les conditions particulières avec date d'effet signées par
            votre client disponibles dans section "Documents contractuels".
          </p>
          <p>
            Suite à cette demande vous ne pourrez plus modifier la proposition
            ainsi que les documents joints.
          </p>
          <p>
            Après vérification des pièces et signature par nos services vous
            serez informé par e-mail et trouverez le contrat signé dans la
            section "Documents échangés avec Astimm" ci-dessous.
          </p>
          {handleDisabled() ? (
            <p style={{ color: "red" }}>Aucun document ajouté !</p>
          ) : null}
          {error ? (
            <Alert severity="error">API error. Please try again later.</Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button
            color="primary"
            onClick={() => handleConfirm()}
            disabled={handleDisabled()}
          >
            Je confirme
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

function AdminCheckDocsResult({
  userRoles,
  proposal,
  onChange,
}: StatusChangeProps) {
  const { signContract, invalidateDocs } = useAPI();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [adminMessage, setAdminMessage] = React.useState("");

  const handleAdminMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdminMessage(event.target.value);
  };

  const handleConfirm = () => {
    setError(false);
    signContract(proposal.proposalId)
      .then(() => {
        setOpen(false);
        onChange();
      })
      // TODO handle error response
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  const handleRejectProposal = () => {
    setError(false);
    invalidateDocs({
      proposalId: proposal.proposalId,
      admin_message: adminMessage,
    })
      .then(() => {
        setOpen(false);
        onChange();
      })
      // TODO handle error reposonse
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  return userRoles?.includes("Admin") ? (
    <Fragment>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        <Typography variant="body1">
          <b>Répondre au courtier</b>
        </Typography>
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <p>
            Si le dossier est conforme, pensez à uploader le contrat signé par
            l'assureur et les pièces à retourner au courtier avant de cliquer
            sur 'Confirmer'.
          </p>
          <p>
            En cas de rejet, pensez à indiquer la raison du rejet en remplissant
            le champ ci-dessous avant de cliquer sur 'Rejeter'.
          </p>
          <TextField
            fullWidth
            id="standard-multiline-static"
            label="Votre message au courtier"
            multiline
            rows={4}
            placeholder="écrire ici"
            variant="outlined"
            onChange={handleAdminMessageChange}
          />
          {error ? (
            <Alert severity="error">API error. Please try again later.</Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button color="primary" onClick={() => handleConfirm()}>
            Confirmer
          </Button>
          <Button color="primary" onClick={() => handleRejectProposal()}>
            Rejeter
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  ) : null;
}

function CancelContract({ proposal, onChange }: StatusChangeProps) {
  const { cancelProposal } = useAPI();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleConfirm = () => {
    setError(false);
    cancelProposal(proposal.proposalId)
      .then(() => {
        setOpen(false);
        onChange();
      })
      // TODO handle error reposonse
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  return (
    <Fragment>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        startIcon={<CancelIcon />}
        onClick={() => setOpen(true)}
      >
        Proposition sans suite
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          Cela annulera le contrat, vous ne pourrez plus effectuer de
          modifications ni le récupérer.
          {error ? (
            <Alert severity="error">API error. Please try again later.</Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button color="primary" onClick={() => handleConfirm()}>
            Je confirme
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export function FinalProposalStatus({
  proposal,
}: {
  proposal: ProposalType | Questionnaire;
}) {
  let s = currentStatus(proposal).tag;
  if (s === "signed") {
    return (
      <Chip label="Contrat signé" icon={<CheckCircleIcon />} color="primary" />
    );
  } else if (s === "canceled") {
    return (
      <Chip label="Contrat annulé" icon={<CancelIcon />} color="secondary" />
    );
  } else {
    return null;
  }
}

const useTimelineStyles = makeStyles((theme) => ({
  primaryTail: {
    backgroundColor: theme.palette.primary.main,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function ProposalTimeline({
  userRoles,
  proposal,
  onChange,
  companyDocs,
}: StatusChangeProps) {
  const classes = useTimelineStyles();
  return (
    <Timeline>
      {!finalStatus(proposal) ? (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary">
              <AddIcon color="primary" />
            </TimelineDot>
            <TimelineConnector className={classes.primaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Box style={{ position: "relative", bottom: "-4px" }}>
              <NextStepAction
                proposal={proposal}
                onChange={onChange}
                userRoles={userRoles}
                companyDocs={companyDocs}
              />
            </Box>
          </TimelineContent>
        </TimelineItem>
      ) : null}
      {proposal.status.map((s) => (
        <TimelineStatusItem
          status={s}
          brokerEmail={proposal.brokerEmail}
          brokerFullName={proposal.brokerFullName}
        />
      ))}
    </Timeline>
  );
}

function timelineStatusUpdate(date: Date) {
  return (
    <TimelineOppositeContent>
      <Typography variant="caption" color="textSecondary">
        {date.toLocaleDateString("fr-FR")}
      </Typography>
      <Typography
        style={{ marginLeft: "4px" }}
        variant="caption"
        color="textSecondary"
      >
        {date.toLocaleTimeString("fr-FR", {
          hour: "numeric",
          minute: "2-digit",
        })}
      </Typography>
    </TimelineOppositeContent>
  );
}

function TimelineStatusItem({
  status,
  brokerFullName,
}: {
  status: ProposalStatus;
  brokerEmail: string;
  brokerFullName: string;
}) {
  const classes = useTimelineStyles();
  const tag = status.tag;
  const date = new Date(statusUpdate(status));

  if (tag === "open") {
    return (
      <TimelineItem>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <FiberNewIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} style={{ padding: "5px 3px" }}>
            <Box>
              <Typography variant="body2" gutterBottom>
                <b>Création</b>
              </Typography>
              <Typography variant="caption" gutterBottom>
                <p>Proposition réalisée par {brokerFullName}.</p>
              </Typography>
            </Box>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "pending") {
    return (
      <TimelineItem>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <HourglassEmptyIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} style={{ padding: "5px 3px" }}>
            <Typography variant="body2">
              <b>Contrat demandé, en attente des documents client</b>
            </Typography>
            <Typography variant="caption">
              Commence le{" "}
              {new Date((status as any).starts_at).toLocaleDateString("fr-FR")}
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "checking_docs") {
    return (
      <TimelineItem>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <FiberNewIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} style={{ padding: "5px 3px" }}>
            <Typography variant="body2">
              <b>Validation du dossier par l'assureur en cours</b>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "invalid_docs") {
    return (
      <TimelineItem>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <FiberNewIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} style={{ padding: "5px 3px" }}>
            <Typography variant="body2">
              <b>Echec de la validation</b>
            </Typography>
            <Typography variant="caption">
              {/*
              //@ts-ignore*/}
              <p>{status.admin_message}</p>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "signed") {
    return (
      <TimelineItem>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot color="primary">
            <CheckCircleIcon />
          </TimelineDot>
          <TimelineConnector className={classes.primaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Chip
            style={{ position: "relative", bottom: "-2px" }}
            label="Signé"
            // icon={<CheckCircleIcon/>}
            color="primary"
          />
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "canceled") {
    return (
      <TimelineItem>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <CancelIcon />
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Chip
            style={{ position: "relative", bottom: "-2px" }}
            label="Proposition annulée"
            // icon={<CancelIcon/>}
            color="secondary"
          />
        </TimelineContent>
      </TimelineItem>
    );
  } else {
    return null;
  }
}
