import React from 'react';
import "./Auth.sass"
import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";

import Avatar from '@material-ui/core/Avatar';
import { Box, Button, createTheme, Menu, MenuItem, ThemeProvider } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <Button className="button is-primary"
                 onClick={() => loginWithRedirect()}>Log In</Button>;
};

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button className="button is-primary" onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </Button>
  );
};

export const Profile = () => {
  // const { name, avatar, isLoading, isAuthorized } = useAuthentication()
  const { logout, user, isLoading, isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => logout({ returnTo: window.location.origin })

  if (isLoading) {
    return <div>Loading ...</div>;
  }

	if (isAuthenticated && user) {
		return (
      <div className="user-profile-block">
        <Box className="user-profile" onClick={handleClick}>
          <Avatar className="large" src={user.picture} alt={user.name}/>
          <div className="user-name-block">
            <div className="user-name">{user.name}</div>
            <div className="user-welcome">
              Bienvenue sur votre espace partenaire
            </div>
          </div>
        </Box>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profil</MenuItem>
            <MenuItem onClick={handleClose}>Mon compte</MenuItem>
            <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
          </Menu>
        <Help/>
      </div>
		)
	} else {
		return <LoginButton/>
	}
};

function Help() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#37D355"
      }
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" className="assistance" color="primary" startIcon={<CallIcon/>}>
        Assistance
      </Button>
    </ThemeProvider>
  )
}

export type AccessTokenF = (options?: GetTokenSilentlyOptions | undefined) => Promise<string>