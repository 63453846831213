import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridRowModel,
  GridValidRowModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { CostsOperation } from "./types";

const colOne = [
  "Prix de vente (dation incluse) (A)",
  "Prix du terrain + frais de notaire + frais d’agence",
  "Taxes",
  "Coût de constructions (dont VRD)",
  "Honoraires techniques",
  "Frais de commercialisation",
  "Frais de gestion",
  "Autres frais",
  "Total prix de revient (B)",
  "Marge Nette (A – B)",
];

const initialRows = Array(10)
  .fill(0)
  .map((_, idx) => ({
    id: idx + 1,
    description: colOne[idx],
    HT: null,
    TTC: null,
    isNew: false,
  }));

export default function OpInfoGrid({
  onEdit,
  initRows,
}: {
  onEdit: React.Dispatch<React.SetStateAction<CostsOperation[] | null>>;
  initRows: CostsOperation[] | null;
}) {
  const [rows, setRows] = React.useState(initRows || initialRows);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => onEdit(rows), [rows]);

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    //@ts-ignore
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns = [
    {
      field: "description",
      headerName: "Description",
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      flex: 0.4,
      sortable: false,
    },
    {
      field: "HT",
      headerName: "HT",
      type: "number",
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      flex: 0.3,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      sortable: false,
    },
    {
      field: "TTC",
      headerName: "TTC",
      type: "number",
      editable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      flex: 0.3,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      sortable: false,
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows as readonly GridValidRowModel[]}
        columns={columns}
        editMode="row"
        processRowUpdate={processRowUpdate}
        componentsProps={{
          //toolbar: { setRows, setRowModesModel },
          baseTextField: {
            inputProps: {
              autocomplete: "off",
              min: 0,
            },
          },
          baseFormControl: {
            autocomplete: "off",
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooter={true}
        autoHeight
        showCellRightBorder
        showColumnRightBorder
      />
    </Box>
  );
}
