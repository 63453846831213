import {
  AppBar,
  Card,
  CardContent,
  createStyles,
  createTheme,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  SvgIcon,
  Theme,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import "./App.sass";
import { frFR } from "@mui/x-data-grid";
import { frFR as pickersFrFr } from "@mui/x-date-pickers";
import { frFR as coreFrFr } from "@mui/material/locale";
import { Profile } from "./components/Auth";
import {
  Switch,
  Route,
  Link,
  NavLink,
  useLocation,
  Redirect,
} from "react-router-dom";
import { Sante } from "./pages/Broker/Sante";
import { GFA } from "./pages/Broker/gfa";
import LogoSrc from "./assets/logo-desktop@2x.png";
import { Home } from "./pages/Broker/Home";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Wallet } from "./pages/Broker/Wallet";
import { WalletGfa } from "./pages/Broker/WalletGfa";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import frLocaleConfig from "./_utils/frLocaleConfig/frLocaleConfig";
import { useAPI } from "./api";
import { Permission, Role } from "./components/types";
import { AdminDashboard } from "./pages/Admin/AdminDashboard";
import { UserManagement } from "./pages/Admin/UserManagement";
import { Kits } from "./pages/Broker/Kits";
import { ProposalView } from "./pages/Broker/ProposalView";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import { FAQ, FAQSection } from "./pages/Broker/FAQ";
import AppsIcon from "@material-ui/icons/Apps";
import ListIcon from "@material-ui/icons/List";
import AssignmentReturnedOutlinedIcon from "@material-ui/icons/AssignmentReturnedOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";

const drawerWidth = 256;

const theme = createTheme(
  {
    typography: {
      fontFamily: "Roboto, sans-serif",
      h1: {
        fontSize: 42,
      },
      h2: {
        fontSize: 36,
      },
      h3: {
        fontSize: 24,
      },
      h4: {
        fontSize: 16,
        fontWeight: 500,
      },
      h5: {
        fontSize: 16,
        fontWeight: 400,
      },
    },
    palette: {
      // background: { default:  "#ffffff33", paper: "#ffffffff" },
      primary: {
        main: "#0089ff",
      },
    },
    overrides: {
      MuiCard: {
        root: {
          borderRadius: 10,
        },
      },
      MuiTypography: {
        h1: {
          // fontSize: "16pt",
          marginBottom: "16pt",
        },
      },
    },
  },
  frFR,
  pickersFrFr,
  coreFrFr
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 10,
    },
    menuLink: {
      textDecoration: "none",
      color: "#000000",
      fontWeight: 300,
    },
    menuLinkActive: {
      color: "#0089ff", // theme.palette.primary.main
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      paddingTop: "80px",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      paddingTop: "80px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: "50px",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 10,
      padding: theme.spacing(0),
      minWidth: "100%",
    },
    title: {
      flexGrow: 1,
    },
    icon: {
      marginLeft: "250px",
    },
    logo: {
      position: "absolute",
      left: "-20px",
    },
    menuLabel: {
      marginLeft: "15px",
    },
    showHide: {
      marginBottom: "30px",
      // position: "relative",
      // bottom: "-50vh",
      // marginTop: "50vh",
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'flex-end',
      // padding: theme.spacing(0, 1),
    },
    menuDrawer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "space-between",
    },
  })
);

const menuItems = [
  {
    label: "Tableau de Bord Statistique",
    icon: BarChartOutlinedIcon,
    route: "/board-statistiques",
    roles: ["Admin", "GFA admin"],
  },
  {
    label: "Gestion des Utilisateurs",
    icon: GroupOutlinedIcon,
    route: "/gestion-des-utilisateurs",
    roles: ["Admin"],
  },
  {
    label: "Gestion des Utilisateurs GFA",
    icon: GroupOutlinedIcon,
    route: "/gestion-des-utilisateurs",
    roles: ["GFA admin"],
  },
  {
    label: "Gestions des Contrats",
    icon: DescriptionOutlinedIcon,
    route: "/gestions-des-contrats",
    roles: ["Admin"],
  },
  {
    label: "Gestions des Contrats GFA",
    icon: DescriptionOutlinedIcon,
    route: "/gestions-des-contrats",
    roles: ["GFA admin"],
  },
  {
    label: "Tarificateur SVA",
    icon: AppsIcon,
    route: "/sante",
    roles: ["Admin", "Broker"],
  },
  {
    label: "Formulaire GFA",
    icon: AppsIcon,
    route: "/gfa",
    roles: ["GFA broker", "Admin", "GFA admin"],
  },
  {
    label: "Portefeuille",
    icon: ListIcon,
    route: "/portefeuille",
    roles: ["Admin", "Broker"],
  },
  {
    label: "Portefeuille GFA",
    icon: ListIcon,
    route: "/portefeuille-gfa",
    roles: ["Admin", "GFA broker", "GFA admin"],
  },
  {
    label: "Kits de distribution",
    icon: AssignmentReturnedOutlinedIcon,
    route: "/kits-de-distribution",
    roles: ["Admin", "Broker"],
  },
  /*   {
    label: "Kits de distribution GFA",
    icon: AssignmentReturnedOutlinedIcon,
    route: "/kits-de-distribution",
    roles: ["Admin", "GFA broker", "GFA admin"],
  }, */
  {
    label: "FAQ",
    icon: LiveHelpOutlinedIcon,
    route: "/faq",
    roles: ["Admin", "Broker"],
  },
  /*   {
    label: "FAQ GFA",
    icon: LiveHelpOutlinedIcon,
    route: "/faq",
    roles: ["Admin", "GFA broker", "GFA admin"],
  }, */
];

export function ThemedApp() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

function App() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [userRole, setUserRole] = useState<Role[]>([]);
  const location = useLocation();
  moment.locale("fr", frLocaleConfig);

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { getPermissions } = useAPI();

  useEffect(() => {
    if (location.pathname !== "/") {
      setOpen(false);
    }
    if (isAuthenticated) {
      getPermissions().then((resp: Permission[]) => {
        setUserRole(defineRole(resp));
      });
    }
  }, [location, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <div>Chargement en cours...</div>;
  } else {
    if (!isAuthenticated) {
      loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
      return <div>Veuillez vous connecter...</div>;
    } else {
      return (
        <div>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <div className={classes.logo}>
                <Logo />
              </div>
              <div className={classes.icon} />
              <Typography variant="h6" className={classes.title}>
                <Switch>
                  <Route exact path="/">
                    Accueil
                  </Route>
                  <Route path="/sante">Tarificateurs</Route>
                  <Route path="/gfa">GFA</Route>
                  <Route path="/portefeuille">Portefeuille</Route>
                  <Route path="/kits-de-distribution">
                    Kits de distribution
                  </Route>
                  <Route exact path="/faq">
                    FAQ
                  </Route>
                  <Route path="/faq/:section">FAQ</Route>
                  <Route path="/board-statistiques">Statistiques</Route>
                  <Route path="/gestion-des-utilisateurs">
                    Gestion des utilisateurs
                  </Route>
                  <Route path="/gestions-des-contrats">
                    Gestions des contrats
                  </Route>
                  <Route path="/proposal/:id">Proposition</Route>
                </Switch>
              </Typography>
              <Profile />
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            anchor="left"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.menuDrawer}>
              <div className={classes.showHide}>
                <IconButton onClick={() => setOpen((f) => !f)}>
                  {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </div>
              <List>
                {menuItems.map(({ label, icon, route, roles }) => (
                  <NavLink
                    hidden={!isAllowed(roles, userRole as Role[])}
                    key={route}
                    className={classes.menuLink}
                    to={route}
                    activeClassName={classes.menuLinkActive}
                  >
                    <ListItem button>
                      <SvgIcon component={icon} />
                      <ListItemText
                        className={classes.menuLabel}
                        primary={label}
                        disableTypography
                      />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </div>
          </Drawer>

          <main>
            <div />
            <Switch>
              <Route exact path="/">
                <Home roles={userRole} />
              </Route>
              <Route path="/sante">
                <Sante />
              </Route>
              <Route exact path="/gfa/:idGFA">
                <GFA userRoles={userRole} />
              </Route>
              <Route exact path="/gfa">
                <GFA userRoles={userRole} />
              </Route>
              <Route path="/portefeuille">
                <Wallet />
              </Route>
              <Route path="/portefeuille-gfa">
                <WalletGfa />
              </Route>
              <Route path="/kits-de-distribution">
                <Kits />
              </Route>
              <Route exact path="/faq">
                <FAQ />
              </Route>
              <Route path="/faq/:section">
                <FAQSection />
              </Route>
              <Route path="/board-statistiques">
                {userRole.includes("Admin") ? (
                  <AdminDashboard />
                ) : (
                  <Redirect to="/" />
                )}
              </Route>
              <Route path="/gestion-des-utilisateurs">
                {userRole.includes("Admin") ? (
                  <UserManagement />
                ) : (
                  <Redirect to="/" />
                )}
              </Route>
              <Route path="/gestions-des-contrats">
                <Card>
                  <CardContent>Bientôt disponible</CardContent>
                </Card>
                {/* {userRole === 'Admin' ?
                                    <ContractsManagement/>
                                    :
                                    <Redirect to="/"/>} */}
              </Route>
              <Route path="/proposal/:id">
                <ProposalView userRoles={userRole} />
              </Route>
            </Switch>
          </main>
        </div>
      );
    }
  }
}

// function NotVerified () {
//   return  (
//     <div className="modal is-active">
//       <div className="modal-background"></div>
//       <div className="modal-content">
//         <div className="box">
//           <div className="mb-5">You are not authorized to use Digital Broker app.</div>
//           <LogoutButton/>
//         </div>
//       </div>
//     </div>
//   )
// }

function Logo() {
  return (
    <Link to="/">
      <img src={LogoSrc} height="91px" alt="Astimm powered by Gestassur" />
    </Link>
  );
}

function defineRole(permissions: Permission[]): Role[] {
  const adminUniquePermission: Permission = "read:any:proposal";
  const roles: Role[] = [];

  if (permissions.find((permission) => permission === adminUniquePermission)) {
    roles.push("Admin");
  }
  if (permissions.find((permission) => permission === "admin:gfa")) {
    roles.push("GFA admin");
  }
  if (permissions.find((permission) => permission === "access:product:gfa")) {
    roles.push("GFA broker");
  }
  if (permissions.find((permission) => permission === "create:own:proposal")) {
    roles.push("Broker");
  }
  console.log(roles);

  return roles;
}

export const isAllowed = (rolesNeeded: string[], userRoles: Role[]) => {
  return rolesNeeded.some((val) => userRoles.includes(val as Role));
};

export default App;
