import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useAPI } from "../api";
import { StepParams } from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

type OptionSereniteProps = { size: number } & StepParams<boolean | null>;

export function OptionSerenite({ size, value, next }: OptionSereniteProps) {
  const { getSerenite } = useAPI();
  const [serenitePrice, setSerenitePrice] = useState("");
  const [serenite, setSerenite] = useState(value);
  const [answer, setAnswer] = useState(
    value === null ? "" : value ? "yes" : "no"
  );

  useEffect(() => {
    getSerenite({ size })
      .then(({ price }) => setSerenitePrice(() => price))
      .catch(() => setSerenitePrice(() => ""));
    // eslint-disable-next-line
  }, [size]);

  // eslint-disable-next-line
  useEffect(() => setSerenite(answer === "yes"), [answer]);

  return (
    <Box>
      <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6}>
          <Box lineHeight={1.4} mb={2}>
            L’entreprise souhaite-t-elle être accompagnée pour la mise en
            conformité vis-à-vis de ses obligations légales et sociales pour{" "}
            <b>{serenitePrice} €</b> TTC par an (TVA&nbsp;20%)?
          </Box>
          {/* </Grid>
        <Grid item xs={4} md={1}> */}
          <FormControl>
            <Select
              value={answer}
              displayEmpty
              onChange={(event) => setAnswer(event.target.value as string)}
            >
              <MenuItem value="" disabled>
                Choisir
              </MenuItem>
              <MenuItem value="yes">Oui</MenuItem>
              <MenuItem value="no">Non</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={3} style={{ maxWidth: "70ex" }}>
        <Typography variant="body2">
          Offre Sérénité Dirigeant permet au dirigeant de répondre aux
          obligations légales de l’entreprise en matière de gestion des risques
          professionnels et de prévention des risques psycho-sociaux grâce à un
          dispositif complet de services et d’accompagnement.
        </Typography>
      </Box>

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={answer === ""}
          startIcon={<NavigateNextIcon />}
          onClick={() => next(serenite)}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}
