import { CodeType } from "../components/types";

export const codeAPEList: CodeType[] = [
  {
    "code": "0111Z",
    "description": "Culture de c\u00e9r\u00e9ales (\u00e0 l'exception du riz), de l\u00e9gumineuses et de graines ol\u00e9agineuses"
  },
  {
    "code": "0112Z",
    "description": "Culture du riz"
  },
  {
    "code": "0113Z",
    "description": "Culture de l\u00e9gumes, de melons, de racines et de tubercules"
  },
  {
    "code": "0114Z",
    "description": "Culture de la canne \u00e0 sucre"
  },
  {
    "code": "0115Z",
    "description": "Culture du tabac"
  },
  {
    "code": "0116Z",
    "description": "Culture de plantes \u00e0 fibres"
  },
  {
    "code": "0119Z",
    "description": "Autres cultures non permanentes"
  },
  {
    "code": "0121Z",
    "description": "Culture de la vigne"
  },
  {
    "code": "0122Z",
    "description": "Culture de fruits tropicaux et subtropicaux"
  },
  {
    "code": "0123Z",
    "description": "Culture d'agrumes"
  },
  {
    "code": "0124Z",
    "description": "Culture de fruits \u00e0 p\u00e9pins et \u00e0 noyau"
  },
  {
    "code": "0125Z",
    "description": "Culture d'autres fruits d'arbres ou d'arbustes et de fruits \u00e0 coque"
  },
  {
    "code": "0126Z",
    "description": "Culture de fruits ol\u00e9agineux"
  },
  {
    "code": "0127Z",
    "description": "Culture de plantes \u00e0 boissons"
  },
  {
    "code": "0128Z",
    "description": "Culture de plantes \u00e0 \u00e9pices, aromatiques, m\u00e9dicinales et pharmaceutiques"
  },
  {
    "code": "0129Z",
    "description": "Autres cultures permanentes"
  },
  {
    "code": "0130Z",
    "description": "Reproduction de plantes"
  },
  {
    "code": "0141Z",
    "description": "\u00c9levage de vaches laiti\u00e8res"
  },
  {
    "code": "0142Z",
    "description": "\u00c9levage d'autres bovins et de buffles"
  },
  {
    "code": "0143Z",
    "description": "\u00c9levage de chevaux et d'autres \u00e9quid\u00e9s"
  },
  {
    "code": "0144Z",
    "description": "\u00c9levage de chameaux et d'autres cam\u00e9lid\u00e9s"
  },
  {
    "code": "0145Z",
    "description": "\u00c9levage d'ovins et de caprins"
  },
  {
    "code": "0146Z",
    "description": "\u00c9levage de porcins"
  },
  {
    "code": "0147Z",
    "description": "\u00c9levage de volailles"
  },
  {
    "code": "0149Z",
    "description": "\u00c9levage d'autres animaux"
  },
  {
    "code": "0150Z",
    "description": "Culture et \u00e9levage associ\u00e9s"
  },
  {
    "code": "0161Z",
    "description": "Activit\u00e9s de soutien aux cultures"
  },
  {
    "code": "0162Z",
    "description": "Activit\u00e9s de soutien \u00e0 la production animale"
  },
  {
    "code": "0163Z",
    "description": "Traitement primaire des r\u00e9coltes"
  },
  {
    "code": "0164Z",
    "description": "Traitement des semences"
  },
  {
    "code": "0170Z",
    "description": "Chasse, pi\u00e9geage et services annexes"
  },
  {
    "code": "0210Z",
    "description": "Sylviculture et autres activit\u00e9s foresti\u00e8res"
  },
  {
    "code": "0220Z",
    "description": "Exploitation foresti\u00e8re"
  },
  {
    "code": "0230Z",
    "description": "R\u00e9colte de produits forestiers non ligneux poussant \u00e0 l'\u00e9tat sauvage"
  },
  {
    "code": "0240Z",
    "description": "Services de soutien \u00e0 l'exploitation foresti\u00e8re"
  },
  {
    "code": "0311Z",
    "description": "P\u00eache en mer"
  },
  {
    "code": "0312Z",
    "description": "P\u00eache en eau douce"
  },
  {
    "code": "0321Z",
    "description": "Aquaculture en mer"
  },
  {
    "code": "0322Z",
    "description": "Aquaculture en eau douce"
  },
  {
    "code": "0510Z",
    "description": "Extraction de houille"
  },
  {
    "code": "0520Z",
    "description": "Extraction de lignite"
  },
  {
    "code": "0610Z",
    "description": "Extraction de p\u00e9trole brut"
  },
  {
    "code": "0620Z",
    "description": "Extraction de gaz naturel"
  },
  {
    "code": "0710Z",
    "description": "Extraction de minerais de fer"
  },
  {
    "code": "0721Z",
    "description": "Extraction de minerais d'uranium et de thorium"
  },
  {
    "code": "0729Z",
    "description": "Extraction d'autres minerais de m\u00e9taux non ferreux"
  },
  {
    "code": "0811Z",
    "description": "Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise"
  },
  {
    "code": "0812Z",
    "description": "Exploitation de gravi\u00e8res et sabli\u00e8res, extraction d\u2019argiles et de kaolin"
  },
  {
    "code": "0891Z",
    "description": "Extraction des min\u00e9raux chimiques et d'engrais min\u00e9raux"
  },
  {
    "code": "0892Z",
    "description": "Extraction de tourbe"
  },
  {
    "code": "0893Z",
    "description": "Production de sel"
  },
  {
    "code": "0899Z",
    "description": "Autres activit\u00e9s extractives nca"
  },
  {
    "code": "0910Z",
    "description": "Activit\u00e9s de soutien \u00e0 l'extraction d'hydrocarbures"
  },
  {
    "code": "0990Z",
    "description": "Activit\u00e9s de soutien aux autres industries extractives"
  },
  {
    "code": "1011Z",
    "description": "Transformation et conservation de la viande de boucherie"
  },
  {
    "code": "1011Z",
    "description": "Transformation et conservation de la viande de boucherie"
  },
  {
    "code": "1012Z",
    "description": "Transformation et conservation de la viande de volaille"
  },
  {
    "code": "1013A",
    "description": "Pr\u00e9paration industrielle de produits \u00e0 base de viande"
  },
  {
    "code": "1013B",
    "description": "Charcuterie"
  },
  {
    "code": "1020Z",
    "description": "Transformation et conservation de poisson, de crustac\u00e9s et de mollusques"
  },
  {
    "code": "1031Z",
    "description": "Transformation et conservation de pommes de terre"
  },
  {
    "code": "1032Z",
    "description": "Pr\u00e9paration de jus de fruits et l\u00e9gumes"
  },
  {
    "code": "1039A",
    "description": "Autre transformation et conservation de l\u00e9gumes"
  },
  {
    "code": "1039B",
    "description": "Transformation et conservation de fruits"
  },
  {
    "code": "1041A",
    "description": "Fabrication d'huiles et graisses brutes"
  },
  {
    "code": "1041B",
    "description": "Fabrication d'huiles et graisses raffin\u00e9es"
  },
  {
    "code": "1042Z",
    "description": "Fabrication de margarine et graisses comestibles similaires"
  },
  {
    "code": "1051A",
    "description": "Fabrication de lait liquide et de produits frais"
  },
  {
    "code": "1051B",
    "description": "Fabrication de beurre"
  },
  {
    "code": "1051C",
    "description": "Fabrication de fromage"
  },
  {
    "code": "1051D",
    "description": "Fabrication d'autres produits laitiers"
  },
  {
    "code": "1052Z",
    "description": "Fabrication de glaces et sorbets"
  },
  {
    "code": "1061A",
    "description": "Meunerie"
  },
  {
    "code": "1061B",
    "description": "Autres activit\u00e9s du travail des grains"
  },
  {
    "code": "1062Z",
    "description": "Fabrication de produits amylac\u00e9s"
  },
  {
    "code": "1071A",
    "description": "Fabrication industrielle de pain et de p\u00e2tisserie fra\u00eeche"
  },
  {
    "code": "1071B",
    "description": "Cuisson de produits de boulangerie"
  },
  {
    "code": "1071C",
    "description": "Boulangerie et boulangerie-p\u00e2tisserie"
  },
  {
    "code": "1071D",
    "description": "P\u00e2tisserie"
  },
  {
    "code": "1072Z",
    "description": "Fabrication de biscuits, biscottes et p\u00e2tisseries de conservation"
  },
  {
    "code": "1073Z",
    "description": "Fabrication de p\u00e2tes alimentaires"
  },
  {
    "code": "1081Z",
    "description": "Fabrication de sucre"
  },
  {
    "code": "1082Z",
    "description": "Fabrication de cacao, chocolat et de produits de confiserie"
  },
  {
    "code": "1083Z",
    "description": "Transformation du th\u00e9 et du caf\u00e9"
  },
  {
    "code": "1084Z",
    "description": "Fabrication de condiments et assaisonnements"
  },
  {
    "code": "1085Z",
    "description": "Fabrication de plats pr\u00e9par\u00e9s"
  },
  {
    "code": "1086Z",
    "description": "Fabrication d'aliments homog\u00e9n\u00e9is\u00e9s et di\u00e9t\u00e9tiques"
  },
  {
    "code": "1089Z",
    "description": "Fabrication d'autres produits alimentaires nca"
  },
  {
    "code": "1091Z",
    "description": "Fabrication d'aliments pour animaux de ferme"
  },
  {
    "code": "1092Z",
    "description": "Fabrication d'aliments pour animaux de compagnie"
  },
  {
    "code": "1101Z",
    "description": "Production de boissons alcooliques distill\u00e9es"
  },
  {
    "code": "1102A",
    "description": "Fabrication de vins effervescents"
  },
  {
    "code": "1102B",
    "description": "Vinification"
  },
  {
    "code": "1103Z",
    "description": "Fabrication de cidre et de vins de fruits"
  },
  {
    "code": "1104Z",
    "description": "Production d'autres boissons ferment\u00e9es non distill\u00e9es"
  },
  {
    "code": "1105Z",
    "description": "Fabrication de bi\u00e8re"
  },
  {
    "code": "1106Z",
    "description": "Fabrication de malt"
  },
  {
    "code": "1107A",
    "description": "Industrie des eaux de table"
  },
  {
    "code": "1107B",
    "description": "Production de boissons rafra\u00eechissantes"
  },
  {
    "code": "1200Z",
    "description": "Fabrication de produits \u00e0 base de tabac"
  },
  {
    "code": "1310Z",
    "description": "Pr\u00e9paration de fibres textiles et filature"
  },
  {
    "code": "1320Z",
    "description": "Tissage"
  },
  {
    "code": "1330Z",
    "description": "Ennoblissement textile"
  },
  {
    "code": "1391Z",
    "description": "Fabrication d'\u00e9toffes \u00e0 mailles"
  },
  {
    "code": "1392Z",
    "description": "Fabrication d'articles textiles, sauf habillement"
  },
  {
    "code": "1393Z",
    "description": "Fabrication de tapis et moquettes"
  },
  {
    "code": "1394Z",
    "description": "Fabrication de ficelles, cordes et filets"
  },
  {
    "code": "1395Z",
    "description": "Fabrication de non-tiss\u00e9s, sauf habillement"
  },
  {
    "code": "1396Z",
    "description": "Fabrication d'autres textiles techniques et industriels"
  },
  {
    "code": "1399Z",
    "description": "Fabrication d'autres textiles nca"
  },
  {
    "code": "1411Z",
    "description": "Fabrication de v\u00eatements en cuir"
  },
  {
    "code": "1412Z",
    "description": "Fabrication de v\u00eatements de travail"
  },
  {
    "code": "1413Z",
    "description": "Fabrication de v\u00eatements de dessus"
  },
  {
    "code": "1414Z",
    "description": "Fabrication de v\u00eatements de dessous"
  },
  {
    "code": "1419Z",
    "description": "Fabrication d'autres v\u00eatements et accessoires"
  },
  {
    "code": "1420Z",
    "description": "Fabrication d'articles en fourrure"
  },
  {
    "code": "1431Z",
    "description": "Fabrication d'articles chaussants \u00e0 mailles"
  },
  {
    "code": "1439Z",
    "description": "Fabrication d'autres articles \u00e0 mailles"
  },
  {
    "code": "1511Z",
    "description": "Appr\u00eat et tannage des cuirs ; pr\u00e9paration et teinture des fourrures"
  },
  {
    "code": "1512Z",
    "description": "Fabrication d'articles de voyage, de maroquinerie et de sellerie"
  },
  {
    "code": "1520Z",
    "description": "Fabrication de chaussures"
  },
  {
    "code": "1610A",
    "description": "Sciage et rabotage du bois, hors impr\u00e9gnation"
  },
  {
    "code": "1610B",
    "description": "Impr\u00e9gnation du bois"
  },
  {
    "code": "1621Z",
    "description": "Fabrication de placage et de panneaux de bois"
  },
  {
    "code": "1622Z",
    "description": "Fabrication de parquets assembl\u00e9s"
  },
  {
    "code": "1623Z",
    "description": "Fabrication de charpentes et d'autres menuiseries"
  },
  {
    "code": "1624Z",
    "description": "Fabrication d'emballages en bois"
  },
  {
    "code": "1629Z",
    "description": "Fabrication d'objets divers en bois ; fabrication d'objets en li\u00e8ge, vannerie et sparterie"
  },
  {
    "code": "1629Z",
    "description": "Fabrication d'objets divers en bois; fabrication d'objets en li\u00e8ge, vannerie et sparterie"
  },
  {
    "code": "1711Z",
    "description": "Fabrication de p\u00e2te \u00e0 papier"
  },
  {
    "code": "1712Z",
    "description": "Fabrication de papier et de carton"
  },
  {
    "code": "1721A",
    "description": "Fabrication de carton ondul\u00e9"
  },
  {
    "code": "1721B",
    "description": "Fabrication de cartonnages"
  },
  {
    "code": "1721C",
    "description": "Fabrication d'emballages en papier"
  },
  {
    "code": "1722Z",
    "description": "Fabrication d'articles en papier \u00e0 usage sanitaire ou domestique"
  },
  {
    "code": "1723Z",
    "description": "Fabrication d'articles de papeterie"
  },
  {
    "code": "1724Z",
    "description": "Fabrication de papiers peints"
  },
  {
    "code": "1729Z",
    "description": "Fabrication d'autres articles en papier ou en carton"
  },
  {
    "code": "1811Z",
    "description": "Imprimerie de journaux"
  },
  {
    "code": "1812Z",
    "description": "Autre imprimerie (labeur)"
  },
  {
    "code": "1813Z",
    "description": "Activit\u00e9s de pr\u00e9-presse"
  },
  {
    "code": "1814Z",
    "description": "Reliure et activit\u00e9s connexes"
  },
  {
    "code": "1820Z",
    "description": "Reproduction d'enregistrements"
  },
  {
    "code": "1910Z",
    "description": "Cok\u00e9faction"
  },
  {
    "code": "1920Z",
    "description": "Raffinage du p\u00e9trole"
  },
  {
    "code": "2011Z",
    "description": "Fabrication de gaz industriels"
  },
  {
    "code": "2012Z",
    "description": "Fabrication de colorants et de pigments"
  },
  {
    "code": "2013A",
    "description": "Enrichissement et retraitement de mati\u00e8res nucl\u00e9aires"
  },
  {
    "code": "2013B",
    "description": "Fabrication d'autres produits chimiques inorganiques de base nca"
  },
  {
    "code": "2014Z",
    "description": "Fabrication d'autres produits chimiques organiques de base"
  },
  {
    "code": "2015Z",
    "description": "Fabrication de produits azot\u00e9s et d'engrais"
  },
  {
    "code": "2016Z",
    "description": "Fabrication de mati\u00e8res plastiques de base"
  },
  {
    "code": "2017Z",
    "description": "Fabrication de caoutchouc synth\u00e9tique"
  },
  {
    "code": "2020Z",
    "description": "Fabrication de pesticides et d\u2019autres produits agrochimiques"
  },
  {
    "code": "2030Z",
    "description": "Fabrication de peintures, vernis, encres et mastics"
  },
  {
    "code": "2041Z",
    "description": "Fabrication de savons, d\u00e9tergents et produits d'entretien"
  },
  {
    "code": "2042Z",
    "description": "Fabrication de parfums et de produits pour la toilette"
  },
  {
    "code": "2051Z",
    "description": "Fabrication de produits explosifs"
  },
  {
    "code": "2052Z",
    "description": "Fabrication de colles"
  },
  {
    "code": "2053Z",
    "description": "Fabrication d'huiles essentielles"
  },
  {
    "code": "2059Z",
    "description": "Fabrication d'autres produits chimiques nca"
  },
  {
    "code": "2060Z",
    "description": "Fabrication de fibres artificielles ou synth\u00e9tiques"
  },
  {
    "code": "2110Z",
    "description": "Fabrication de produits pharmaceutiques de base"
  },
  {
    "code": "2120Z",
    "description": "Fabrication de pr\u00e9parations pharmaceutiques"
  },
  {
    "code": "2211Z",
    "description": "Fabrication et rechapage de pneumatiques"
  },
  {
    "code": "2219Z",
    "description": "Fabrication d'autres articles en caoutchouc"
  },
  {
    "code": "2221Z",
    "description": "Fabrication de plaques, feuilles, tubes et profil\u00e9s en mati\u00e8res plastiques"
  },
  {
    "code": "2222Z",
    "description": "Fabrication d'emballages en mati\u00e8res plastiques"
  },
  {
    "code": "2223Z",
    "description": "Fabrication d'\u00e9l\u00e9ments en mati\u00e8res plastiques pour la construction"
  },
  {
    "code": "2229A",
    "description": "Fabrication de pi\u00e8ces techniques \u00e0 base de mati\u00e8res plastiques"
  },
  {
    "code": "2229B",
    "description": "Fabrication de produits de consommation courante en mati\u00e8res plastiques"
  },
  {
    "code": "2311Z",
    "description": "Fabrication de verre plat"
  },
  {
    "code": "2312Z",
    "description": "Fa\u00e7onnage et transformation du verre plat"
  },
  {
    "code": "2313Z",
    "description": "Fabrication de verre creux"
  },
  {
    "code": "2314Z",
    "description": "Fabrication de fibres de verre"
  },
  {
    "code": "2319Z",
    "description": "Fabrication et fa\u00e7onnage d'autres articles en verre, y compris verre technique"
  },
  {
    "code": "2320Z",
    "description": "Fabrication de produits r\u00e9fractaires"
  },
  {
    "code": "2331Z",
    "description": "Fabrication de carreaux en c\u00e9ramique"
  },
  {
    "code": "2332Z",
    "description": "Fabrication de briques, tuiles et produits de construction, en terre cuite"
  },
  {
    "code": "2341Z",
    "description": "Fabrication d'articles c\u00e9ramiques \u00e0 usage domestique ou ornemental"
  },
  {
    "code": "2342Z",
    "description": "Fabrication d'appareils sanitaires en c\u00e9ramique"
  },
  {
    "code": "2343Z",
    "description": "Fabrication d'isolateurs et pi\u00e8ces isolantes en c\u00e9ramique"
  },
  {
    "code": "2344Z",
    "description": "Fabrication d'autres produits c\u00e9ramiques \u00e0 usage technique"
  },
  {
    "code": "2349Z",
    "description": "Fabrication d'autres produits c\u00e9ramiques"
  },
  {
    "code": "2351Z",
    "description": "Fabrication de ciment"
  },
  {
    "code": "2352Z",
    "description": "Fabrication de chaux et pl\u00e2tre"
  },
  {
    "code": "2361Z",
    "description": "Fabrication d'\u00e9l\u00e9ments en b\u00e9ton pour la construction"
  },
  {
    "code": "2362Z",
    "description": "Fabrication d'\u00e9l\u00e9ments en pl\u00e2tre pour la construction"
  },
  {
    "code": "2363Z",
    "description": "Fabrication de b\u00e9ton pr\u00eat \u00e0 l'emploi"
  },
  {
    "code": "2364Z",
    "description": "Fabrication de mortiers et b\u00e9tons secs"
  },
  {
    "code": "2365Z",
    "description": "Fabrication d'ouvrages en fibre-ciment"
  },
  {
    "code": "2369Z",
    "description": "Fabrication d'autres ouvrages en b\u00e9ton, en ciment ou en pl\u00e2tre"
  },
  {
    "code": "2370Z",
    "description": "Taille, fa\u00e7onnage et finissage de pierres"
  },
  {
    "code": "2391Z",
    "description": "Fabrication de produits abrasifs"
  },
  {
    "code": "2399Z",
    "description": "Fabrication d'autres produits min\u00e9raux non m\u00e9talliques nca"
  },
  {
    "code": "2410Z",
    "description": "Sid\u00e9rurgie"
  },
  {
    "code": "2420Z",
    "description": "Fabrication de tubes, tuyaux, profil\u00e9s creux et accessoires correspondants en acier"
  },
  {
    "code": "2431Z",
    "description": "\u00c9tirage \u00e0 froid de barres"
  },
  {
    "code": "2432Z",
    "description": "Laminage \u00e0 froid de feuillards"
  },
  {
    "code": "2433Z",
    "description": "Profilage \u00e0 froid par formage ou pliage"
  },
  {
    "code": "2434Z",
    "description": "Tr\u00e9filage \u00e0 froid"
  },
  {
    "code": "2441Z",
    "description": "Production de m\u00e9taux pr\u00e9cieux"
  },
  {
    "code": "2442Z",
    "description": "M\u00e9tallurgie de l'aluminium"
  },
  {
    "code": "2443Z",
    "description": "M\u00e9tallurgie du plomb, du zinc ou de l'\u00e9tain"
  },
  {
    "code": "2444Z",
    "description": "M\u00e9tallurgie du cuivre"
  },
  {
    "code": "2445Z",
    "description": "M\u00e9tallurgie des autres m\u00e9taux non ferreux"
  },
  {
    "code": "2446Z",
    "description": "\u00c9laboration et transformation de mati\u00e8res nucl\u00e9aires"
  },
  {
    "code": "2451Z",
    "description": "Fonderie de fonte"
  },
  {
    "code": "2452Z",
    "description": "Fonderie d'acier"
  },
  {
    "code": "2453Z",
    "description": "Fonderie de m\u00e9taux l\u00e9gers"
  },
  {
    "code": "2454Z",
    "description": "Fonderie d'autres m\u00e9taux non ferreux"
  },
  {
    "code": "2511Z",
    "description": "Fabrication de structures m\u00e9talliques et de parties de structures"
  },
  {
    "code": "2512Z",
    "description": "Fabrication de portes et fen\u00eatres en m\u00e9tal"
  },
  {
    "code": "2521Z",
    "description": "Fabrication de radiateurs et de chaudi\u00e8res pour le chauffage central"
  },
  {
    "code": "2529Z",
    "description": "Fabrication d'autres r\u00e9servoirs, citernes et conteneurs m\u00e9talliques"
  },
  {
    "code": "2530Z",
    "description": "Fabrication de g\u00e9n\u00e9rateurs de vapeur, \u00e0 l'exception des chaudi\u00e8res pour le chauffage central"
  },
  {
    "code": "2540Z",
    "description": "Fabrication d'armes et de munitions"
  },
  {
    "code": "2550A",
    "description": "Forge, estampage, matri\u00e7age ; m\u00e9tallurgie des poudres"
  },
  {
    "code": "2550B",
    "description": "D\u00e9coupage, emboutissage"
  },
  {
    "code": "2561Z",
    "description": "Traitement et rev\u00eatement des m\u00e9taux"
  },
  {
    "code": "2562A",
    "description": "D\u00e9colletage"
  },
  {
    "code": "2562B",
    "description": "M\u00e9canique industrielle"
  },
  {
    "code": "2571Z",
    "description": "Fabrication de coutellerie"
  },
  {
    "code": "2572Z",
    "description": "Fabrication de serrures et de ferrures"
  },
  {
    "code": "2573A",
    "description": "Fabrication de moules et mod\u00e8les"
  },
  {
    "code": "2573B",
    "description": "Fabrication d'autres outillages"
  },
  {
    "code": "2591Z",
    "description": "Fabrication de f\u00fbts et emballages m\u00e9talliques similaires"
  },
  {
    "code": "2592Z",
    "description": "Fabrication d'emballages m\u00e9talliques l\u00e9gers"
  },
  {
    "code": "2593Z",
    "description": "Fabrication d'articles en fils m\u00e9talliques, de cha\u00eenes et de ressorts"
  },
  {
    "code": "2594Z",
    "description": "Fabrication de vis et de boulons"
  },
  {
    "code": "2599A",
    "description": "Fabrication d'articles m\u00e9talliques m\u00e9nagers"
  },
  {
    "code": "2599B",
    "description": "Fabrication d'autres articles m\u00e9talliques"
  },
  {
    "code": "2611Z",
    "description": "Fabrication de composants \u00e9lectroniques"
  },
  {
    "code": "2612Z",
    "description": "Fabrication de cartes \u00e9lectroniques assembl\u00e9es"
  },
  {
    "code": "2620Z",
    "description": "Fabrication d'ordinateurs et d'\u00e9quipements p\u00e9riph\u00e9riques"
  },
  {
    "code": "2630Z",
    "description": "Fabrication d'\u00e9quipements de communication"
  },
  {
    "code": "2640Z",
    "description": "Fabrication de produits \u00e9lectroniques grand public"
  },
  {
    "code": "2651A",
    "description": "Fabrication d'\u00e9quipements d'aide \u00e0 la navigation"
  },
  {
    "code": "2651B",
    "description": "Fabrication d'instrumentation scientifique et technique"
  },
  {
    "code": "2652Z",
    "description": "Horlogerie"
  },
  {
    "code": "2660Z",
    "description": "Fabrication d'\u00e9quipements d'irradiation m\u00e9dicale, d'\u00e9quipements \u00e9lectrom\u00e9dicaux et \u00e9lectroth\u00e9rapeutiques"
  },
  {
    "code": "2670Z",
    "description": "Fabrication de mat\u00e9riels optique et photographique"
  },
  {
    "code": "2680Z",
    "description": "Fabrication de supports magn\u00e9tiques et optiques"
  },
  {
    "code": "2711Z",
    "description": "Fabrication de moteurs, g\u00e9n\u00e9ratrices et transformateurs \u00e9lectriques"
  },
  {
    "code": "2712Z",
    "description": "Fabrication de mat\u00e9riel de distribution et de commande \u00e9lectrique"
  },
  {
    "code": "2720Z",
    "description": "Fabrication de piles et d'accumulateurs \u00e9lectriques"
  },
  {
    "code": "2731Z",
    "description": "Fabrication de c\u00e2bles de fibres optiques"
  },
  {
    "code": "2732Z",
    "description": "Fabrication d'autres fils et c\u00e2bles \u00e9lectroniques ou \u00e9lectriques"
  },
  {
    "code": "2733Z",
    "description": "Fabrication de mat\u00e9riel d'installation \u00e9lectrique"
  },
  {
    "code": "2740Z",
    "description": "Fabrication d'appareils d'\u00e9clairage \u00e9lectrique"
  },
  {
    "code": "2751Z",
    "description": "Fabrication d'appareils \u00e9lectrom\u00e9nagers"
  },
  {
    "code": "2752Z",
    "description": "Fabrication d'appareils m\u00e9nagers non \u00e9lectriques"
  },
  {
    "code": "2790Z",
    "description": "Fabrication d'autres mat\u00e9riels \u00e9lectriques"
  },
  {
    "code": "2811Z",
    "description": "Fabrication de moteurs et turbines, \u00e0 l'exception des moteurs d\u2019avions et de v\u00e9hicules"
  },
  {
    "code": "2812Z",
    "description": "Fabrication d'\u00e9quipements hydrauliques et pneumatiques"
  },
  {
    "code": "2813Z",
    "description": "Fabrication d'autres pompes et compresseurs"
  },
  {
    "code": "2814Z",
    "description": "Fabrication d'autres articles de robinetterie"
  },
  {
    "code": "2815Z",
    "description": "Fabrication d'engrenages et d'organes m\u00e9caniques de transmission"
  },
  {
    "code": "2821Z",
    "description": "Fabrication de fours et br\u00fbleurs"
  },
  {
    "code": "2822Z",
    "description": "Fabrication de mat\u00e9riel de levage et de manutention"
  },
  {
    "code": "2823Z",
    "description": "Fabrication de machines et d'\u00e9quipements de bureau (\u00e0 l'exception des ordinateurs et \u00e9quipements p\u00e9riph\u00e9riques)"
  },
  {
    "code": "2824Z",
    "description": "Fabrication d'outillage portatif \u00e0 moteur incorpor\u00e9"
  },
  {
    "code": "2825Z",
    "description": "Fabrication d'\u00e9quipements a\u00e9rauliques et frigorifiques industriels"
  },
  {
    "code": "2829A",
    "description": "Fabrication d'\u00e9quipements d'emballage, de conditionnement et de pesage"
  },
  {
    "code": "2829B",
    "description": "Fabrication d'autres machines d'usage g\u00e9n\u00e9ral"
  },
  {
    "code": "2830Z",
    "description": "Fabrication de machines agricoles et foresti\u00e8res"
  },
  {
    "code": "2841Z",
    "description": "Fabrication de machines-outils pour le travail des m\u00e9taux"
  },
  {
    "code": "2849Z",
    "description": "Fabrication d'autres machines-outils"
  },
  {
    "code": "2891Z",
    "description": "Fabrication de machines pour la m\u00e9tallurgie"
  },
  {
    "code": "2892Z",
    "description": "Fabrication de machines pour l'extraction ou la construction"
  },
  {
    "code": "2893Z",
    "description": "Fabrication de machines pour l'industrie agro-alimentaire"
  },
  {
    "code": "2894Z",
    "description": "Fabrication de machines pour les industries textiles"
  },
  {
    "code": "2895Z",
    "description": "Fabrication de machines pour les industries du papier et du carton"
  },
  {
    "code": "2896Z",
    "description": "Fabrication de machines pour le travail du caoutchouc ou des plastiques"
  },
  {
    "code": "2899A",
    "description": "Fabrication de machines d'imprimerie"
  },
  {
    "code": "2899B",
    "description": "Fabrication d'autres machines sp\u00e9cialis\u00e9es"
  },
  {
    "code": "2910Z",
    "description": "Construction de v\u00e9hicules automobiles"
  },
  {
    "code": "2920Z",
    "description": "Fabrication de carrosseries et remorques"
  },
  {
    "code": "2931Z",
    "description": "Fabrication d'\u00e9quipements \u00e9lectriques et \u00e9lectroniques automobiles"
  },
  {
    "code": "2932Z",
    "description": "Fabrication d'autres \u00e9quipements automobiles"
  },
  {
    "code": "3011Z",
    "description": "Construction de navires et de structures flottantes"
  },
  {
    "code": "3012Z",
    "description": "Construction de bateaux de plaisance"
  },
  {
    "code": "3020Z",
    "description": "Construction de locomotives et d'autre mat\u00e9riel ferroviaire roulant"
  },
  {
    "code": "3030Z",
    "description": "Construction a\u00e9ronautique et spatiale"
  },
  {
    "code": "3040Z",
    "description": "Construction de v\u00e9hicules militaires de combat"
  },
  {
    "code": "3091Z",
    "description": "Fabrication de motocycles"
  },
  {
    "code": "3092Z",
    "description": "Fabrication de bicyclettes et de v\u00e9hicules pour invalides"
  },
  {
    "code": "3099Z",
    "description": "Fabrication d\u2019autres \u00e9quipements de transport nca"
  },
  {
    "code": "3101Z",
    "description": "Fabrication de meubles de bureau et de magasin"
  },
  {
    "code": "3102Z",
    "description": "Fabrication de meubles de cuisine"
  },
  {
    "code": "3103Z",
    "description": "Fabrication de matelas"
  },
  {
    "code": "3109A",
    "description": "Fabrication de si\u00e8ges d'ameublement d'int\u00e9rieur"
  },
  {
    "code": "3109B",
    "description": "Fabrication d\u2019autres meubles et industries connexes de l\u2019ameublement"
  },
  {
    "code": "3109B",
    "description": "Fabrication d'autres meubles et industries connexes de l'ameublement"
  },
  {
    "code": "3211Z",
    "description": "Frappe de monnaie"
  },
  {
    "code": "3212Z",
    "description": "Fabrication d\u2019articles de joaillerie et bijouterie"
  },
  {
    "code": "3213Z",
    "description": "Fabrication d\u2019articles de bijouterie fantaisie et articles similaires"
  },
  {
    "code": "3220Z",
    "description": "Fabrication d'instruments de musique"
  },
  {
    "code": "3230Z",
    "description": "Fabrication d'articles de sport"
  },
  {
    "code": "3240Z",
    "description": "Fabrication de jeux et jouets"
  },
  {
    "code": "3250A",
    "description": "Fabrication de mat\u00e9riel chirurgical et dentaire"
  },
  {
    "code": "3250A",
    "description": "Fabrication de mat\u00e9riel m\u00e9dico-chirurgical et dentaire"
  },
  {
    "code": "3250A",
    "description": "Fabrication de mat\u00e9riels m\u00e9dico-chirurgical et dentaire"
  },
  {
    "code": "3250B",
    "description": "Fabrication de lunettes"
  },
  {
    "code": "3291Z",
    "description": "Fabrication d\u2019articles de brosserie"
  },
  {
    "code": "3299Z",
    "description": "Autres activit\u00e9s manufacturi\u00e8res nca"
  },
  {
    "code": "3311Z",
    "description": "R\u00e9paration d'ouvrages en m\u00e9taux"
  },
  {
    "code": "3312Z",
    "description": "R\u00e9paration de machines et \u00e9quipements m\u00e9caniques"
  },
  {
    "code": "3313Z",
    "description": "R\u00e9paration de mat\u00e9riels \u00e9lectroniques et optiques"
  },
  {
    "code": "3314Z",
    "description": "R\u00e9paration d'\u00e9quipements \u00e9lectriques"
  },
  {
    "code": "3315Z",
    "description": "R\u00e9paration et maintenance navale"
  },
  {
    "code": "3316Z",
    "description": "R\u00e9paration et maintenance d'a\u00e9ronefs et d'engins spatiaux"
  },
  {
    "code": "3317Z",
    "description": "R\u00e9paration et maintenance d'autres \u00e9quipements de transport"
  },
  {
    "code": "3319Z",
    "description": "R\u00e9paration d'autres \u00e9quipements"
  },
  {
    "code": "3320A",
    "description": "Installation de structures m\u00e9talliques, chaudronn\u00e9es et de tuyauterie"
  },
  {
    "code": "3320B",
    "description": "Installation de machines et \u00e9quipements m\u00e9caniques"
  },
  {
    "code": "3320C",
    "description": "Conception d'ensemble et assemblage sur site industriel d'\u00e9quipements de contr\u00f4le des processus industriels"
  },
  {
    "code": "3320D",
    "description": "Installation d'\u00e9quipements \u00e9lectriques, de mat\u00e9riels \u00e9lectroniques et optiques ou d'autres mat\u00e9riels"
  },
  {
    "code": "3511Z",
    "description": "Production d'\u00e9lectricit\u00e9"
  },
  {
    "code": "3512Z",
    "description": "Transport d'\u00e9lectricit\u00e9"
  },
  {
    "code": "3513Z",
    "description": "Distribution d'\u00e9lectricit\u00e9"
  },
  {
    "code": "3514Z",
    "description": "Commerce d'\u00e9lectricit\u00e9"
  },
  {
    "code": "3521Z",
    "description": "Production de combustibles gazeux"
  },
  {
    "code": "3522Z",
    "description": "Distribution de combustibles gazeux par conduites"
  },
  {
    "code": "3523Z",
    "description": "Commerce de combustibles gazeux par conduites"
  },
  {
    "code": "3530Z",
    "description": "Production et distribution de vapeur et d'air conditionn\u00e9"
  },
  {
    "code": "3600Z",
    "description": "Captage, traitement et distribution d'eau"
  },
  {
    "code": "3700Z",
    "description": "Collecte et traitement des eaux us\u00e9es"
  },
  {
    "code": "3811Z",
    "description": "Collecte des d\u00e9chets non dangereux"
  },
  {
    "code": "3812Z",
    "description": "Collecte des d\u00e9chets dangereux"
  },
  {
    "code": "3821Z",
    "description": "Traitement et \u00e9limination des d\u00e9chets non dangereux"
  },
  {
    "code": "3822Z",
    "description": "Traitement et \u00e9limination des d\u00e9chets dangereux"
  },
  {
    "code": "3831Z",
    "description": "D\u00e9mant\u00e8lement d'\u00e9paves"
  },
  {
    "code": "3832Z",
    "description": "R\u00e9cup\u00e9ration de d\u00e9chets tri\u00e9s"
  },
  {
    "code": "3900Z",
    "description": "D\u00e9pollution et autres services de gestion des d\u00e9chets"
  },
  {
    "code": "4110A",
    "description": "Promotion immobili\u00e8re de logements"
  },
  {
    "code": "4110B",
    "description": "Promotion immobili\u00e8re de bureaux"
  },
  {
    "code": "4110C",
    "description": "Promotion immobili\u00e8re d'autres b\u00e2timents"
  },
  {
    "code": "4110D",
    "description": "Supports juridiques de programmes"
  },
  {
    "code": "4120A",
    "description": "Construction de maisons individuelles"
  },
  {
    "code": "4120B",
    "description": "Construction d'autres b\u00e2timents"
  },
  {
    "code": "4211Z",
    "description": "Construction de routes et autoroutes"
  },
  {
    "code": "4212Z",
    "description": "Construction de voies ferr\u00e9es de surface et souterraines"
  },
  {
    "code": "4213A",
    "description": "Construction d'ouvrages d'art"
  },
  {
    "code": "4213B",
    "description": "Construction et entretien de tunnels"
  },
  {
    "code": "4221Z",
    "description": "Construction de r\u00e9seaux pour fluides"
  },
  {
    "code": "4222Z",
    "description": "Construction de r\u00e9seaux \u00e9lectriques et de t\u00e9l\u00e9communications"
  },
  {
    "code": "4291Z",
    "description": "Construction d'ouvrages maritimes et fluviaux"
  },
  {
    "code": "4299Z",
    "description": "Construction d'autres ouvrages de g\u00e9nie civil nca"
  },
  {
    "code": "4311Z",
    "description": "Travaux de d\u00e9molition"
  },
  {
    "code": "4312A",
    "description": "Travaux de terrassement courants et travaux pr\u00e9paratoires"
  },
  {
    "code": "4312B",
    "description": "Travaux de terrassement sp\u00e9cialis\u00e9s ou de grande masse"
  },
  {
    "code": "4313Z",
    "description": "Forages et sondages"
  },
  {
    "code": "4321A",
    "description": "Travaux d'installation \u00e9lectrique dans tous locaux"
  },
  {
    "code": "4321B",
    "description": "Travaux d'installation \u00e9lectrique sur la voie publique"
  },
  {
    "code": "4322A",
    "description": "Travaux d'installation d'eau et de gaz en tous locaux"
  },
  {
    "code": "4322B",
    "description": "Travaux d' installation d'\u00e9quipements thermiques et de climatisation"
  },
  {
    "code": "4329A",
    "description": "Travaux d'isolation"
  },
  {
    "code": "4329B",
    "description": "Autres travaux d'installation nca"
  },
  {
    "code": "4331Z",
    "description": "Travaux de pl\u00e2trerie"
  },
  {
    "code": "4332A",
    "description": "Travaux de menuiserie bois et PVC"
  },
  {
    "code": "4332B",
    "description": "Travaux de menuiserie m\u00e9tallique et serrurerie"
  },
  {
    "code": "4332C",
    "description": "Agencement de lieux de vente"
  },
  {
    "code": "4333Z",
    "description": "Travaux de rev\u00eatement des sols et des murs"
  },
  {
    "code": "4334Z",
    "description": "Travaux de peinture et vitrerie"
  },
  {
    "code": "4339Z",
    "description": "Autres travaux de finition"
  },
  {
    "code": "4391A",
    "description": "Travaux de charpente"
  },
  {
    "code": "4391B",
    "description": "Travaux de couverture par \u00e9l\u00e9ments"
  },
  {
    "code": "4399A",
    "description": "Travaux d'\u00e9tanch\u00e9ification"
  },
  {
    "code": "4399B",
    "description": "Travaux de montage de structures m\u00e9talliques"
  },
  {
    "code": "4399C",
    "description": "Travaux de ma\u00e7onnerie g\u00e9n\u00e9rale et gros \u0153uvre de b\u00e2timent"
  },
  {
    "code": "4399D",
    "description": "Autres travaux sp\u00e9cialis\u00e9s de construction"
  },
  {
    "code": "4399E",
    "description": "Location avec op\u00e9rateur de mat\u00e9riel de construction"
  },
  {
    "code": "4511Z",
    "description": "Commerce de voitures et de v\u00e9hicules automobiles l\u00e9gers"
  },
  {
    "code": "4519Z",
    "description": "Commerce d'autres v\u00e9hicules automobiles"
  },
  {
    "code": "4520A",
    "description": "Entretien et r\u00e9paration de v\u00e9hicules automobiles l\u00e9gers"
  },
  {
    "code": "4520B",
    "description": "Entretien et r\u00e9paration d'autres v\u00e9hicules automobiles"
  },
  {
    "code": "4531Z",
    "description": "Commerce de gros d'\u00e9quipements automobiles"
  },
  {
    "code": "4532Z",
    "description": "Commerce de d\u00e9tail d'\u00e9quipements automobiles"
  },
  {
    "code": "4540Z",
    "description": "Commerce et r\u00e9paration de motocycles"
  },
  {
    "code": "4611Z",
    "description": "Interm\u00e9diaires du commerce en mati\u00e8res premi\u00e8res agricoles, animaux vivants, mati\u00e8res premi\u00e8res textiles et produits semi-finis"
  },
  {
    "code": "4612A",
    "description": "Centrales d'achat de carburant"
  },
  {
    "code": "4612B",
    "description": "Autres interm\u00e9diaires du commerce en combustibles, m\u00e9taux, min\u00e9raux et produits chimiques"
  },
  {
    "code": "4613Z",
    "description": "Interm\u00e9diaires du commerce en bois et mat\u00e9riaux de construction"
  },
  {
    "code": "4614Z",
    "description": "Interm\u00e9diaires du commerce en machines, \u00e9quipements industriels, navires et avions"
  },
  {
    "code": "4615Z",
    "description": "Interm\u00e9diaires du commerce en meubles, articles de m\u00e9nage et quincaillerie"
  },
  {
    "code": "4616Z",
    "description": "Interm\u00e9diaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"
  },
  {
    "code": "4617A",
    "description": "Centrales d'achat alimentaires"
  },
  {
    "code": "4617B",
    "description": "Autres interm\u00e9diaires du commerce en denr\u00e9es, boissons et tabac"
  },
  {
    "code": "4618Z",
    "description": "Interm\u00e9diaires sp\u00e9cialis\u00e9s dans le commerce d'autres produits sp\u00e9cifiques"
  },
  {
    "code": "4619A",
    "description": "Centrales d'achat non alimentaires"
  },
  {
    "code": "4619B",
    "description": "Autres interm\u00e9diaires du commerce en produits divers"
  },
  {
    "code": "4621Z",
    "description": "Commerce de gros (commerce interentreprises) de c\u00e9r\u00e9ales, de tabac non manufactur\u00e9, de semences et d'aliments pour le b\u00e9tail"
  },
  {
    "code": "4622Z",
    "description": "Commerce de gros (commerce interentreprises) de fleurs et plantes"
  },
  {
    "code": "4623Z",
    "description": "Commerce de gros (commerce interentreprises) d'animaux vivants"
  },
  {
    "code": "4624Z",
    "description": "Commerce de gros (commerce interentreprises) de cuirs et peaux"
  },
  {
    "code": "4631Z",
    "description": "Commerce de gros (commerce interentreprises) de fruits et l\u00e9gumes"
  },
  {
    "code": "4632A",
    "description": "Commerce de gros (commerce interentreprises) de viandes de boucherie"
  },
  {
    "code": "4632B",
    "description": "Commerce de gros (commerce interentreprises) de produits \u00e0 base de viande"
  },
  {
    "code": "4632C",
    "description": "Commerce de gros (commerce interentreprises) de volailles et gibier"
  },
  {
    "code": "4633Z",
    "description": "Commerce de gros (commerce interentreprises) de produits laitiers, \u0153ufs, huiles et mati\u00e8res grasses comestibles"
  },
  {
    "code": "4634Z",
    "description": "Commerce de gros (commerce interentreprises) de boissons"
  },
  {
    "code": "4635Z",
    "description": "Commerce de gros (commerce interentreprises) de produits \u00e0 base de tabac"
  },
  {
    "code": "4636Z",
    "description": "Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie"
  },
  {
    "code": "4637Z",
    "description": "Commerce de gros (commerce interentreprises) de caf\u00e9, th\u00e9, cacao et \u00e9pices"
  },
  {
    "code": "4638A",
    "description": "Commerce de gros (commerce interentreprises) de poissons, crustac\u00e9s et mollusques"
  },
  {
    "code": "4638B",
    "description": "Commerce de gros (commerce interentreprises) alimentaire sp\u00e9cialis\u00e9 divers"
  },
  {
    "code": "4639A",
    "description": "Commerce de gros (commerce interentreprises) de produits surgel\u00e9s"
  },
  {
    "code": "4639B",
    "description": "Commerce de gros (commerce interentreprises) alimentaire non sp\u00e9cialis\u00e9"
  },
  {
    "code": "4641Z",
    "description": "Commerce de gros (commerce interentreprises) de textiles"
  },
  {
    "code": "4642Z",
    "description": "Commerce de gros (commerce interentreprises) d'habillement et de chaussures"
  },
  {
    "code": "4643Z",
    "description": "Commerce de gros (commerce interentreprises) d'appareils \u00e9lectrom\u00e9nagers"
  },
  {
    "code": "4644Z",
    "description": "Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien"
  },
  {
    "code": "4645Z",
    "description": "Commerce de gros (commerce interentreprises) de parfumerie et de produits de beaut\u00e9"
  },
  {
    "code": "4646Z",
    "description": "Commerce de gros (commerce interentreprises) de produits pharmaceutiques"
  },
  {
    "code": "4647Z",
    "description": "Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'\u00e9clairage"
  },
  {
    "code": "4648Z",
    "description": "Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie"
  },
  {
    "code": "4649Z",
    "description": "Commerce de gros (commerce interentreprises) d'autres biens domestiques"
  },
  {
    "code": "4651Z",
    "description": "Commerce de gros (commerce interentreprises) d'ordinateurs, d'\u00e9quipements informatiques p\u00e9riph\u00e9riques et de logiciels"
  },
  {
    "code": "4652Z",
    "description": "Commerce de gros (commerce interentreprises) de composants et d'\u00e9quipements \u00e9lectroniques et de t\u00e9l\u00e9communication"
  },
  {
    "code": "4661Z",
    "description": "Commerce de gros (commerce interentreprises) de mat\u00e9riel agricole"
  },
  {
    "code": "4662Z",
    "description": "Commerce de gros (commerce interentreprises) de machines-outils"
  },
  {
    "code": "4663Z",
    "description": "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le g\u00e9nie civil"
  },
  {
    "code": "4664Z",
    "description": "Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement"
  },
  {
    "code": "4665Z",
    "description": "Commerce de gros (commerce interentreprises) de mobilier de bureau"
  },
  {
    "code": "4666Z",
    "description": "Commerce de gros (commerce interentreprises) d'autres machines et \u00e9quipements de bureau"
  },
  {
    "code": "4669A",
    "description": "Commerce de gros (commerce interentreprises) de mat\u00e9riel \u00e9lectrique"
  },
  {
    "code": "4669B",
    "description": "Commerce de gros (commerce interentreprises) de fournitures et \u00e9quipements industriels divers"
  },
  {
    "code": "4669C",
    "description": "Commerce de gros (commerce interentreprises) de fournitures et \u00e9quipements divers pour le commerce et les services"
  },
  {
    "code": "4671Z",
    "description": "Commerce de gros (commerce interentreprises) de combustibles et de produits annexes"
  },
  {
    "code": "4672Z",
    "description": "Commerce de gros (commerce interentreprises) de minerais et m\u00e9taux"
  },
  {
    "code": "4673A",
    "description": "Commerce de gros (commerce interentreprises) de bois et de mat\u00e9riaux de construction"
  },
  {
    "code": "4673B",
    "description": "Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de d\u00e9coration"
  },
  {
    "code": "4674A",
    "description": "Commerce de gros (commerce interentreprises) de quincaillerie"
  },
  {
    "code": "4674B",
    "description": "Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage"
  },
  {
    "code": "4675Z",
    "description": "Commerce de gros (commerce interentreprises) de produits chimiques"
  },
  {
    "code": "4676Z",
    "description": "Commerce de gros (commerce interentreprises) d'autres produits interm\u00e9diaires"
  },
  {
    "code": "4677Z",
    "description": "Commerce de gros (commerce interentreprises) de d\u00e9chets et d\u00e9bris"
  },
  {
    "code": "4690Z",
    "description": "Commerce de gros (commerce interentreprises) non sp\u00e9cialis\u00e9"
  },
  {
    "code": "4711A",
    "description": "Commerce de d\u00e9tail de produits surgel\u00e9s"
  },
  {
    "code": "4711B",
    "description": "Commerce d'alimentation g\u00e9n\u00e9rale"
  },
  {
    "code": "4711C",
    "description": "Sup\u00e9rettes"
  },
  {
    "code": "4711D",
    "description": "Supermarch\u00e9s"
  },
  {
    "code": "4711E",
    "description": "Magasins multi-commerces"
  },
  {
    "code": "4711F",
    "description": "Hypermarch\u00e9s"
  },
  {
    "code": "4719A",
    "description": "Grands magasins"
  },
  {
    "code": "4719B",
    "description": "Autres commerces de d\u00e9tail en magasin non sp\u00e9cialis\u00e9"
  },
  {
    "code": "4721Z",
    "description": "Commerce de d\u00e9tail de fruits et l\u00e9gumes en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4722Z",
    "description": "Commerce de d\u00e9tail de viandes et de produits \u00e0 base de viande en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4723Z",
    "description": "Commerce de d\u00e9tail de poissons, crustac\u00e9s et mollusques en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4724Z",
    "description": "Commerce de d\u00e9tail de pain, p\u00e2tisserie et confiserie en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4725Z",
    "description": "Commerce de d\u00e9tail de boissons en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4726Z",
    "description": "Commerce de d\u00e9tail de produits \u00e0 base de tabac en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4729Z",
    "description": "Autres commerces de d\u00e9tail alimentaires en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4730Z",
    "description": "Commerce de d\u00e9tail de carburants en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4741Z",
    "description": "Commerce de d\u00e9tail d'ordinateurs, d'unit\u00e9s p\u00e9riph\u00e9riques et de logiciels en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4742Z",
    "description": "Commerce de d\u00e9tail de mat\u00e9riels de t\u00e9l\u00e9communication en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4743Z",
    "description": "Commerce de d\u00e9tail de mat\u00e9riels audio et vid\u00e9o en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4751Z",
    "description": "Commerce de d\u00e9tail de textiles en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4752A",
    "description": "Commerce de d\u00e9tail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)"
  },
  {
    "code": "4752B",
    "description": "Commerce de d\u00e9tail de quincaillerie, peintures et verres en grandes surfaces (400 m2 et plus)"
  },
  {
    "code": "4753Z",
    "description": "Commerce de d\u00e9tail de tapis, moquettes et rev\u00eatements de murs et de sols en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4754Z",
    "description": "Commerce de d\u00e9tail d'appareils \u00e9lectrom\u00e9nagers en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4759A",
    "description": "Commerce de d\u00e9tail de meubles"
  },
  {
    "code": "4759B",
    "description": "Commerce de d\u00e9tail d'autres \u00e9quipements du foyer"
  },
  {
    "code": "4761Z",
    "description": "Commerce de d\u00e9tail de livres en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4762Z",
    "description": "Commerce de d\u00e9tail de journaux et papeterie en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4763Z",
    "description": "Commerce de d\u00e9tail d'enregistrements musicaux et vid\u00e9o en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4764Z",
    "description": "Commerce de d\u00e9tail d'articles de sport en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4765Z",
    "description": "Commerce de d\u00e9tail de jeux et jouets en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4771Z",
    "description": "Commerce de d\u00e9tail d'habillement en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4772A",
    "description": "Commerce de d\u00e9tail de la chaussure"
  },
  {
    "code": "4772B",
    "description": "Commerce de d\u00e9tail de maroquinerie et d'articles de voyage"
  },
  {
    "code": "4773Z",
    "description": "Commerce de d\u00e9tail de produits pharmaceutiques en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4774Z",
    "description": "Commerce de d\u00e9tail d'articles m\u00e9dicaux et orthop\u00e9diques en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4775Z",
    "description": "Commerce de d\u00e9tail de parfumerie et de produits de beaut\u00e9 en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4776Z",
    "description": "Commerce de d\u00e9tail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4777Z",
    "description": "Commerce de d\u00e9tail d'articles d'horlogerie et de bijouterie en magasin sp\u00e9cialis\u00e9"
  },
  {
    "code": "4778A",
    "description": "Commerces de d\u00e9tail d'optique"
  },
  {
    "code": "4778B",
    "description": "Commerces de d\u00e9tail de charbons et combustibles"
  },
  {
    "code": "4778C",
    "description": "Autres commerces de d\u00e9tail sp\u00e9cialis\u00e9s divers"
  },
  {
    "code": "4779Z",
    "description": "Commerce de d\u00e9tail de biens d'occasion en magasin"
  },
  {
    "code": "4781Z",
    "description": "Commerce de d\u00e9tail alimentaire sur \u00e9ventaires et march\u00e9s"
  },
  {
    "code": "4782Z",
    "description": "Commerce de d\u00e9tail de textiles, d'habillement et de chaussures sur \u00e9ventaires et march\u00e9s"
  },
  {
    "code": "4789Z",
    "description": "Autres commerces de d\u00e9tail sur \u00e9ventaires et march\u00e9s"
  },
  {
    "code": "4791A",
    "description": "Vente \u00e0 distance sur catalogue g\u00e9n\u00e9ral"
  },
  {
    "code": "4791B",
    "description": "Vente \u00e0 distance sur catalogue sp\u00e9cialis\u00e9"
  },
  {
    "code": "4799A",
    "description": "Vente \u00e0 domicile"
  },
  {
    "code": "4799B",
    "description": "Vente par automates et autres commerces de d\u00e9tail hors magasin, \u00e9ventaires ou march\u00e9s nca"
  },
  {
    "code": "4910Z",
    "description": "Transport ferroviaire interurbain de voyageurs"
  },
  {
    "code": "4920Z",
    "description": "Transports ferroviaires de fret"
  },
  {
    "code": "4931Z",
    "description": "Transports urbains et suburbains de voyageurs"
  },
  {
    "code": "4932Z",
    "description": "Transports de voyageurs par taxis"
  },
  {
    "code": "4939A",
    "description": "Transports routiers r\u00e9guliers de voyageurs"
  },
  {
    "code": "4939B",
    "description": "Autres transports routiers de voyageurs"
  },
  {
    "code": "4939C",
    "description": "T\u00e9l\u00e9ph\u00e9riques et remont\u00e9es m\u00e9caniques"
  },
  {
    "code": "4941A",
    "description": "Transports routiers de fret interurbains"
  },
  {
    "code": "4941B",
    "description": "Transports routiers de fret de proximit\u00e9"
  },
  {
    "code": "4941C",
    "description": "Location de camions avec chauffeur"
  },
  {
    "code": "4942Z",
    "description": "Services de d\u00e9m\u00e9nagement"
  },
  {
    "code": "4950Z",
    "description": "Transports par conduites"
  },
  {
    "code": "5010Z",
    "description": "Transports maritimes et c\u00f4tiers de passagers"
  },
  {
    "code": "5020Z",
    "description": "Transports maritimes et c\u00f4tiers de fret"
  },
  {
    "code": "5030Z",
    "description": "Transports fluviaux de passagers"
  },
  {
    "code": "5040Z",
    "description": "Transports fluviaux de fret"
  },
  {
    "code": "5110Z",
    "description": "Transports a\u00e9riens de passagers"
  },
  {
    "code": "5121Z",
    "description": "Transports a\u00e9riens de fret"
  },
  {
    "code": "5122Z",
    "description": "Transports spatiaux"
  },
  {
    "code": "5210A",
    "description": "Entreposage et stockage frigorifique"
  },
  {
    "code": "5210B",
    "description": "Entreposage et stockage non frigorifique"
  },
  {
    "code": "5221Z",
    "description": "Services auxiliaires des transports terrestres"
  },
  {
    "code": "5222Z",
    "description": "Services auxiliaires des transports par eau"
  },
  {
    "code": "5223Z",
    "description": "Services auxiliaires des transports a\u00e9riens"
  },
  {
    "code": "5224A",
    "description": "Manutention portuaire"
  },
  {
    "code": "5224B",
    "description": "Manutention non portuaire"
  },
  {
    "code": "5229A",
    "description": "Messagerie, fret express"
  },
  {
    "code": "5229B",
    "description": "Affr\u00e8tement et organisation des transports"
  },
  {
    "code": "5310Z",
    "description": "Activit\u00e9s de poste dans le cadre d'une obligation de service universel"
  },
  {
    "code": "5320Z",
    "description": "Autres activit\u00e9s de poste et de courrier"
  },
  {
    "code": "5510Z",
    "description": "H\u00f4tels et h\u00e9bergement similaire"
  },
  {
    "code": "5520Z",
    "description": "H\u00e9bergement touristique et autre h\u00e9bergement de courte dur\u00e9e"
  },
  {
    "code": "5530Z",
    "description": "Terrains de camping et parcs pour caravanes ou v\u00e9hicules de loisirs"
  },
  {
    "code": "5590Z",
    "description": "Autres h\u00e9bergements"
  },
  {
    "code": "5610A",
    "description": "Restauration traditionnelle"
  },
  {
    "code": "5610B",
    "description": "Caf\u00e9t\u00e9rias et autres libres-services"
  },
  {
    "code": "5610C",
    "description": "Restauration de type rapide"
  },
  {
    "code": "5621Z",
    "description": "Services des traiteurs"
  },
  {
    "code": "5629A",
    "description": "Restauration collective sous contrat"
  },
  {
    "code": "5629B",
    "description": "Autres services de restauration nca"
  },
  {
    "code": "5630Z",
    "description": "D\u00e9bits de boissons"
  },
  {
    "code": "5811Z",
    "description": "\u00c9dition de livres"
  },
  {
    "code": "5812Z",
    "description": "\u00c9dition de r\u00e9pertoires et de fichiers d'adresses"
  },
  {
    "code": "5813Z",
    "description": "\u00c9dition de journaux"
  },
  {
    "code": "5814Z",
    "description": "\u00c9dition de revues et p\u00e9riodiques"
  },
  {
    "code": "5819Z",
    "description": "Autres activit\u00e9s d'\u00e9dition"
  },
  {
    "code": "5821Z",
    "description": "\u00c9dition de jeux \u00e9lectroniques"
  },
  {
    "code": "5829A",
    "description": "\u00c9dition de logiciels syst\u00e8me et de r\u00e9seau"
  },
  {
    "code": "5829B",
    "description": "Edition de logiciels outils de d\u00e9veloppement et de langages"
  },
  {
    "code": "5829C",
    "description": "Edition de logiciels applicatifs"
  },
  {
    "code": "5911A",
    "description": "Production de films et de programmes pour la t\u00e9l\u00e9vision"
  },
  {
    "code": "5911B",
    "description": "Production de films institutionnels et publicitaires"
  },
  {
    "code": "5911C",
    "description": "Production de films pour le cin\u00e9ma"
  },
  {
    "code": "5912Z",
    "description": "Post-production de films cin\u00e9matographiques, de vid\u00e9o et de programmes de t\u00e9l\u00e9vision"
  },
  {
    "code": "5913A",
    "description": "Distribution de films cin\u00e9matographiques"
  },
  {
    "code": "5913B",
    "description": "Edition et distribution vid\u00e9o"
  },
  {
    "code": "5914Z",
    "description": "Projection de films cin\u00e9matographiques"
  },
  {
    "code": "5920Z",
    "description": "Enregistrement sonore et \u00e9dition musicale"
  },
  {
    "code": "6010Z",
    "description": "\u00c9dition et diffusion de programmes radio"
  },
  {
    "code": "6020A",
    "description": "Edition de cha\u00eenes g\u00e9n\u00e9ralistes"
  },
  {
    "code": "6020B",
    "description": "Edition de cha\u00eenes th\u00e9matiques"
  },
  {
    "code": "6110Z",
    "description": "T\u00e9l\u00e9communications filaires"
  },
  {
    "code": "6120Z",
    "description": "T\u00e9l\u00e9communications sans fil"
  },
  {
    "code": "6130Z",
    "description": "T\u00e9l\u00e9communications par satellite"
  },
  {
    "code": "6190Z",
    "description": "Autres activit\u00e9s de t\u00e9l\u00e9communication"
  },
  {
    "code": "6201Z",
    "description": "Programmation informatique"
  },
  {
    "code": "6202A",
    "description": "Conseil en syst\u00e8mes et logiciels informatiques"
  },
  {
    "code": "6202B",
    "description": "Tierce maintenance de syst\u00e8mes et d\u2019applications informatiques"
  },
  {
    "code": "6203Z",
    "description": "Gestion d'installations informatiques"
  },
  {
    "code": "6209Z",
    "description": "Autres activit\u00e9s informatiques"
  },
  {
    "code": "6311Z",
    "description": "Traitement de donn\u00e9es, h\u00e9bergement et activit\u00e9s connexes"
  },
  {
    "code": "6312Z",
    "description": "Portails Internet"
  },
  {
    "code": "6391Z",
    "description": "Activit\u00e9s des agences de presse"
  },
  {
    "code": "6399Z",
    "description": "Autres services d'information nca"
  },
  {
    "code": "6411Z",
    "description": "Activit\u00e9s de banque centrale"
  },
  {
    "code": "6419Z",
    "description": "Autres interm\u00e9diations mon\u00e9taires"
  },
  {
    "code": "6420Z",
    "description": "Activit\u00e9s des soci\u00e9t\u00e9s holding"
  },
  {
    "code": "6430Z",
    "description": "Fonds de placement et entit\u00e9s financi\u00e8res similaires"
  },
  {
    "code": "6491Z",
    "description": "Cr\u00e9dit-bail"
  },
  {
    "code": "6492Z",
    "description": "Autre distribution de cr\u00e9dit"
  },
  {
    "code": "6499Z",
    "description": "Autres activit\u00e9s des services financiers, hors assurance et caisses de retraite, nca"
  },
  {
    "code": "6511Z",
    "description": "Assurance vie"
  },
  {
    "code": "6512Z",
    "description": "Autres assurances"
  },
  {
    "code": "6520Z",
    "description": "R\u00e9assurance"
  },
  {
    "code": "6530Z",
    "description": "Caisses de retraite"
  },
  {
    "code": "6611Z",
    "description": "Administration de march\u00e9s financiers"
  },
  {
    "code": "6612Z",
    "description": "Courtage de valeurs mobili\u00e8res et de marchandises"
  },
  {
    "code": "6619A",
    "description": "Supports juridiques de gestion de patrimoine mobilier"
  },
  {
    "code": "6619B",
    "description": "Autres activit\u00e9s auxiliaires de services financiers, hors assurance et caisses de retraite, nca"
  },
  {
    "code": "6621Z",
    "description": "\u00c9valuation des risques et dommages"
  },
  {
    "code": "6622Z",
    "description": "Activit\u00e9s des agents et courtiers d'assurances"
  },
  {
    "code": "6629Z",
    "description": "Autres activit\u00e9s auxiliaires d'assurance et de caisses de retraite"
  },
  {
    "code": "6630Z",
    "description": "Gestion de fonds"
  },
  {
    "code": "6810Z",
    "description": "Activit\u00e9s des marchands de biens immobiliers"
  },
  {
    "code": "6820A",
    "description": "Location de logements"
  },
  {
    "code": "6820B",
    "description": "Location de terrains et d'autres biens immobiliers"
  },
  {
    "code": "6831Z",
    "description": "Agences immobili\u00e8res"
  },
  {
    "code": "6832A",
    "description": "Administration d'immeubles et autres biens immobiliers"
  },
  {
    "code": "6832B",
    "description": "Supports juridiques de gestion de patrimoine immobilier"
  },
  {
    "code": "6910Z",
    "description": "Activit\u00e9s juridiques"
  },
  {
    "code": "6920Z",
    "description": "Activit\u00e9s comptables"
  },
  {
    "code": "7010Z",
    "description": "Activit\u00e9s des si\u00e8ges sociaux"
  },
  {
    "code": "7021Z",
    "description": "Conseil en relations publiques et communication"
  },
  {
    "code": "7022Z",
    "description": "Conseil pour les affaires et autres conseils de gestion"
  },
  {
    "code": "7111Z",
    "description": "Activit\u00e9s d'architecture"
  },
  {
    "code": "7112A",
    "description": "Activit\u00e9 des g\u00e9om\u00e8tres"
  },
  {
    "code": "7112B",
    "description": "Ing\u00e9nierie, \u00e9tudes techniques"
  },
  {
    "code": "7120A",
    "description": "Contr\u00f4le technique automobile"
  },
  {
    "code": "7120B",
    "description": "Analyses, essais et inspections techniques"
  },
  {
    "code": "7211Z",
    "description": "Recherche-d\u00e9veloppement en biotechnologie"
  },
  {
    "code": "7219Z",
    "description": "Recherche-d\u00e9veloppement en autres sciences physiques et naturelles"
  },
  {
    "code": "7220Z",
    "description": "Recherche-d\u00e9veloppement en sciences humaines et sociales"
  },
  {
    "code": "7311Z",
    "description": "Activit\u00e9s des agences de publicit\u00e9"
  },
  {
    "code": "7312Z",
    "description": "R\u00e9gie publicitaire de m\u00e9dias"
  },
  {
    "code": "7320Z",
    "description": "\u00c9tudes de march\u00e9 et sondages"
  },
  {
    "code": "7410Z",
    "description": "Activit\u00e9s sp\u00e9cialis\u00e9es de design"
  },
  {
    "code": "7420Z",
    "description": "Activit\u00e9s photographiques"
  },
  {
    "code": "7430Z",
    "description": "Traduction et interpr\u00e9tation"
  },
  {
    "code": "7490A",
    "description": "Activit\u00e9 des \u00e9conomistes de la construction"
  },
  {
    "code": "7490B",
    "description": "Activit\u00e9s sp\u00e9cialis\u00e9es, scientifiques et techniques diverses"
  },
  {
    "code": "7500Z",
    "description": "Activit\u00e9s v\u00e9t\u00e9rinaires"
  },
  {
    "code": "7711A",
    "description": "Location de courte dur\u00e9e de voitures et de v\u00e9hicules automobiles l\u00e9gers"
  },
  {
    "code": "7711B",
    "description": "Location de longue dur\u00e9e de voitures et de v\u00e9hicules automobiles l\u00e9gers"
  },
  {
    "code": "7712Z",
    "description": "Location et location-bail de camions"
  },
  {
    "code": "7721Z",
    "description": "Location et location-bail d'articles de loisirs et de sport"
  },
  {
    "code": "7722Z",
    "description": "Location de vid\u00e9ocassettes et disques vid\u00e9o"
  },
  {
    "code": "7729Z",
    "description": "Location et location-bail d'autres biens personnels et domestiques"
  },
  {
    "code": "7731Z",
    "description": "Location et location-bail de machines et \u00e9quipements agricoles"
  },
  {
    "code": "7732Z",
    "description": "Location et location-bail de machines et \u00e9quipements pour la construction"
  },
  {
    "code": "7733Z",
    "description": "Location et location-bail de machines de bureau et de mat\u00e9riel informatique"
  },
  {
    "code": "7734Z",
    "description": "Location et location-bail de mat\u00e9riels de transport par eau"
  },
  {
    "code": "7735Z",
    "description": "Location et location-bail de mat\u00e9riels de transport a\u00e9rien"
  },
  {
    "code": "7739Z",
    "description": "Location et location-bail d'autres machines, \u00e9quipements et biens mat\u00e9riels nca"
  },
  {
    "code": "7740Z",
    "description": "Location-bail de propri\u00e9t\u00e9 intellectuelle et de produits similaires, \u00e0 l'exception des \u0153uvres soumises \u00e0 copyright"
  },
  {
    "code": "7810Z",
    "description": "Activit\u00e9s des agences de placement de main-d'\u0153uvre"
  },
  {
    "code": "7820Z",
    "description": "Activit\u00e9s des agences de travail temporaire"
  },
  {
    "code": "7830Z",
    "description": "Autre mise \u00e0 disposition de ressources humaines"
  },
  {
    "code": "7911Z",
    "description": "Activit\u00e9s des agences de voyage"
  },
  {
    "code": "7912Z",
    "description": "Activit\u00e9s des voyagistes"
  },
  {
    "code": "7990Z",
    "description": "Autres services de r\u00e9servation et activit\u00e9s connexes"
  },
  {
    "code": "8010Z",
    "description": "Activit\u00e9s de s\u00e9curit\u00e9 priv\u00e9e"
  },
  {
    "code": "8020Z",
    "description": "Activit\u00e9s li\u00e9es aux syst\u00e8mes de s\u00e9curit\u00e9"
  },
  {
    "code": "8030Z",
    "description": "Activit\u00e9s d'enqu\u00eate"
  },
  {
    "code": "8110Z",
    "description": "Activit\u00e9s combin\u00e9es de soutien li\u00e9 aux b\u00e2timents"
  },
  {
    "code": "8121Z",
    "description": "Nettoyage courant des b\u00e2timents"
  },
  {
    "code": "8122Z",
    "description": "Autres activit\u00e9s de nettoyage des b\u00e2timents et nettoyage industriel"
  },
  {
    "code": "8129A",
    "description": "D\u00e9sinfection, d\u00e9sinsectisation, d\u00e9ratisation"
  },
  {
    "code": "8129B",
    "description": "Autres activit\u00e9s de nettoyage nca"
  },
  {
    "code": "8130Z",
    "description": "Services d'am\u00e9nagement paysager"
  },
  {
    "code": "8211Z",
    "description": "Services administratifs combin\u00e9s de bureau"
  },
  {
    "code": "8219Z",
    "description": "Photocopie, pr\u00e9paration de documents et autres activit\u00e9s sp\u00e9cialis\u00e9es de soutien de bureau"
  },
  {
    "code": "8220Z",
    "description": "Activit\u00e9s de centres d'appels"
  },
  {
    "code": "8230Z",
    "description": "Organisation de foires, salons professionnels et congr\u00e8s"
  },
  {
    "code": "8291Z",
    "description": "Activit\u00e9s des agences de recouvrement de factures et des soci\u00e9t\u00e9s d'information financi\u00e8re sur la client\u00e8le"
  },
  {
    "code": "8292Z",
    "description": "Activit\u00e9s de conditionnement"
  },
  {
    "code": "8299Z",
    "description": "Autres activit\u00e9s de soutien aux entreprises nca"
  },
  {
    "code": "8411Z",
    "description": "Administration publique g\u00e9n\u00e9rale"
  },
  {
    "code": "8412Z",
    "description": "Administration publique (tutelle) de la sant\u00e9, de la formation, de la culture et des services sociaux, autre que s\u00e9curit\u00e9 sociale"
  },
  {
    "code": "8413Z",
    "description": "Administration publique (tutelle) des activit\u00e9s \u00e9conomiques"
  },
  {
    "code": "8421Z",
    "description": "Affaires \u00e9trang\u00e8res"
  },
  {
    "code": "8422Z",
    "description": "D\u00e9fense"
  },
  {
    "code": "8423Z",
    "description": "Justice"
  },
  {
    "code": "8424Z",
    "description": "Activit\u00e9s d\u2019ordre public et de s\u00e9curit\u00e9"
  },
  {
    "code": "8425Z",
    "description": "Services du feu et de secours"
  },
  {
    "code": "8430A",
    "description": "Activit\u00e9s g\u00e9n\u00e9rales de s\u00e9curit\u00e9 sociale"
  },
  {
    "code": "8430B",
    "description": "Gestion des retraites compl\u00e9mentaires"
  },
  {
    "code": "8430C",
    "description": "Distribution sociale de revenus"
  },
  {
    "code": "8510Z",
    "description": "Enseignement pr\u00e9-primaire"
  },
  {
    "code": "8520Z",
    "description": "Enseignement primaire"
  },
  {
    "code": "8531Z",
    "description": "Enseignement secondaire g\u00e9n\u00e9ral"
  },
  {
    "code": "8532Z",
    "description": "Enseignement secondaire technique ou professionnel"
  },
  {
    "code": "8541Z",
    "description": "Enseignement post-secondaire non sup\u00e9rieur"
  },
  {
    "code": "8542Z",
    "description": "Enseignement sup\u00e9rieur"
  },
  {
    "code": "8551Z",
    "description": "Enseignement de disciplines sportives et d'activit\u00e9s de loisirs"
  },
  {
    "code": "8552Z",
    "description": "Enseignement culturel"
  },
  {
    "code": "8553Z",
    "description": "Enseignement de la conduite"
  },
  {
    "code": "8559A",
    "description": "Formation continue d'adultes"
  },
  {
    "code": "8559B",
    "description": "Autres enseignements"
  },
  {
    "code": "8560Z",
    "description": "Activit\u00e9s de soutien \u00e0 l'enseignement"
  },
  {
    "code": "8610Z",
    "description": "Activit\u00e9s hospitali\u00e8res"
  },
  {
    "code": "8621Z",
    "description": "Activit\u00e9 des m\u00e9decins g\u00e9n\u00e9ralistes"
  },
  {
    "code": "8622A",
    "description": "Activit\u00e9s de radiodiagnostic et de radioth\u00e9rapie"
  },
  {
    "code": "8622B",
    "description": "Activit\u00e9s chirurgicales"
  },
  {
    "code": "8622C",
    "description": "Autres activit\u00e9s des m\u00e9decins sp\u00e9cialistes"
  },
  {
    "code": "8623Z",
    "description": "Pratique dentaire"
  },
  {
    "code": "8690A",
    "description": "Ambulances"
  },
  {
    "code": "8690B",
    "description": "Laboratoires d'analyses m\u00e9dicales"
  },
  {
    "code": "8690C",
    "description": "Centres de collecte et banques d'organes"
  },
  {
    "code": "8690D",
    "description": "Activit\u00e9s des infirmiers et des sages-femmes"
  },
  {
    "code": "8690E",
    "description": "Activit\u00e9s des professionnels de la r\u00e9\u00e9ducation, de l\u2019appareillage et des p\u00e9dicures-podologues"
  },
  {
    "code": "8690F",
    "description": "Activit\u00e9s de sant\u00e9 humaine non class\u00e9es ailleurs"
  },
  {
    "code": "8710A",
    "description": "H\u00e9bergement m\u00e9dicalis\u00e9 pour personnes \u00e2g\u00e9es"
  },
  {
    "code": "8710B",
    "description": "H\u00e9bergement m\u00e9dicalis\u00e9s pour enfants handicap\u00e9s"
  },
  {
    "code": "8710C",
    "description": "H\u00e9bergement m\u00e9dicalis\u00e9 pour adultes handicap\u00e9s et autre h\u00e9bergement m\u00e9dicalis\u00e9 "
  },
  {
    "code": "8720A",
    "description": "H\u00e9bergement social pour handicap\u00e9s mentaux et malades mentaux"
  },
  {
    "code": "8720B",
    "description": "H\u00e9bergement social pour toxicomanes"
  },
  {
    "code": "8730A",
    "description": "H\u00e9bergement social pour personnes \u00e2g\u00e9es"
  },
  {
    "code": "8730B",
    "description": "H\u00e9bergement social pour handicap\u00e9s physiques"
  },
  {
    "code": "8790A",
    "description": "H\u00e9bergement social pour enfants en difficult\u00e9s"
  },
  {
    "code": "8790B",
    "description": "H\u00e9bergement social pour adultes et familles en difficult\u00e9s et autre h\u00e9bergement social"
  },
  {
    "code": "8810A",
    "description": "Aide \u00e0 domicile"
  },
  {
    "code": "8810B",
    "description": "Accueil ou accompagnement sans h\u00e9bergement d\u2019adultes handicap\u00e9s ou de personnes \u00e2g\u00e9es"
  },
  {
    "code": "8810C",
    "description": "Aide par le travail"
  },
  {
    "code": "8891A",
    "description": "Accueil de jeunes enfants"
  },
  {
    "code": "8891B",
    "description": "Accueil ou accompagnement sans h\u00e9bergement d\u2019enfants handicap\u00e9s"
  },
  {
    "code": "8899A",
    "description": "Autre accueil ou accompagnement sans h\u00e9bergement ?_x0019_enfants et d\u2019adolescents "
  },
  {
    "code": "8899B",
    "description": "Action sociale sans h\u00e9bergement nca"
  },
  {
    "code": "9001Z",
    "description": "Arts du spectacle vivant"
  },
  {
    "code": "9002Z",
    "description": "Activit\u00e9s de soutien au spectacle vivant"
  },
  {
    "code": "9003A",
    "description": "Cr\u00e9ation artistique relevant des arts plastiques"
  },
  {
    "code": "9003B",
    "description": "Autre cr\u00e9ation artistique"
  },
  {
    "code": "9004Z",
    "description": "Gestion de salles de spectacles"
  },
  {
    "code": "9101Z",
    "description": "Gestion des biblioth\u00e8ques et des archives"
  },
  {
    "code": "9102Z",
    "description": "Gestion des mus\u00e9es"
  },
  {
    "code": "9103Z",
    "description": "Gestion des sites et monuments historiques et des attractions touristiques similaires"
  },
  {
    "code": "9104Z",
    "description": "Gestion des jardins botaniques et zoologiques et des r\u00e9serves naturelles"
  },
  {
    "code": "9200Z",
    "description": "Organisation de jeux de hasard et d'argent"
  },
  {
    "code": "9311Z",
    "description": "Gestion d'installations sportives"
  },
  {
    "code": "9312Z",
    "description": "Activit\u00e9s de clubs de sports"
  },
  {
    "code": "9313Z",
    "description": "Activit\u00e9s des centres de culture physique"
  },
  {
    "code": "9319Z",
    "description": "Autres activit\u00e9s li\u00e9es au sport"
  },
  {
    "code": "9321Z",
    "description": "Activit\u00e9s des parcs d'attractions et parcs \u00e0 th\u00e8mes"
  },
  {
    "code": "9329Z",
    "description": "Autres activit\u00e9s r\u00e9cr\u00e9atives et de loisirs"
  },
  {
    "code": "9411Z",
    "description": "Activit\u00e9s des organisations patronales et consulaires"
  },
  {
    "code": "9412Z",
    "description": "Activit\u00e9s des organisations professionnelles"
  },
  {
    "code": "9420Z",
    "description": "Activit\u00e9s des syndicats de salari\u00e9s"
  },
  {
    "code": "9491Z",
    "description": "Activit\u00e9s des organisations religieuses"
  },
  {
    "code": "9492Z",
    "description": "Activit\u00e9s des organisations politiques"
  },
  {
    "code": "9499Z",
    "description": "Autres organisations fonctionnant par adh\u00e9sion volontaire"
  },
  {
    "code": "9511Z",
    "description": "R\u00e9paration d'ordinateurs et d'\u00e9quipements p\u00e9riph\u00e9riques"
  },
  {
    "code": "9512Z",
    "description": "R\u00e9paration d'\u00e9quipements de communication"
  },
  {
    "code": "9521Z",
    "description": "R\u00e9paration de produits \u00e9lectroniques grand public"
  },
  {
    "code": "9522Z",
    "description": "R\u00e9paration d'appareils \u00e9lectrom\u00e9nagers et d'\u00e9quipements pour la maison et le jardin"
  },
  {
    "code": "9523Z",
    "description": "R\u00e9paration de chaussures et d'articles en cuir"
  },
  {
    "code": "9524Z",
    "description": "R\u00e9paration de meubles et d'\u00e9quipements du foyer"
  },
  {
    "code": "9525Z",
    "description": "R\u00e9paration d'articles d'horlogerie et de bijouterie"
  },
  {
    "code": "9529Z",
    "description": "R\u00e9paration d'autres biens personnels et domestiques"
  },
  {
    "code": "9601A",
    "description": "Blanchisserie-teinturerie de gros"
  },
  {
    "code": "9601B",
    "description": "Blanchisserie-teinturerie de d\u00e9tail"
  },
  {
    "code": "9602A",
    "description": "Coiffure"
  },
  {
    "code": "9602B",
    "description": "Soins de beaut\u00e9"
  },
  {
    "code": "9603Z",
    "description": "Services fun\u00e9raires"
  },
  {
    "code": "9604Z",
    "description": "Entretien corporel"
  },
  {
    "code": "9609Z",
    "description": "Autres services personnels nca"
  },
  {
    "code": "9700Z",
    "description": "Activit\u00e9s des m\u00e9nages en tant qu'employeurs de personnel domestique"
  },
  {
    "code": "9810Z",
    "description": "Activit\u00e9s indiff\u00e9renci\u00e9es des m\u00e9nages en tant que producteurs de biens pour usage propre"
  },
  {
    "code": "9820Z",
    "description": "Activit\u00e9s indiff\u00e9renci\u00e9es des m\u00e9nages en tant que producteurs de services pour usage propre"
  },
  {
    "code": "9900Z",
    "description": "Activit\u00e9s des organisations et organismes extraterritoriaux"
  }
]