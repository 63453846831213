import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  GridSize,
  Switch,
  Typography,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useAPI } from "../api";
import { OfferDetails, RenfortDetails } from "./OfferDetails";
import { SelectableCard } from "./SelectableCard";
import {
  Formula,
  FormulasInput,
  Offer,
  OfferListParams,
  Offers,
  StepParams,
  StructureOptions,
  waiting,
} from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { handleChange } from "../lib";
import { useCallback } from "react";
import { Assistance, Price } from "./Proposal/Price";
import {
  CardSection,
  collegeTitle,
  formulaIcon,
  formulaTitle,
} from "./Proposal";
import { Alert } from "@material-ui/lab";

type SelectOfferFormProps = {
  params: OfferListParams;
} & StepParams<FormulasInput>;

/** Offer (formula) selection.
 *
 * Used as a step in pricing form. Depending on selected
 * structure (ensemble or cadres/non-cadres) it shows list
 * of available formulas as a selectable cards.
 */
export function SelectOfferForm({ params, value, next }: SelectOfferFormProps) {
  const { getOffers } = useAPI();
  const [offers, setOffers] = useState(waiting<Offers>());
  const [formulas, setFormulas] = useState(value);

  const valid = useCallback(() => {
    return params.structures.every(({ college }) => formulas[college] !== null);
  }, [params.structures, formulas]);

  useEffect(() => {
    setOffers(waiting);
    getOffers(params)
      .then((response) => setOffers({ status: "ok", response }))
      .catch(() => setOffers({ status: "error" }));
    // eslint-disable-next-line
  }, [params]);

  return (
    <Box>
      <Box>
        La tarification proposée est donnée à titre indicatif en s'appuyant sur
        le Plafond mensuel de la sécurité sociale (PMSS) en vigueur à la date de
        saisie. Le PMSS 2024 s'élève à 3 864 € et peut être révisé chaque année
        au 1er janvier par la Sécurité Sociale.
      </Box>
      {offers.status === "waiting" ? (
        <CircularProgress />
      ) : offers.status === "error" ? (
        <Alert severity="error">Could not get offers from the API</Alert>
      ) : (
        offers.response.offers.map(({ college, value }) => (
          <FormulaSelection
            college={college}
            options={value}
            assistance={offers.response.assistance}
            value={formulas[college]}
            onChange={handleChange(college, setFormulas)}
          />
        ))
      )}
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          disabled={!valid()}
          startIcon={<NavigateNextIcon />}
          onClick={() => next(formulas)}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}

type FormulaSelectionProps = {
  college: keyof StructureOptions;
  options: Offer[];
  assistance: string;
  value: Formula | null;
  onChange: (f: Formula) => void;
};

/** Formula selection form. */
function FormulaSelection({
  college,
  options,
  assistance,
  value,
  onChange,
}: FormulaSelectionProps) {
  const [details, setDetails] = useState(false);
  let mdCols: GridSize = details ? 6 : 3;
  return (
    <Box mt={3}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">{collegeTitle(college)}</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={details}
              onChange={() => setDetails((f) => !f)}
              name="checkedB"
              color="primary"
            />
          }
          label={
            <Typography variant="caption">Afficher les détails</Typography>
          }
          labelPlacement="start"
        />
      </Box>
      <Grid container spacing={3}>
        {options.map((offer) => (
          <Grid item xs={6} md={mdCols}>
            <OfferCard
              offer={offer}
              value={value}
              assistance={assistance}
              details={details}
              onClick={onChange}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

type OfferCardProps = {
  offer: Offer;
  value: Formula | null;
  assistance: string;
  onClick: (f: Formula) => void;
  details: boolean;
};

/** A selectable card presenting one formula
 * Rendering will depend whether it is a selected card or not
 * or whether details are requested or not.
 *
 * Note that this component shows details in a slightly
 * different way compared to the already selected proposal
 * (`PriceCard`, as shown in the proposal summary). Here details
 * for an offer and option renfort are intertwined as it
 * allows for easier comparison.
 *
 * Additionally we have different types, as `Offer` have prices
 * for both "raw" value and the optionRenfort.
 */
function OfferCard({
  offer,
  value,
  assistance,
  onClick,
  details,
}: OfferCardProps) {
  const selected = value !== null ? value.name === offer.formula.name : false;

  const [renfort, setRenfort] = useState(
    selected && value && "optionRenfort" in value ? value.optionRenfort : false
  );

  let formula =
    "optionRenfort" in offer.formula
      ? { ...offer.formula, optionRenfort: renfort }
      : offer.formula;

  useEffect(() => {
    if (selected) {
      onClick(formula);
    } else {
      setRenfort(false);
    }
    // eslint-disable-next-line
  }, [renfort, selected]);

  return (
    <Box my={2}>
      <SelectableCard
        title={formulaTitle(formula)}
        icon={formulaIcon(formula)}
        selected={selected}
        onClick={() => onClick(formula)}
      >
        <Box>
          {offer.prices.map((p, index) => (
            <Price
              key={index}
              price={renfort ? p.price : p.basePrice}
              ppmss={renfort ? p.ppmss : p.basePPMSS}
              beneficient={p.beneficient}
            />
          ))}
          <Assistance value={assistance} />
          <CardSection show={details}>
            <OfferDetails formula={formula.name} />
          </CardSection>
          <CardSection show={"optionRenfort" in formula}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  checked={renfort}
                  onChange={() => setRenfort((f) => !f)}
                />
              }
              label="Option booster"
            />
            {details ? <RenfortDetails /> : null}
          </CardSection>
        </Box>
      </SelectableCard>
    </Box>
  );
}

export const emptyFormulasInput: FormulasInput = {
  ensemble: null,
  agirc: null,
  nonAgirc: null,
  ani: null,
  nonAni: null,
};
