import {
  Container,
  Typography,
  Box,
  Button,
  Snackbar,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  TextField,
} from "@material-ui/core";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import FileUpload from "../../components/FileUpload";
import { IconButton } from "@mui/material";

import {
  Alert,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import {
  currentStatus,
  DocumentItem,
  FileItem,
  finalStatus,
  GarantiesInfos,
  Proposal as ProposalType,
  statusUpdate,
  StepParams,
  lastUpdate,
} from "../../components/types";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect } from "react";
import "./Sante.sass";
import { ProposalStatus, Questionnaire, Role } from "../../components/types";
import { useParams } from "react-router";

import {
  PromoteurForm,
  emptyPromoteurInfo,
} from "../../components/PromoteurForm";
import {
  OperationInfosFrom,
  emptyOperationInfos,
} from "../../components/OperationInfosFrom";
import {
  emptyIntervenantsInfo,
  IntervenantsForm,
} from "../../components/IntervenantsForm";
import {
  emptyFinancementOpInfos,
  FinancementOpForm,
} from "../../components/FinancementOpForm";
import {
  emptyGarantiesInfo,
  GarantiesForm,
} from "../../components/GarantiesForm";
import { useAPI } from "../../api";
import { Color } from "@material-ui/lab/Alert/Alert";
import { FinalProposalStatus } from "./ProposalView";
import { Downloadable } from "../../components/Files";
import { useHistory } from "react-router-dom";

type SaveStatus = {
  type: Color;
  show: boolean;
  message: string;
};

type GFAprops = {
  userRoles?: Role[];
};

export function GFA({ userRoles }: GFAprops) {
  const { idGFA } = useParams() as { idGFA: string };
  const [activeStep, setActiveStep] = React.useState(0);
  const [saveForm, setSaveForm] = React.useState(false);
  const [loading, setLoading] = React.useState(idGFA ? true : false);

  const [promoteurInfo, setPromoteurInfo] = React.useState(emptyPromoteurInfo);
  const [financementOpInfos, setFinancementOpInfos] = React.useState(
    emptyFinancementOpInfos
  );

  const [intervenantsInfos, setIntervenantsInfos] = React.useState(
    emptyIntervenantsInfo
  );
  const [garantiesInfos, setGarantiesInfos] =
    React.useState(emptyGarantiesInfo);

  const [operationInfos, setOperationInfos] =
    React.useState(emptyOperationInfos);
  const [showDialog, setShowDialog] = React.useState({
    show: false,
    message: "",
    type: "info",
  } as SaveStatus);
  const handleStep = (wantedStep: number) => () => {
    if (wantedStep < activeStep) setActiveStep(wantedStep);
  };
  const history = useHistory();


  const user = useAuth0().user;
  console.log(user)

  const {
    saveGfaForm,
    getGfaForm,
    getDocumentListGfa,
    uploadFilesGfa,
    removeFilesGfa,
    listGfaForms,
  } = useAPI();
  const [brokerEmail, setBrokerEmail] = React.useState(
    user ? (user.email as string) : ""
  );
  const [brokerMetadata, setBrokerMetadata] = React.useState(
    user ? (user.user_metadata as Record<string, unknown>) : {}
  );
  const [brokerFullName, setBrokerFullName] = React.useState(
    user ? (user.name as string) : ""
  );
  const [status, setStatus] = React.useState([
    {
      tag: "open",
      created_at: "fake",
    } as ProposalStatus,
  ]);
  const [filesToUpload, setFilesToUpload] = React.useState([] as any[]);

  const [companyDocs, setCompanyDocs] = React.useState([] as any[]);
  const [reqRetries, setReqRetries] = React.useState(0 as number);
  const [readOnly, setReadOnly] = React.useState(false);

  const jumpToProposal = useCallback(
    (id: string) => history.push(`/gfa/${id}`),
    [history]
  );
  useEffect(() => {
    const interval = setInterval(() => {
      if (reqRetries <= 3 && idGFA) {
        getDocumentListGfa(idGFA)().then(handleDocs);
      }
    }, 7000);
    /*@ts-ignore*/
    if (reqRetries > 3) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [reqRetries]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDocs = (docsList: any) => {
    setReqRetries(reqRetries + 1);
    setCompanyDocs(docsList);
  };

  const handleRemoveFile = (index: number) => {
    removeFilesGfa({
      files: [companyDocs[index]],
      gfaFormId: idGFA,
    });
    const tmp = [...companyDocs];
    tmp.splice(index, 1);
    setCompanyDocs(tmp);
  };

  useEffect(() => {
    if (!(filesToUpload === undefined || filesToUpload.length === 0) && idGFA) {
      uploadFilesGfa({
        files: filesToUpload,
        gfaFormId: questionnaire?.gfaFormId,
      });
      setTimeout(() => getDocumentListGfa(idGFA)().then(handleDocs), 3000);
      setFilesToUpload([]);
    }
  }, [filesToUpload]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshProposal = () => {
    if (idGFA) {
      getGfaForm(idGFA)()
        .then((resp) => {
          setPromoteurInfo(resp.promoteur);
          setOperationInfos(resp.operation);
          setFinancementOpInfos(resp.financement);
          setIntervenantsInfos(resp.intervenants);
          setGarantiesInfos(resp.garanties);
          setBrokerEmail(resp.brokerEmail);
          setBrokerMetadata(resp.brokerMetadata);
          setBrokerFullName(resp.brokerFullName);
          setStatus(resp.status);
          setReadOnly(resp.docsReadOnly);
          setLoading(false);
        })
        .catch(() => console.log("error"));
    }
  };

  useEffect(refreshProposal, [idGFA]); // eslint-disable-line react-hooks/exhaustive-deps

  let questionnaire: Questionnaire = {
    status: status,
    promoteur: promoteurInfo,
    operation: operationInfos,
    financement: financementOpInfos,
    intervenants: intervenantsInfos,
    garanties: garantiesInfos,
    brokerEmail: brokerEmail,
    brokerMetadata: brokerMetadata,
    brokerFullName: brokerFullName,
    docsReadOnly: readOnly,
    gfaFormId: idGFA,
  };
  useEffect(() => {
    if (saveForm === true && questionnaire.docsReadOnly === false) {
      saveGfaForm(questionnaire)
        .then(() =>
          setShowDialog({
            show: true,
            message: `Votre formulaire a été correctement enregistré`,
            type: "success",
          })
        )
        .then(() => {
          listGfaForms().then((p): void => {
            if (questionnaire.gfaFormId) {
            } else {
              const proposals: Questionnaire[] = p.sort((a, b) =>
                lastUpdate(a) < lastUpdate(b) ? 1 : -1
              );
              if (proposals[0].gfaFormId)
                jumpToProposal(proposals[0].gfaFormId);
            }
          });
        })
        .catch(() => {
          setShowDialog({
            show: true,
            message: `L'enregistrement de votre formulaire a échoué`,
            type: "error",
          });
        });
      setSaveForm(false);
    }
    // eslint-disable-next-line
  }, [saveForm]);
  const handleFilesChange = (files: any) => {
    setFilesToUpload(files);
  };

  const steps = [
    {
      label: "Informations sur le promoteur",
      content: (
        <PromoteurForm
          value={promoteurInfo}
          next={(info) => {
            setPromoteurInfo(info);
            setActiveStep(1);
          }}
        />
      ),
    },
    {
      label: "Informations sur l'opération",
      content: (
        <OperationInfosFrom
          value={operationInfos}
          next={(opInfos) => {
            setOperationInfos(opInfos);
            setActiveStep(2);
          }}
        />
      ),
    },
    {
      label: "Financement de l'opération",
      content: (
        <FinancementOpForm
          value={financementOpInfos}
          next={(info) => {
            setFinancementOpInfos(info);
            setActiveStep(3);
          }}
        />
      ),
    },
    {
      label: "Intervenants",
      content: (
        <IntervenantsForm
          value={intervenantsInfos}
          next={(info) => {
            setIntervenantsInfos(info);
            setActiveStep(4);
          }}
        />
      ),
    },
    {
      label: "Garanties obligatoires déjà souscrites",
      content: (
        <GarantiesForm
          infos={questionnaire}
          value={garantiesInfos}
          next={(info) => {
            setGarantiesInfos(info);
            setSaveForm(true);
            setActiveStep(5);
          }}
        />
      ),
    },
    {
      label: "Envoi des documents",
      content: (
        <DocumentStep
          infos={questionnaire}
          value={garantiesInfos}
          //@ts-ignore
          next={(info) => {
            setGarantiesInfos(info);
          }}
          handleFilesChange={handleFilesChange}
          handleRemoveFile={handleRemoveFile}
          reqRetries={reqRetries}
          docs={companyDocs}
          readOnly={readOnly}
        />
      ),
    },
  ];

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        minWidth: "100%",
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          minWidth: "80%",
          paddingRight: 0,
        }}
      >
        <div className="transparent-card">
          <Box style={{ padding: "24px", paddingBottom: 60 }}>
            <Typography
              variant="h3"
              align="center"
              style={{ paddingBottom: "30px" }}
            >
              Fiche de présentation GFA
            </Typography>
            <Typography align="left" paragraph={true} variant="body1">
              Ce document reprend les principaux éléments du risque. Il nous
              permettra de vous faire une offre tarifaire avec conditions de
              mise en place sous réserve de validation et d’acceptation du
              risque par l’assureur et de l’obtention des documents d’étude.
            </Typography>
          </Box>
          <Box sx={{ width: "100" }}>
            <Paper elevation={3}>
              <Typography
                variant="h4"
                align="center"
                style={{ paddingBottom: "10px" }}
              >
                Notice d'utilisation
              </Typography>{" "}
              <p style={{ padding: "10px", width: "100%" }}>
                Remplissez le questionnaire ci-dessous puis joignez les
                documents nécessaire à l'étude de votre demande puis cliquez sur
                "Demander la validation".
                <br />
                <br />A tout moment vous pouvez enregistrer le questionnaire
                pour le modifier plus tard en cliquant sur le bouton
                "enregistrer" situé à droite. Vous retrouverez votre
                questionnaire dans votre portefeuille GFA.
                <br />
                <br />
                Après la demande de validation de votre dossier par l'assureur,
                vous ne pourrez plus modifier le questionnaire ni les documents
                avant la réception de la réponse de celui-ci.
                <br />
              </p>
            </Paper>
          </Box>
          {!loading && (
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((s, index) => {
                if (index === 5) {
                  return (
                    <Step
                      key={s.label}
                      active={readOnly || activeStep === index}
                    >
                      <StepLabel>
                        <Button onClick={handleStep(index)}>{s.label}</Button>
                      </StepLabel>
                      <StepContent>
                        <legend>{s.content}</legend>
                      </StepContent>
                    </Step>
                  );
                }
                return (
                  <Step key={s.label} active={readOnly || activeStep === index}>
                    <StepLabel>
                      <Button onClick={handleStep(index)}>{s.label}</Button>
                    </StepLabel>
                    <StepContent>
                      <fieldset disabled={readOnly}>{s.content} </fieldset>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          )}
        </div>
      </Container>

      <Container style={{ paddingLeft: 0, minWidth: "20%" }}>
        <Box display="flex" justifyContent="center" paddingBottom={"30px"}>
          {!readOnly ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (!readOnly) setSaveForm(true);
              }}
            >
              ENREGISTRER
            </Button>
          ) : null}
        </Box>
        <Box
          style={{
            minWidth: "30%",
          }}
        >
          <ProposalTimeline
            //@ts-ignore
            key={`${questionnaire.gfaFormId}`}
            proposal={questionnaire}
            onChange={refreshProposal}
            userRoles={userRoles}
            companyDocsLoaded={
              companyDocs === undefined || companyDocs.length === 0
                ? false
                : true
            }
            setReadOnly={setReadOnly}
          />
        </Box>
      </Container>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showDialog.show}
        onClose={() => setShowDialog({ ...showDialog, show: false })}
        autoHideDuration={6000}
      >
        <Alert
          severity={showDialog.type}
          onClose={() => setShowDialog({ ...showDialog, show: false })}
        >
          {showDialog.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
type StatusChangeProps = {
  companyDocs?: DocumentItem[];
  companyDocsLoaded?: Boolean;
  userRoles?: Role[];
  proposal: ProposalType;
  onChange: () => void;
};

export function DocumentStep({
  infos,
  value,
  next,
  handleFilesChange,
  handleRemoveFile,
  reqRetries,
  docs,
  readOnly,
}: { infos: Questionnaire } & StepParams<GarantiesInfos> & any) {
  //@ts-ignore
  const RemoveIcon = ({ index }) => {
    if (readOnly) return null;
    return (
      <>
        <IconButton
          sx={{ padding: "0px" }}
          onClick={() => handleRemoveFile(index)}
        >
          <CloseIcon />
        </IconButton>
      </>
    );
  };
  return (
    <>
      <Box sx={{ width: "100" }}>
        <Paper elevation={3}>
          <Typography
            variant="h4"
            align="center"
            style={{ paddingBottom: "10px" }}
          >
            Liste des documents à joindre en fonction de vos réponses :
          </Typography>{" "}
          <p style={{ padding: "10px", width: "100%" }}>
            - Etats patrimoniaux des dirigeants
            <br />
            - Liasse fiscale avec annexe de la société porteuse de projet.
            <br />
            - Liasse fiscale de holding / des holdings (si holding ou maison
            mère)
            <br />
            - Planning de trésorerie
            <br />
            - Bilan financier de l'opération
            <br />
            - Bilan consolidé (si groupe consolidant)
            <br />
            - Organigramme (si groupe)
            <br />
            - Permis de construire
            <br />
            - Statuts, KBIS (société porteuse et société holding)
            <br />
            - Jeu de plans avec tableau des surfaces accompagné de la notice
            descriptive
            <br />
            - Les plans d’exécution des travaux : masse, niveaux (sous-sol, rdc,
            étage, façades, coupes)
            <br />- Expérience du promoteur
          </p>
        </Paper>
      </Box>
      <Box mt={2} mb={2}>
        {infos.gfaFormId && reqRetries <= 3 ? (
          <span>Récupération des documents déjà envoyés en cours:</span>
        ) : null}
        {infos.gfaFormId && reqRetries <= 3 ? (
          <LinearProgressWithLabel value={(reqRetries / 3) * 100} />
        ) : null}
      </Box>
      <Box>
        <h3>Documents échangés avec Astimm</h3>
        <ul>
          {docs.length !== 0 ? (
            docs.map((e: FileItem | DocumentItem, index: any) => (
              <li>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Downloadable item={e} />
                  {/*
                    //@ts-ignore*/}
                  <RemoveIcon readOnly={infos.docsReadOnly} index={index} />
                </Box>
              </li>
            ))
          ) : (
            <span>Vous n'avez pas encore envoyé ni reçu de document.</span>
          )}
        </ul>
      </Box>
      <Box paddingBottom={3}>
        <FileUpload
          multiFile={true}
          disabled={false}
          title="Envoyez les documents de votre client"
          header="Déposez"
          leftLabel="ou"
          rightLabel="pour rechercher un fichier (jpg, pdf, jpeg, docx, xls, xlsx, txt, odt, ods, max 10Mo) sur votre ordinateur "
          buttonLabel="cliquez ici"
          // buttonRemoveLabel="Effacer tous"
          maxFileSize={10}
          maxUploadFiles={5}
          maxFilesContainerHeight={357}
          errorSizeMessage={"erreur lors de l'envoi"}
          allowedExtensions={[
            "jpg",
            "pdf",
            "jpeg",
            "docx",
            "xls",
            "xlsx",
            "txt",
            "odt",
            "ods",
          ]}
          onFilesChange={handleFilesChange}
          onError={() => console.log("error")}
          // bannerProps={{ elevation: 0, variant: "outlined" }}
          //containerProps={{ elevation: 0, variant: "outlined" }}
        />
      </Box>
    </>
  );
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "50%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function NextStepAction({
  userRoles,
  proposal,
  onChange,
  companyDocsLoaded,
  setReadOnly,
}: StatusChangeProps & any) {
  let s = currentStatus(proposal).tag;
  if (s === "open" || s === "invalid_docs") {
    return (
      <SubmitDocs
        key={`${proposal.gfaFormId}-${s}`}
        proposal={proposal}
        onChange={onChange}
        companyDocsLoaded={companyDocsLoaded}
        setReadOnly={setReadOnly}
      />
    );
  } else if (s === "checking_docs") {
    return (
      <AdminCheckDocsResult
        key={`${proposal.gfaFormId}-${s}`}
        proposal={proposal}
        onChange={onChange}
        userRoles={userRoles}
      />
    );
  } else {
    return <FinalProposalStatus proposal={proposal} />;
  }
}
function ProposalTimeline({
  userRoles,
  proposal,
  onChange,
  companyDocsLoaded,
  setReadOnly,
}: StatusChangeProps & any) {
  const classes = useTimelineStyles();
  return (
    <Timeline key={`${proposal.gfaFormId}`}>
      {!finalStatus(proposal) ? (
        <TimelineItem key={`${proposal.gfaFormId}`}>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary">
              <AddIcon color="primary" />
            </TimelineDot>
            <TimelineConnector className={classes.primaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <NextStepAction
              proposal={proposal}
              onChange={onChange}
              userRoles={userRoles}
              companyDocsLoaded={companyDocsLoaded}
              setReadOnly={setReadOnly}
            />
          </TimelineContent>
        </TimelineItem>
      ) : null}
      {
        //@ts-ignore
        proposal.status.map((s) => {
          return (
            <TimelineStatusItem
              key={`${proposal.gfaFormId}-${s.updated_at}`}
              status={s}
              brokerEmail={proposal.brokerEmail}
              brokerFullName={proposal.brokerFullName}
            />
          );
        })
      }
    </Timeline>
  );
}

const useTimelineStyles = makeStyles((theme) => ({
  primaryTail: {
    backgroundColor: theme.palette.primary.main,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function TimelineStatusItem({
  status,
  brokerFullName,
}: {
  status: ProposalStatus;
  brokerEmail: string;
  brokerFullName: string;
}) {
  const classes = useTimelineStyles();
  const tag = status.tag;
  const date = new Date(statusUpdate(status) || Date.now());

  if (tag === "open") {
    return (
      <TimelineItem key={`${tag}`}>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <FiberNewIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} style={{ padding: "5px 3px" }}>
            <Box>
              <Typography variant="body2" gutterBottom>
                <b>Création</b>
              </Typography>
              <Typography variant="caption" gutterBottom>
                <p>Proposition réalisée par {brokerFullName}.</p>
              </Typography>
            </Box>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "pending") {
    return (
      <TimelineItem key={`${tag}-${status.updated_at}`}>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <HourglassEmptyIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} style={{ padding: "5px 3px" }}>
            <Typography variant="body2">
              <b>Contrat demandé, en attente des documents client</b>
            </Typography>
            <Typography variant="caption">
              Commence le{" "}
              {new Date((status as any).starts_at).toLocaleDateString("fr-FR")}
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "checking_docs") {
    return (
      <TimelineItem key={`${tag}-${status.updated_at}`}>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <FiberNewIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} style={{ padding: "5px 3px" }}>
            <Typography variant="body2">
              <b>Validation du dossier par l'assureur en cours</b>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "invalid_docs") {
    return (
      <TimelineItem key={`${tag}-${status.updated_at}`}>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot>
            <FiberNewIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={1} style={{ padding: "5px 3px" }}>
            <Typography variant="body2">
              <b>Echec de la validation</b>
            </Typography>
            <Typography variant="caption">
              {/*
              //@ts-ignore*/}
              <p>{status.admin_message}</p>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "signed") {
    return (
      <TimelineItem key={`${tag}-${status.updated_at}`}>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot color="primary">
            <CheckCircleIcon />
          </TimelineDot>
          <TimelineConnector className={classes.primaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Chip
            style={{ position: "relative", bottom: "-2px" }}
            label="Signé"
            // icon={<CheckCircleIcon/>}
            color="primary"
          />
        </TimelineContent>
      </TimelineItem>
    );
  } else if (tag === "canceled") {
    return (
      <TimelineItem key={`${tag}-${status.updated_at}`}>
        {timelineStatusUpdate(date)}
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <CancelIcon />
          </TimelineDot>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Chip
            style={{ position: "relative", bottom: "-2px" }}
            label="Proposition annulée"
            // icon={<CancelIcon/>}
            color="secondary"
          />
        </TimelineContent>
      </TimelineItem>
    );
  } else {
    return null;
  }
}

function timelineStatusUpdate(date: Date) {
  let dateDay = date.toLocaleDateString("fr-FR");
  return (
    <TimelineOppositeContent>
      <Typography variant="caption" color="textSecondary">
        {dateDay === "Invalid Date" ? null : dateDay} <br />
        {dateDay === "Invalid Date"
          ? null
          : date.toLocaleTimeString("fr-FR", {
              hour: "numeric",
              minute: "2-digit",
            })}
      </Typography>
    </TimelineOppositeContent>
  );
}

function SubmitDocs({
  proposal,
  onChange,
  companyDocsLoaded,
  setReadOnly,
}: StatusChangeProps | any) {
  const { requestCheckDocsGfa } = useAPI();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleConfirm = () => {
    setError(false);
    proposal.docsReadOnly = true;
    requestCheckDocsGfa(proposal.gfaFormId)
      .then(() => {
        setOpen(false);
        onChange();
      })
      // TODO handle error reposonse
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  //if(companyDocs === undefined || proposal === undefined) return null
  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Demander la validation de votre dossier.
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <b>
            Ceci déclenchera la vérification des pièces de votre client par
            notre gestionnaire.
          </b>
          <p>
            Veuillez vérifier que vous avez bien joint les documents
            nécessaires.
          </p>
          <p>
            Suite à cette demande vous ne pourrez plus modifier la proposition
            ainsi que les documents joints.
          </p>
          <p>
            Après vérification des pièces et signature par nos services vous
            serez informé par e-mail.
          </p>
          {!companyDocsLoaded ? (
            <p style={{ color: "red" }}>Aucun document ajouté !</p>
          ) : null}
          {error ? (
            <Alert severity="error">API error. Please try again later.</Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button
            color="primary"
            onClick={() => handleConfirm()}
            disabled={!companyDocsLoaded}
          >
            Je confirme
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function AdminCheckDocsResult({
  userRoles,
  proposal,
  onChange,
}: StatusChangeProps & any) {
  const { signContractGfa, invalidateDocsGfa } = useAPI();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [adminMessage, setAdminMessage] = React.useState("");

  const handleAdminMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdminMessage(event.target.value);
  };

  const handleConfirm = () => {
    setError(false);
    signContractGfa(proposal.gfaFormId)
      .then(() => {
        setOpen(false);
        onChange();
      })
      // TODO handle error response
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  const handleRejectProposal = () => {
    setError(false);
    invalidateDocsGfa({
      gfaFormId: proposal.gfaFormId,
      admin_message: adminMessage,
    })
      .then(() => {
        setOpen(false);
        onChange();
      })
      // TODO handle error reposonse
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };
  return userRoles?.includes("Admin") || userRoles?.includes("GFA admin") ? (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        <Typography variant="body1">
          <b>Répondre au courtier</b>
        </Typography>
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <p>
            Si le dossier est conforme, pensez à uploader le contrat signé par
            l'assureur et les pièces à retourner au courtier avant de cliquer
            sur 'Confirmer'.
          </p>
          <p>
            En cas de rejet, pensez à indiquer la raison du rejet en remplissant
            le champ ci-dessous avant de cliquer sur 'Rejeter'.
          </p>
          <TextField
            fullWidth
            id="standard-multiline-static"
            label="Votre message au courtier"
            multiline
            rows={4}
            placeholder="écrire ici"
            variant="outlined"
            onChange={handleAdminMessageChange}
          />
          {error ? (
            <Alert severity="error">API error. Please try again later.</Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Annuler
          </Button>
          <Button color="primary" onClick={() => handleConfirm()}>
            Confirmer et envoyer les données dans ADHOC
          </Button>
          <Button color="primary" onClick={() => handleRejectProposal()}>
            Rejeter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
}
