import { Box, FormControlLabel } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import { useState } from "react";
import { GarantiesInfos, Questionnaire, StepParams } from "./types";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export const emptyGarantiesInfo: GarantiesInfos = {
  DO: null as boolean | null,
  TRC: null as boolean | null,
  CNR: null as boolean | null,
  RCMO: null as boolean | null,
  nomAssureurDO: "",
  nomAssureurTRC: "",
  nomAssureurCNR: "",
  nomAssureurRCMO: "",
};

export function GarantiesForm({
  infos,
  value,
  next,
}: { infos: Questionnaire } & StepParams<GarantiesInfos>) {
  const [DO, setDO] = useState(value.DO);
  const [TRC, setTRC] = useState(value.TRC);
  const [CNR, setCNR] = useState(value.CNR);
  const [RCMO, setRCMO] = useState(value.RCMO);
  const [nomAssureurDO, setnomAssureurDO] = useState(value.nomAssureurDO);
  const [nomAssureurTRC, setnomAssureurTRC] = useState(value.nomAssureurTRC);
  const [nomAssureurCNR, setnomAssureurCNR] = useState(value.nomAssureurCNR);
  const [nomAssureurRCMO, setnomAssureurRCMO] = useState(value.nomAssureurRCMO);
  let garantiesInfos: GarantiesInfos = {
    DO,
    TRC,
    CNR,
    RCMO,
    nomAssureurDO,
    nomAssureurTRC,
    nomAssureurCNR,
    nomAssureurRCMO,
  };

  return (
    <Box>
      <form className="basic-info" noValidate autoComplete="off">
        <Grid item xs={12} md={6} mt={2}>
          <Typography>DO:</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={DO === true}
                color="primary"
                onChange={() => setDO(true)}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={DO === false}
                color="primary"
                onChange={() => setDO(false)}
              />
            }
            label="NON"
          />
        </Grid>
        {DO === true && (
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" alignContent={"center"}>
              <Typography
                pr="5px"
                sx={{
                  whiteSpace: "nowrap",
                  alignSelf: "flex-end",
                }}
              >
                Nom de l' assureur :
              </Typography>
              <TextField
                type="text"
                value={nomAssureurDO}
                onChange={(event) => setnomAssureurDO(event.target.value)}
                InputProps={{
                  sx: {
                    "& .MuiInput-input": {
                      paddingBottom: 0,
                    },
                  },
                }}
                sx={{
                  paddingLeft: 2,
                }}
                variant="standard"
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6} mt={2}>
          <Typography>TRC :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={TRC === true}
                color="primary"
                onChange={() => setTRC(true)}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={TRC === false}
                color="primary"
                onChange={() => setTRC(false)}
              />
            }
            label="NON"
          />
        </Grid>
        {TRC === true && (
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" alignContent={"center"}>
              <Typography
                pr="5px"
                sx={{
                  whiteSpace: "nowrap",
                  alignSelf: "flex-end",
                }}
              >
                Nom de l' assureur :
              </Typography>
              <TextField
                type="text"
                value={nomAssureurTRC}
                onChange={(event) => setnomAssureurTRC(event.target.value)}
                InputProps={{
                  sx: {
                    "& .MuiInput-input": {
                      paddingBottom: 0,
                    },
                  },
                }}
                sx={{
                  paddingLeft: 2,
                }}
                variant="standard"
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6} mt={2}>
          <Typography>CNR :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={CNR === true}
                color="primary"
                onChange={() => setCNR(true)}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={CNR === false}
                color="primary"
                onChange={() => setCNR(false)}
              />
            }
            label="NON"
          />
        </Grid>
        {CNR === true && (
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" alignContent={"center"}>
              <Typography
                pr="5px"
                sx={{
                  whiteSpace: "nowrap",
                  alignSelf: "flex-end",
                }}
              >
                Nom de l' assureur :
              </Typography>
              <TextField
                type="text"
                value={nomAssureurCNR}
                onChange={(event) => setnomAssureurCNR(event.target.value)}
                InputProps={{
                  sx: {
                    "& .MuiInput-input": {
                      paddingBottom: 0,
                    },
                  },
                }}
                sx={{
                  paddingLeft: 2,
                }}
                variant="standard"
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} md={6} mt={2}>
          <Typography>RCMO :</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={RCMO === true}
                color="primary"
                onChange={() => setRCMO(true)}
              />
            }
            label="OUI"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={RCMO === false}
                color="primary"
                onChange={() => setRCMO(false)}
              />
            }
            label="NON"
          />
        </Grid>
        {RCMO === true && (
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" alignContent={"center"}>
              <Typography
                pr="5px"
                sx={{
                  whiteSpace: "nowrap",
                  alignSelf: "flex-end",
                }}
              >
                Nom de l' assureur :
              </Typography>
              <TextField
                type="text"
                value={nomAssureurRCMO}
                onChange={(event) => setnomAssureurRCMO(event.target.value)}
                InputProps={{
                  sx: {
                    "& .MuiInput-input": {
                      paddingBottom: 0,
                    },
                  },
                }}
                sx={{
                  paddingLeft: 2,
                }}
                variant="standard"
              />
            </Box>
          </Grid>
        )}
      </form>
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          //disabled={!valid()}
          startIcon={<NavigateNextIcon />}
          onClick={() => next(garantiesInfos)}
        >
          Continuer
        </Button>
      </Box>
    </Box>
  );
}
