import { Decimal } from "decimal.js";

export type HomeProps = {
  roles: Role[];
};
export type CostsOperation = {
  id: number | null;
  description: string | null;
  HT: string | null;
  TTC: string | null;
};
export type CompanyInfoINSEE = {
  name: string;
  address: string;
  typeVoie: string;
  postalCode: string;
  city: string;
  codeAPE: string;
};

export type LegalForm =
  | "SARL"
  | "SA"
  | "SAS+SASU"
  | "SNC"
  | "Scop"
  | "SCA+SCS"
  | "";

export function showLegalForm(f: LegalForm) {
  switch (f) {
    case "SARL":
      return "Société à responsabilité limitée (SARL)";
    case "SA":
      return "Société anonyme (SA)";
    case "SAS+SASU":
      return "Société par actions simplifiée (SAS) ou société par actions simplifiée unipersonnelle (SASU)";
    case "SNC":
      return "Société en nom collectif (SNC)";
    case "Scop":
      return "Société coopérative de production (Scop)";
    case "SCA+SCS":
      return "Société en commandite par actions (SCA) et société en commandite simple (SCS)";
  }
  return f;
}

export type CompanyRepresentant = {
  genderTitle: string;
  firstname: string;
  lastname: string;
  role: string;
  telephone: string;
  email: string;
};

export type CompanyInfo = {
  siret: string;
  name: string;
  legalForm: LegalForm;
  representant: CompanyRepresentant;
  address: string;
  postalCode: string;
  city: string;
  codeAPE: CodeType;
  codeCCN: CodeType;
};

export type Partner = {
  name: string;
  shares: string;
  siret: string;
};

export type Operation = {
  id: number | null;
  name: string | null;
  address: string | null;
  CATTC: string | null;
  costRev: number | null;
  costConstruct: number | null;
  logNum: string | null;
  finishDate: string | null;
  surface: number | null;
} | null;

export type PromoteurInfo = {
  raisonSociale: string | null;
  dedicatedCompany: boolean | null;
  siret: string | null;
  partners: Partner[] | null;
  partnersOpHistory: Operation[] | null;
  otherExp: string | null;
};

export type SplittingType =
  | "ensemble"
  | "agirc-non-agirc"
  | "ani-non-ani"
  | null;

export type DemographicsSpec = { personnes: string; structure: string };

export type BeneficientsInput = {
  splitting: SplittingType;
  ensemble: DemographicsSpec;
  agirc: DemographicsSpec;
  nonAgirc: DemographicsSpec;
  ani: DemographicsSpec;
  nonAni: DemographicsSpec;
};

export type FormulasInput = {
  ensemble: Formula | null;
  agirc: Formula | null;
  nonAgirc: Formula | null;
  ani: Formula | null;
  nonAni: Formula | null;
};
export type PaiementDiff = {
  amount: string;
  date: string;
};

export type FinancementOp = {
  appFdsPrpres: boolean | null;
  provFds: string;
  credTerr: boolean | null;
  montant: string;
  dateEch: string;
  nomBanqueCredTerr: string;
  credProm: boolean | null;
  montantCredProm: string;
  dateEchCredProm: string;
  nomBanqueCredProm: string;
  crowFund: boolean | null;
  montantCrowFund: string;
  dateEchCrowFund: string;
  nomPlateformeCrowFund: string;
  pretPriv: boolean | null;
  montantPretPriv: string;
  dateEchPretPriv: string;
};

export type OperationInfos = {
  opName: string;
  addressContruction: string;
  typeContrat: string;
  numPC: string;
  numPCmodif: string;
  numPCTrans: string;
  dateNumPC: string;
  dateNumPCmodif: string;
  dateNumPCTrans: string;
  typeConstruct: string[];
  nbrBat: string;
  nbrTerr: string;
  nbrBurCom: string;
  nbrLog: string;
  nbrEtage: string;
  surfacePlancher: string;
  dateOuvChantier: string;
  dateFinTrav: string;
  dateDemChantier: string;
  dureeTrav: string;
  chantierDem: boolean | null;
  foncierPaye: boolean | null;
  creditAcFoncier: boolean | null;
  montantCreditAcFoncier: string | null;
  dation: boolean | null;
  montantDation: string;
  payDiff: boolean | null;
  paiementDiff: PaiementDiff;
  costsHTandTTC: CostsOperation[] | null;
};

export type GarantiesInfos = {
  DO: boolean | null;
  TRC: boolean | null;
  CNR: boolean | null;
  RCMO: boolean | null;

  nomAssureurDO: string;
  nomAssureurTRC: string;
  nomAssureurCNR: string;
  nomAssureurRCMO: string;
};

export type Questionnaire = {
  status: ProposalStatus[];
  promoteur: PromoteurInfo;
  operation: OperationInfos;
  financement: FinancementOp;
  intervenants: IntervenantsInfo;
  garanties: GarantiesInfos;
  brokerEmail: string;
  brokerMetadata: Record<string, unknown>
  brokerFullName: string;
  docsReadOnly: boolean;
  gfaFormId: null | string;
};

export type IntervenantsInfo = {
  typeEntrGrosOeuvre: string;
  marcheDejaPass: boolean | null;
  nomEntr: string;
  sirenMaitr: string;
  nomMaitre: string;
  missionComplete: boolean | null;
  suiviTrav: boolean | null;
  sirenEntr: string;
  constatAchev: boolean | null;
  validcompte: boolean | null;
  nomPersHabilite: string;
};
export type PaymentDetails = {
  declareMode: "DSN" | "hors DSN" | boolean;
  paymentMethod: "chèque" | "virement" | "prélévement" | boolean;
};

export type Formula =
  | { name: "basic" }
  | { name: "standard" }
  | { name: "premium"; optionRenfort: boolean }
  | { name: "ultimate"; optionRenfort: boolean };

export function renfort(f: Formula): boolean {
  return f.name === "premium" || f.name === "ultimate"
    ? f.optionRenfort
    : false;
}

export type StructureOptions = {
  ensemble: string[];
  agirc: string[];
  nonAgirc: string[];
  ani: string[];
  nonAni: string[];
};

export type OfferListParams = {
  postalCode: string;
  codeCCN: CodeType;
  structures: ByCollege<string>;
};

export type OfferPriceItem = {
  beneficient: string;
  price: string;
  basePrice: string;
  ppmss: string;
  basePPMSS: string;
};
export type Offer = {
  formula: Formula;
  prices: OfferPriceItem[];
  regime?: string;
};
export type OfferList = ByCollege<Offer[]>;
export type Offers = {
  offers: OfferList;
  assistance: string;
};

export type SereniteParams = { size: number };
export type SerenitePrice = { price: string };

export type ByCollege<T> = {
  college: keyof StructureOptions;
  value: T;
}[];

export type JWTToken = {
  aud: string[];
  azp: string;
  exp: number;
  https: string;
  iat: number;
  iss: string;
  permissions: Permission[];
  scope: string;
  sub: string;
};

export type ProposalParams = {
  postalCode: string;
  codeCCN: CodeType;
  companySize: ByCollege<number>;
  optionSerenite: boolean;
  commission?: string;
  formula: ByCollege<{ formula: Formula; structure: string }>;
  paymentDetails: PaymentDetails;
};

export type Rate = {
  beneficient: string;
  price: string;
  ppmss: string;
  booster?: string;
  boosterPPMSS?: string;
};

export function totalRatePrice({ price, booster }: Rate): string {
  if (booster) {
    let x = new Decimal(price);
    let y = new Decimal(booster);
    return x.add(y).toString();
  } else {
    return price;
  }
}

export function totalRatePPMSS({ ppmss, boosterPPMSS }: Rate): string {
  if (boosterPPMSS) {
    let x = new Decimal(ppmss);
    let y = new Decimal(boosterPPMSS);
    return x.add(y).toString();
  } else {
    return ppmss;
  }
}

export type RatesSpec = {
  rates: Rate[];
  formula: Formula;
  regime: string;
};

export type Prices = {
  rates: ByCollege<RatesSpec>;
  optionSerenite?: string;
  assistance: string;
};

export type StepParams<T> = { value: T; next: (x: T) => void };

export type FormParams<T> = {
  value: T;
  onChange: React.Dispatch<React.SetStateAction<T>>;
};

export type CodeType = { code: string; description: string };

export type Proposal = {
  proposalId: string;
  status: ProposalStatus[];
  params: ProposalParams;
  prices: Prices;
  company: CompanyInfo;
  brokerEmail: string;
  brokerFullName: string;
  propDocsLength: number;
  contractDocsLength: number;
  docsReadOnly: boolean;
  expirationDate?: string;
};

export function finalStatus(p: Proposal | Questionnaire) {
  let s = currentStatus(p);
  return s.tag === "canceled" || s.tag === "signed";
}

export function currentStatus(p: Proposal | Questionnaire) {
  return p.status[0];
}

export function lastUpdate(p: Proposal | Questionnaire) {
  return statusUpdate(currentStatus(p));
}

export function statusUpdate(s: ProposalStatus) {
  return s.tag === "open" ? s.created_at : s.updated_at;
}

export function statusColor(status: ProposalStatus) {
  if (status.tag === "open") {
    return "default";
  } else if (status.tag === "canceled") {
    return "default";
  } else if (status.tag === "pending") {
    return "primary";
  } else if (status.tag === "signed") {
    return "default";
  } else {
    return "default";
  }
}

export function showStatus({ tag }: ProposalStatus) {
  if (tag === "open") {
    return "Nouveau";
  } else if (tag === "pending") {
    return "Contrat demandé";
  } else if (tag === "signed") {
    return "Signé";
  } else if (tag === "canceled") {
    return "Cancelé";
  } else {
    return tag;
  }
}

export type NewProposal = Omit<Proposal, "proposalId" | "prices" | "status">;

export type ProposalKey = { broker: string; siret: string };

export type ProposalStatus =
  | { tag: "open"; created_at: string } // when a broker performs a commercial proposition
  | { tag: "canceled"; updated_at: string } // when a broker cancels a commercial proposition
  | { tag: "pending"; starts_at: string; updated_at: string }
  | { tag: "checking_docs"; starts_at: string; updated_at: string }
  | {
      tag: "invalid_docs";
      starts_at: string;
      updated_at: string;
      admin_message: string;
      result: string;
    }
  | { tag: "signed"; starts_at: string; updated_at: string };

export type CreateProposalStatus =
  | "new" // created new proposal
  | "saved" // proposal is already saved
  | "updated"; // saved proposal has been updated

export type Permission =
  | "cancel:own:proposal"
  | "cancel:any:proposal"
  | "create:own:proposal"
  | "create:any:proposal"
  | "list:any:proposal"
  | "list:own:proposals"
  | "read:any:proposal"
  | "read:own:proposal"
  | "request:own:contract"
  | "request:any:contract"
  | "signed:own:contract"
  | "signed:any:contract"
  | "access:product:gfa"
  | "admin:gfa"
  | any;

export type Role =
  | "Active Broker"
  | "Admin"
  | "Broker"
  | "GFA broker"
  | "GFA admin"
  | "None";

export type FileItem = {
  name: string;
  items?: FileItem[];
  url?: string | null;
};

export type DocumentItem = {
  name: string;
  is_proposal_doc?: boolean;
  status: "error" | "ok";
  url: string;
};

export type DownloadItem = { name: string; blob: Blob };

export type APIData<T> =
  | { status: "ok"; response: T }
  | { status: "waiting" }
  | { status: "error" };

export const waiting = <T>(): APIData<T> => ({ status: "waiting" });
