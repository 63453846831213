import {
  CompanyInfo as CompanyInfoType,
  Formula,
  Prices,
  ProposalParams,
  RatesSpec,
  renfort,
  StructureOptions,
  totalRatePPMSS,
  totalRatePrice,
  lastUpdate,
  Proposal as ProposalType,
} from "./types";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Slider } from "@mui/material";
import { Color } from "@material-ui/lab/Alert/Alert";
import { Assistance, Price } from "./Proposal/Price";
import { ReactNode, useEffect, useMemo, useCallback } from "react";
import { OfferDetails, RenfortDetails } from "./OfferDetails";
import SanteSrc from "../assets/logo-sante/LOGO-SVA-RVB.svg";
import React from "react";
import { useAPI } from "../api";
import SendIcon from "@material-ui/icons/Send";
import { useAuth0 } from "@auth0/auth0-react";
import Alert from "@material-ui/lab/Alert";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useHistory } from "react-router-dom";

type SaveStatus = {
  type: Color;
  show: boolean;
  message: string;
};

export function NewProposal({
  params,
  company,
}: {
  params: ProposalParams;
  company: CompanyInfoType;
}) {
  const history = useHistory();
  const { listProposals } = useAPI();
  const { user } = useAuth0();
  const [commission, setCommission] = React.useState(0.12);
  const [rates, setRates] = React.useState(undefined as Prices | undefined);
  const { getProposalPrices: getProposal, saveProposal } = useAPI();
  const [showDialog, setShowDialog] = React.useState({
    show: false,
    message: "",
    type: "info",
  } as SaveStatus);

  const proposal = useMemo(
    () => ({ ...params, commission: commission.toFixed(2) }),
    [params, commission]
  );
  const brokerEmail = user ? (user.email as string) : "";
  const brokerFullName = user ? (user.name as string) : "";
  const jumpToProposal = useCallback(
    (propID: string) => history.push(`/proposal/${propID}`),
    [history]
  );

  useEffect(() => {
    getProposal(proposal)
      .then(setRates)
      .catch((err) => {
        console.log(err); // TODO show error to the user
        setRates(undefined);
      });
    // eslint-disable-next-line
  }, [proposal]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        mb={3}
      >
        <CompanyInfo info={company} />
        <Box>
          <img src={SanteSrc} height="80px" alt="Santé" />
        </Box>
      </Box>
      <PricesList prices={rates} />

      <Box mt={3} alignItems="end">
        <Box mt={7} style={{ width: "200px", marginBottom: "-10px" }}>
          <Slider
            value={commission}
            onChange={(_, v) => setCommission(v as number)}
            valueLabelDisplay="on"
            valueLabelFormat={(p) => `${Math.trunc(p * 100)}%`}
            min={0}
            max={0.12}
            step={0.01}
          />
        </Box>
        <Typography variant="body2">Ajuster la commission</Typography>
      </Box>

      <Box mt={3} style={{ maxWidth: "80ex" }}>
        <Typography variant="body1" paragraph>
          Le contrat de base offre des avantages fiscaux et sociaux tant pour
          l’entreprise que pour les salariés.
        </Typography>

        <Typography variant="body1" paragraph>
          <b>Pour l’employeur :</b> Les cotisations versées pour la protection
          sociale complémentaire des salariés sont déductibles de l’impôt des
          sociétés et exonérés de charges sociales, dans la limite de plafonds
          définis.
        </Typography>

        <Typography variant="body1" paragraph>
          <b>Pour le salarié :</b> Sa contribution dans le financement de sa
          protection sociale est déductible de son revenu imposable (hors
          contribution patronale qui elle est réintégrée dans le salaire).
        </Typography>
      </Box>

      <Box
        ml={1}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        mt={5}
      >
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            onClick={() => {
              saveProposal({
                params: proposal,
                company: company,
                brokerEmail,
                brokerFullName,
                contractDocsLength: 0,
                propDocsLength: 0,
                docsReadOnly: false,
              })
                .then(() =>
                  setShowDialog({
                    show: true,
                    message: `Votre proposition a été correctement enregistrée`,
                    type: "success",
                  })
                )
                .then(() => {
                  listProposals().then((p): void => {
                    const proposals: ProposalType[] = p.sort((a, b) =>
                      lastUpdate(a) < lastUpdate(b) ? 1 : -1
                    );
                    jumpToProposal(proposals[0].proposalId);
                  });
                })
                .catch(() => {
                  setShowDialog({
                    show: true,
                    message: `L'enregistrement de la proposition a échoué`,
                    type: "error",
                  });
                });
            }}
          >
            Enregistrer la proposition
          </Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showDialog.show}
        onClose={() => setShowDialog({ ...showDialog, show: false })}
        autoHideDuration={6000}
      >
        <Alert
          severity={showDialog.type}
          onClose={() => setShowDialog({ ...showDialog, show: false })}
        >
          {showDialog.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

const useCompanyStyles = makeStyles((theme) => ({
  dimmed: {
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
  company: {
    fontSize: "22px",
    fontWeight: 400,
  },
  representant: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "all .5s ease-in",
    "&:hover": {
      textDecoration: "underline",
      textUnderlineOffset: "3px",
    },
  },
}));

export function CompanyInfo({ info }: { info: CompanyInfoType }) {
  const classes = useCompanyStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box className={classes.company}>{info.name}</Box>
          <Box className={classes.dimmed} mb={2}>
            <div>{info.address}</div>
            <div>
              {info.postalCode} {info.city}
            </div>
          </Box>
          <Box className={classes.dimmed}>représenté par</Box>
          <Box>
            <a
              className={classes.representant}
              href={`mailto:${info.representant.email}`}
              onClick={(e) => e.stopPropagation()}
            >
              {info.representant.firstname} {info.representant.lastname}
            </a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export function PricesList({ prices }: { prices: Prices | undefined }) {
  return prices ? (
    <Grid container spacing={3}>
      {prices.rates.map((r) => (
        <React.Fragment>
          {/* <Typography variant="h5">{collegeTitle(r.college)}</Typography> */}
          <Grid item xs={12} md={6}>
            <PriceCard
              rates={r.value}
              college={r.college}
              assistance={prices.assistance}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  ) : (
    <Alert severity="error">Error getting response from api</Alert>
  );
}

type PriceCardProps = {
  rates: RatesSpec;
  assistance: string;
  college: keyof StructureOptions;
  details?: boolean;
  elevation?: number;
};

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: "30ex",
  },
  header: {
    color: "#fff",
    backgroundColor: theme.palette.primary.dark,
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  college: {
    maxWidth: "40%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

/** Formats prices for specific formula */
export function PriceCard({
  rates,
  assistance,
  elevation,
  college,
  details,
}: PriceCardProps) {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={elevation}>
      <Box
        className={classes.header}
        display="flex"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Box className={classes.college}>
          <Typography variant="caption"> {collegeTitle(college)} </Typography>
        </Box>
        <Box>
          <Typography variant="caption">{rates.regime}</Typography>
        </Box>
      </Box>
      <CardContent>
        <FormulaTitle spec={rates} />
        <Box>
          {rates.rates.map((r, index) => (
            <Price
              key={index}
              price={totalRatePrice(r)}
              ppmss={totalRatePPMSS(r)}
              beneficient={r.beneficient}
            />
          ))}
          <Assistance value={assistance} />
          {details ? <FormulaDetails formula={rates.formula} /> : null}
        </Box>
      </CardContent>
    </Card>
  );
}

function FormulaTitle({ spec }: { spec: RatesSpec }) {
  return (
    <Box mb={2}>
      <Box
        mb={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h6">{formulaTitle(spec.formula)}</Typography>
        </Box>
        {renfort(spec.formula) ? <Renfort /> : null}
      </Box>
      <Divider />
    </Box>
  );
}

function Renfort() {
  return (
    <Box display="flex" alignItems="center" textAlign="right">
      <CheckBoxIcon color={"primary"} style={{ marginRight: "6px" }} />
      <Typography variant="body2">avec booster</Typography>
    </Box>
  );
}

function FormulaDetails({ formula }: { formula: Formula }) {
  return (
    <CardSection>
      <OfferDetails formula={formula.name} />
      {renfort(formula) ? <RenfortDetails /> : null}
    </CardSection>
  );
}

export function CardSection({
  children,
  show,
}: {
  children: ReactNode;
  show?: boolean;
}) {
  return show === undefined || show ? (
    <Box>
      <Box my={2}>
        <Divider />
      </Box>
      {children}
    </Box>
  ) : null;
}

export function formulaTitle(formula: Formula): string {
  if (formula.name === "basic") {
    return "Essentielle";
  } else if (formula.name === "standard") {
    return "Sécurité";
  } else if (formula.name === "premium") {
    return "Confort";
  } else if (formula.name === "ultimate") {
    return "Plénitude";
  } else {
    return "";
  }
}

export function formulaIcon(formula: Formula): ReactNode {
  if (formula.name === "basic") {
    return <Box>✨</Box>;
  } else if (formula.name === "standard") {
    return <Box>🌟</Box>;
  } else if (formula.name === "premium") {
    return <Box>🏆</Box>;
  } else if (formula.name === "ultimate") {
    return <Box>🎉</Box>;
  } else {
    return null;
  }
}

export function collegeTitle(college: keyof StructureOptions): string {
  if (college === "agirc") {
    return "CADRE : salariés relevant des art 4  et 4 bis et 36  de la convention interprofessionnelle AGIRC  du 14 mars 1947";
  } else if (college === "nonAgirc") {
    return "NON CADRE : salariés ne relevant pas des art 4, 4 bis et 36  de la convention interprofessionnelle AGIRC  du 14 mars 1947";
  } else if (college === "ani") {
    return "ANI";
  } else if (college === "nonAni") {
    return "NON-ANI";
  } else if (college === "ensemble") {
    return "Ensemble du personnel";
  } else {
    return "";
  }
}
